import find from 'lodash/find';
import moment from 'moment';
import { AnyAction } from 'redux';

import * as actions from '../actions/invite';

const initialState = {
  invitationsData: {
    invitations: {
      pending: []
    }
  },
  loading: true,
  reseded: false,
  resending: false,
  sended: false,
  sending: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    // load current users invitations and credits data
    case actions.LOAD:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        invitationsData: action.result,
        loaded: true,
        loading: false
      };

    case actions.LOAD_FAIL:
      return {
        ...state,
        loadErrors: action.error,
        loaded: false,
        loading: false
      };

    //  resend invitation email
    case actions.RESEND_INVITE:
      return {
        ...state,
        resended: false,
        resending: true
      };

    case actions.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resended: true,
        resending: false
      };

    case actions.RESEND_INVITE_FAIL:
      return {
        ...state,
        resendErrors: action.error,
        resended: false,
        resending: false
      };

    case actions.INVITATION_SEND:
      return {
        ...state,
        sended: false,
        sending: true
      };

    case actions.INVITATION_SEND_SUCCESS: {
      const user = action.result;
      const invitationsData = state.invitationsData;

      if (!find(invitationsData.invitations.pending, { id: user.id })) {
        const newPendingInvite = {
          email: user.email,
          id: user.id,
          invitation_sent_at: moment(user.invitation_sent_at).format(
            'MMMM DD, YYYY'
          )
        };

        invitationsData.invitations.pending.push(newPendingInvite);
      }

      return {
        ...state,
        invitationsData,
        sended: true,
        sending: false
      };
    }

    case actions.INVITATION_SEND_FAIL:
      return {
        ...state,
        sendErrors: action.error,
        sended: false,
        sending: false
      };

    default:
      return state;
  }
};
