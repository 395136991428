import { Table, Tooltip } from '@equitymultiple/react-eui';
import { Investment } from 'containers/Portfolio/types';
import moment from 'moment';
import React from 'react';

import utils from '../../../../utilities/utils';
import { tableHeaders, tooltips } from '../../constants';
import * as styles from '../../containers/AccountShared.module.scss';
import {
  formatPerformanceStatus,
  getActiveInvestmentNameCell
} from '../../helpers';

interface Props {
  investments: Investment[];
  loading: boolean;
  timePeriod: 'Total' | 'YTD';
}

const getColumnHeaders = () => [
  tableHeaders.investment,
  tableHeaders.performanceStatus,
  <span className={styles.infoIconWrap} key="investment-amount">
    <span className={styles.th}>
      {tableHeaders.investmentAmount}
      <div>Remaining Balance</div>
    </span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      placement="top"
      tooltipContent={tooltips.remainingCapitalBalance}
    />
  </span>,
  tableHeaders.dateInvested,
  <span className={styles.infoIconWrap} key="maturity-date">
    <span className={styles.th}>Maturity Date</span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      placement="top"
      tooltipContent={tooltips.maturityDate.keep}
    />
  </span>,
  tableHeaders.effectiveInterestRate,
  <span className={styles.infoIconWrap} key="interest-earned">
    <span className={styles.th}>
      Interest Earned
      <div>Interest Accruing</div>
    </span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      placement="top"
      tooltipContent={tooltips.interestAccruing}
    />
  </span>,
  tableHeaders.distributions,
  ''
];

const getRows = (investments: Investment[], timePeriod: 'Total' | 'YTD') => {
  if (investments) {
    let totalAmount = 0;
    let totalEarnings = 0;
    let totalDistributions = 0;

    const rows = investments.map(investment => {
      let remainingAmount = investment.amount;
      let earnings = investment.earnings;
      let distributions = investment.distributions;
      let remainingDays = '';

      if (timePeriod === 'YTD') {
        earnings = investment.ytd_earnings;
        distributions = investment.ytd_distributions;
      }

      if (investment.redeemed) {
        remainingAmount = investment.amount - investment.redeemable_amount;
      }

      if (investment.offering.matures_on) {
        const days = moment(investment.offering.matures_on).diff(
          moment(),
          'days'
        );
        remainingDays = `${days} day${days === 1 ? '' : 's'} left`;
      }

      totalAmount += investment.amount;
      totalEarnings += earnings;
      totalEarnings += investment.accrued_interest;
      totalDistributions += distributions;

      if (
        earnings === 0.0 &&
        investment.accrued_interest !== 0.0 &&
        !investment.redeemed
      ) {
        earnings = investment.accrued_interest;
      }

      return {
        cells: [
          getActiveInvestmentNameCell(investment),
          <span className={styles.infoIconWrap} key="performance-status">
            {formatPerformanceStatus(investment.offering.performance_status)}
            <Tooltip
              className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
              infoIcon
              placement="top"
              tooltipContent={
                tooltips.performanceStatuses[
                  investment.offering.performance_status
                ]
              }
            />
          </span>,
          <div key="investment-amount">
            {utils.formatCurrency(investment.amount, 2)}
            <div className={styles.secondaryText}>
              {utils.formatCurrency(remainingAmount, 2)}
            </div>
          </div>,
          utils.dateFormat(investment.date_invested),
          <div key="investment-maturity-date">
            {utils.dateFormat(investment.offering.matures_on)}
            <div className={styles.secondaryText}>{remainingDays}</div>
          </div>,
          `${investment.offering.rate}%`,
          <div key="investment-earnings">
            {earnings > 0 ? utils.formatCurrency(earnings, 2) : 'TBD'}
            <div className={styles.secondaryText}>
              {utils.formatCurrency(investment.accrued_interest, 2)}
            </div>
          </div>,
          utils.formatCurrency(distributions, 2),

          <i className="fa fa-chevron-right" key="icon" />
        ],
        className: styles.rowLink,
        link: `/portfolio/account/${investment.investment_account_id}/investment/${investment.id}`
      };
    });

    rows.push({
      cells: [
        'Total',
        '',
        utils.formatCurrency(totalAmount, 2),
        '',
        '',
        '',
        utils.formatCurrency(totalEarnings, 2),
        utils.formatCurrency(totalDistributions, 2),
        ''
      ],
      className: '',
      link: ''
    });

    return rows;
  }

  return [];
};

const KeepInvestments = ({ investments, loading, timePeriod }: Props) => {
  return (
    <Table
      allowSorting={[0]}
      className={styles.table}
      columnHeaders={getColumnHeaders()}
      loading={loading}
      rows={getRows(investments, timePeriod)}
      totalRow
    />
  );
};

export default KeepInvestments;
