const PHONE_CODES = [
  { code: 'US', label: 'USA (+1)', value: '1' },
  { code: 'GB', label: 'UK (+44)', value: '44' },
  { code: 'DZ', label: 'Algeria (+213)', value: '213' },
  { code: 'AD', label: 'Andorra (+376)', value: '376' },
  { code: 'AO', label: 'Angola (+244)', value: '244' },
  { code: 'AI', label: 'Anguilla (+1264)', value: '1264' },
  { code: 'AG', label: 'Antigua & Barbuda (+1268)', value: '1268' },
  { code: 'AR', label: 'Argentina (+54)', value: '54' },
  { code: 'AM', label: 'Armenia (+374)', value: '374' },
  { code: 'AW', label: 'Aruba (+297)', value: '297' },
  { code: 'AU', label: 'Australia (+61)', value: '61' },
  { code: 'AT', label: 'Austria (+43)', value: '43' },
  { code: 'AZ', label: 'Azerbaijan (+994)', value: '994' },
  { code: 'BS', label: 'Bahamas (+1242)', value: '1242' },
  { code: 'BH', label: 'Bahrain (+973)', value: '973' },
  { code: 'BD', label: 'Bangladesh (+880)', value: '880' },
  { code: 'BB', label: 'Barbados (+1246)', value: '1246' },
  { code: 'BY', label: 'Belarus (+375)', value: '375' },
  { code: 'BE', label: 'Belgium (+32)', value: '32' },
  { code: 'BZ', label: 'Belize (+501)', value: '501' },
  { code: 'BJ', label: 'Benin (+229)', value: '229' },
  { code: 'BM', label: 'Bermuda (+1441)', value: '1441' },
  { code: 'BT', label: 'Bhutan (+975)', value: '975' },
  { code: 'BO', label: 'Bolivia (+591)', value: '591' },
  { code: 'BA', label: 'Bosnia Herzegovina (+387)', value: '387' },
  { code: 'BW', label: 'Botswana (+267)', value: '267' },
  { code: 'BR', label: 'Brazil (+55)', value: '55' },
  { code: 'BN', label: 'Brunei (+673)', value: '673' },
  { code: 'BG', label: 'Bulgaria (+359)', value: '359' },
  { code: 'BF', label: 'Burkina Faso (+226)', value: '226' },
  { code: 'BI', label: 'Burundi (+257)', value: '257' },
  { code: 'KH', label: 'Cambodia (+855)', value: '855' },
  { code: 'CM', label: 'Cameroon (+237)', value: '237' },
  { code: 'CV', label: 'Cape Verde Islands (+238)', value: '238' },
  { code: 'KY', label: 'Cayman Islands (+1345)', value: '1345' },
  { code: 'CF', label: 'Central African Republic (+236)', value: '236' },
  { code: 'CL', label: 'Chile (+56)', value: '56' },
  { code: 'CN', label: 'China (+86)', value: '86' },
  { code: 'CO', label: 'Colombia (+57)', value: '57' },
  { code: 'KM', label: 'Comoros (+269)', value: '269' },
  { code: 'CG', label: 'Congo (+242)', value: '242' },
  { code: 'CK', label: 'Cook Islands (+682)', value: '682' },
  { code: 'CR', label: 'Costa Rica (+506)', value: '506' },
  { code: 'HR', label: 'Croatia (+385)', value: '385' },
  { code: 'CU', label: 'Cuba (+53)', value: '53' },
  { code: 'CY', label: 'Cyprus North (+90392)', value: '90392' },
  { code: 'CY', label: 'Cyprus South (+357)', value: '357' },
  { code: 'CZ', label: 'Czech Republic (+42)', value: '42' },
  { code: 'DK', label: 'Denmark (+45)', value: '45' },
  { code: 'DJ', label: 'Djibouti (+253)', value: '253' },
  { code: 'DM', label: 'Dominica (+1809)', value: '1809' },
  { code: 'DO', label: 'Dominican Republic (+1809)', value: '1809' },
  { code: 'EC', label: 'Ecuador (+593)', value: '593' },
  { code: 'EG', label: 'Egypt (+20)', value: '20' },
  { code: 'SV', label: 'El Salvador (+503)', value: '503' },
  { code: 'GQ', label: 'Equatorial Guinea (+240)', value: '240' },
  { code: 'ER', label: 'Eritrea (+291)', value: '291' },
  { code: 'EE', label: 'Estonia (+372)', value: '372' },
  { code: 'ET', label: 'Ethiopia (+251)', value: '251' },
  { code: 'FK', label: 'Falkland Islands (+500)', value: '500' },
  { code: 'FO', label: 'Faroe Islands (+298)', value: '298' },
  { code: 'FJ', label: 'Fiji (+679)', value: '679' },
  { code: 'FI', label: 'Finland (+358)', value: '358' },
  { code: 'FR', label: 'France (+33)', value: '33' },
  { code: 'GF', label: 'French Guiana (+594)', value: '594' },
  { code: 'PF', label: 'French Polynesia (+689)', value: '689' },
  { code: 'GA', label: 'Gabon (+241)', value: '241' },
  { code: 'GM', label: 'Gambia (+220)', value: '220' },
  { code: 'GE', label: 'Georgia (+7880)', value: '7880' },
  { code: 'DE', label: 'Germany (+49)', value: '49' },
  { code: 'GH', label: 'Ghana (+233)', value: '233' },
  { code: 'GI', label: 'Gibraltar (+350)', value: '350' },
  { code: 'GR', label: 'Greece (+30)', value: '30' },
  { code: 'GL', label: 'Greenland (+299)', value: '299' },
  { code: 'GD', label: 'Grenada (+1473)', value: '1473' },
  { code: 'GP', label: 'Guadeloupe (+590)', value: '590' },
  { code: 'GU', label: 'Guam (+671)', value: '671' },
  { code: 'GT', label: 'Guatemala (+502)', value: '502' },
  { code: 'GN', label: 'Guinea (+224)', value: '224' },
  { code: 'GW', label: 'Guinea - Bissau (+245)', value: '245' },
  { code: 'GY', label: 'Guyana (+592)', value: '592' },
  { code: 'HT', label: 'Haiti (+509)', value: '509' },
  { code: 'HN', label: 'Honduras (+504)', value: '504' },
  { code: 'HK', label: 'Hong Kong (+852)', value: '852' },
  { code: 'HU', label: 'Hungary (+36)', value: '36' },
  { code: 'IS', label: 'Iceland (+354)', value: '354' },
  { code: 'IN', label: 'India (+91)', value: '91' },
  { code: 'PK', label: 'Pakistan (+92)', value: '92' },
  { code: 'ID', label: 'Indonesia (+62)', value: '62' },
  { code: 'IR', label: 'Iran (+98)', value: '98' },
  { code: 'IQ', label: 'Iraq (+964)', value: '964' },
  { code: 'IE', label: 'Ireland (+353)', value: '353' },
  { code: 'IL', label: 'Israel (+972)', value: '972' },
  { code: 'IT', label: 'Italy (+39)', value: '39' },
  { code: 'JM', label: 'Jamaica (+1876)', value: '1876' },
  { code: 'JP', label: 'Japan (+81)', value: '81' },
  { code: 'JO', label: 'Jordan (+962)', value: '962' },
  { code: 'KZ', label: 'Kazakhstan (+7)', value: '7' },
  { code: 'KE', label: 'Kenya (+254)', value: '254' },
  { code: 'KI', label: 'Kiribati (+686)', value: '686' },
  { code: 'KP', label: 'Korea North (+850)', value: '850' },
  { code: 'KR', label: 'Korea South (+82)', value: '82' },
  { code: 'KW', label: 'Kuwait (+965)', value: '965' },
  { code: 'KG', label: 'Kyrgyzstan (+996)', value: '996' },
  { code: 'LA', label: 'Laos (+856)', value: '856' },
  { code: 'LV', label: 'Latvia (+371)', value: '371' },
  { code: 'LB', label: 'Lebanon (+961)', value: '961' },
  { code: 'LS', label: 'Lesotho (+266)', value: '266' },
  { code: 'LR', label: 'Liberia (+231)', value: '231' },
  { code: 'LY', label: 'Libya (+218)', value: '218' },
  { code: 'LI', label: 'Liechtenstein (+417)', value: '417' },
  { code: 'LT', label: 'Lithuania (+370)', value: '370' },
  { code: 'LU', label: 'Luxembourg (+352)', value: '352' },
  { code: 'MO', label: 'Macao (+853)', value: '853' },
  { code: 'MK', label: 'Macedonia (+389)', value: '389' },
  { code: 'MG', label: 'Madagascar (+261)', value: '261' },
  { code: 'MW', label: 'Malawi (+265)', value: '265' },
  { code: 'MY', label: 'Malaysia (+60)', value: '60' },
  { code: 'MV', label: 'Maldives (+960)', value: '960' },
  { code: 'ML', label: 'Mali (+223)', value: '223' },
  { code: 'MT', label: 'Malta (+356)', value: '356' },
  { code: 'MH', label: 'Marshall Islands (+692)', value: '692' },
  { code: 'MQ', label: 'Martinique (+596)', value: '596' },
  { code: 'MR', label: 'Mauritania (+222)', value: '222' },
  { code: 'YT', label: 'Mayotte (+269)', value: '269' },
  { code: 'MX', label: 'Mexico (+52)', value: '52' },
  { code: 'FM', label: 'Micronesia (+691)', value: '691' },
  { code: 'MD', label: 'Moldova (+373)', value: '373' },
  { code: 'MC', label: 'Monaco (+377)', value: '377' },
  { code: 'MN', label: 'Mongolia (+976)', value: '976' },
  { code: 'MS', label: 'Montserrat (+1664)', value: '1664' },
  { code: 'MA', label: 'Morocco (+212)', value: '212' },
  { code: 'MZ', label: 'Mozambique (+258)', value: '258' },
  { code: 'MN', label: 'Myanmar (+95)', value: '95' },
  { code: 'NA', label: 'Namibia (+264)', value: '264' },
  { code: 'NR', label: 'Nauru (+674)', value: '674' },
  { code: 'NP', label: 'Nepal (+977)', value: '977' },
  { code: 'NL', label: 'Netherlands (+31)', value: '31' },
  { code: 'NC', label: 'New Caledonia (+687)', value: '687' },
  { code: 'NZ', label: 'New Zealand (+64)', value: '64' },
  { code: 'NI', label: 'Nicaragua (+505)', value: '505' },
  { code: 'NE', label: 'Niger (+227)', value: '227' },
  { code: 'NG', label: 'Nigeria (+234)', value: '234' },
  { code: 'NU', label: 'Niue (+683)', value: '683' },
  { code: 'NF', label: 'Norfolk Islands (+672)', value: '672' },
  { code: 'NP', label: 'Northern Marianas (+670)', value: '670' },
  { code: 'NO', label: 'Norway (+47)', value: '47' },
  { code: 'OM', label: 'Oman (+968)', value: '968' },
  { code: 'PW', label: 'Palau (+680)', value: '680' },
  { code: 'PA', label: 'Panama (+507)', value: '507' },
  { code: 'PG', label: 'Papua New Guinea (+675)', value: '675' },
  { code: 'PY', label: 'Paraguay (+595)', value: '595' },
  { code: 'PE', label: 'Peru (+51)', value: '51' },
  { code: 'PH', label: 'Philippines (+63)', value: '63' },
  { code: 'PL', label: 'Poland (+48)', value: '48' },
  { code: 'PT', label: 'Portugal (+351)', value: '351' },
  { code: 'PR', label: 'Puerto Rico (+1787)', value: '1787' },
  { code: 'QA', label: 'Qatar (+974)', value: '974' },
  { code: 'RE', label: 'Reunion (+262)', value: '262' },
  { code: 'RO', label: 'Romania (+40)', value: '40' },
  { code: 'RU', label: 'Russia (+7)', value: '7' },
  { code: 'RW', label: 'Rwanda (+250)', value: '250' },
  { code: 'SM', label: 'San Marino (+378)', value: '378' },
  { code: 'ST', label: 'Sao Tome & Principe (+239)', value: '239' },
  { code: 'SA', label: 'Saudi Arabia (+966)', value: '966' },
  { code: 'SN', label: 'Senegal (+221)', value: '221' },
  { code: 'CS', label: 'Serbia (+381)', value: '381' },
  { code: 'SC', label: 'Seychelles (+248)', value: '248' },
  { code: 'SL', label: 'Sierra Leone (+232)', value: '232' },
  { code: 'SG', label: 'Singapore (+65)', value: '65' },
  { code: 'SK', label: 'Slovak Republic (+421)', value: '421' },
  { code: 'SI', label: 'Slovenia (+386)', value: '386' },
  { code: 'SB', label: 'Solomon Islands (+677)', value: '677' },
  { code: 'SO', label: 'Somalia (+252)', value: '252' },
  { code: 'ZA', label: 'South Africa (+27)', value: '27' },
  { code: 'ES', label: 'Spain (+34)', value: '34' },
  { code: 'LK', label: 'Sri Lanka (+94)', value: '94' },
  { code: 'SH', label: 'St. Helena (+290)', value: '290' },
  { code: 'KN', label: 'St. Kitts (+1869)', value: '1869' },
  { code: 'SC', label: 'St. Lucia (+1758)', value: '1758' },
  { code: 'SD', label: 'Sudan (+249)', value: '249' },
  { code: 'SR', label: 'Suri"name" (+597)', value: '597' },
  { code: 'SZ', label: 'Swaziland (+268)', value: '268' },
  { code: 'SE', label: 'Sweden (+46)', value: '46' },
  { code: 'CH', label: 'Switzerland (+41)', value: '41' },
  { code: 'SI', label: 'Syria (+963)', value: '963' },
  { code: 'TW', label: 'Taiwan (+886)', value: '886' },
  { code: 'TJ', label: 'Tajikstan (+7)', value: '7' },
  { code: 'TH', label: 'Thailand (+66)', value: '66' },
  { code: 'TG', label: 'Togo (+228)', value: '228' },
  { code: 'TO', label: 'Tonga (+676)', value: '676' },
  { code: 'TT', label: 'Trinidad & Tobago (+1868)', value: '1868' },
  { code: 'TN', label: 'Tunisia (+216)', value: '216' },
  { code: 'TR', label: 'Turkey (+90)', value: '90' },
  { code: 'TM', label: 'Turkmenistan (+7)', value: '7' },
  { code: 'TM', label: 'Turkmenistan (+993)', value: '993' },
  { code: 'TC', label: 'Turks & Caicos Islands (+1649)', value: '1649' },
  { code: 'TV', label: 'Tuvalu (+688)', value: '688' },
  { code: 'UG', label: 'Uganda (+256)', value: '256' },
  { code: 'UA', label: 'Ukraine (+380)', value: '380' },
  { code: 'AE', label: 'United Arab Emirates (+971)', value: '971' },
  { code: 'UY', label: 'Uruguay (+598)', value: '598' },
  { code: 'UZ', label: 'Uzbekistan (+7)', value: '7' },
  { code: 'VU', label: 'Vanuatu (+678)', value: '678' },
  { code: 'VA', label: 'Vatican City (+379)', value: '379' },
  { code: 'VE', label: 'Venezuela (+58)', value: '58' },
  { code: 'VN', label: 'Vietnam (+84)', value: '84' },
  { code: 'VG', label: 'Virgin Islands - British (+1284)', value: '84' },
  { code: 'VI', label: 'Virgin Islands - US (+1340)', value: '84' },
  { code: 'WF', label: 'Wallis & Futuna (+681)', value: '681' },
  { code: 'YE', label: 'Yemen (North)(+969)', value: '969' },
  { code: 'YE', label: 'Yemen (South)(+967)', value: '967' },
  { code: 'ZM', label: 'Zambia (+260)', value: '260' },
  { code: 'ZW', label: 'Zimbabwe (+263)', value: '263' }
];

export default PHONE_CODES;
