import React, { useEffect, useState } from 'react';

import * as styles from './ProgressBarBorder.module.scss';

interface Props {
  currentStep: number;
  fullWidth?: boolean;
  noAnimation?: boolean;
  steps: number;
  warning?: boolean;
}

const ProgressBarBorder = ({
  currentStep,
  fullWidth,
  noAnimation,
  steps,
  warning
}: Props) => {
  const [animate, setAnimate] = useState(false);
  const [width, setWidth] = useState(0);

  const handleSetWidth = () => {
    let newWidth = ((currentStep - 1) / steps) * 100;
    if (currentStep === 1) newWidth = 1;
    if (warning) newWidth = 100;

    setWidth(newWidth);
  };

  useEffect(() => {
    if ((currentStep && steps) || fullWidth) {
      if (!noAnimation) {
        // Set the previous step's width with no animation, then immediately animate to the current step's width
        // This enables animation even when the component has to remount
        const previousStepWidth = ((currentStep - 2) / steps) * 100;
        setWidth(previousStepWidth);
        setTimeout(() => {
          setAnimate(true);
          handleSetWidth();
        }, 0);
      } else {
        handleSetWidth();
      }
    }

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentStep) handleSetWidth();
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const complete = width === 100 && !warning;

  return (
    <div
      className={`${styles.progressBarBorder} ${
        warning ? styles.warning : ''
      } ${complete ? styles.solidBlue : ''} ${animate ? styles.animate : ''}`}
      data-testid="progressBarBorder"
      style={{ width: width + '%' }}
    />
  );
};

export default ProgressBarBorder;
