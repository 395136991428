import React, { useEffect, useRef } from 'react';

interface Props {
  height?: number | string;
  latitude?: number | string;
  longitude?: number | string;
  marginLeft?: number | string;
  points?: {
    latitude?: number;
    longitude?: number;
    pin_color?: string;
    pin_label?: string;
    title?: string;
  }[];
  width?: number | string;
  zoom?: number;
}

const GoogleMap = ({
  height,
  latitude,
  longitude,
  marginLeft,
  points,
  width,
  zoom
}: Props) => {
  const { google } = window;
  const mapRef = useRef();

  const updateMarkers = map => {
    // map may not be loaded when parent component re-renders
    if (map === null) return false;

    const bounds = new google.maps.LatLngBounds();

    // add new markers
    points.forEach(point => {
      const location = new google.maps.LatLng(point.latitude, point.longitude);
      bounds.extend(location);

      let pinColor = point.pin_color;
      const pinLabel = point.pin_label;
      let pinUrl;

      if (pinLabel && pinColor) {
        pinColor = pinColor.replace(/#/g, '');
        pinUrl =
          'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=' +
          pinLabel +
          '|' +
          pinColor;
      } else {
        pinUrl =
          'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FE7569';
      }

      const mapPin = {
        anchor: new google.maps.Point(17, 34),
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(30, 50),
        size: new google.maps.Size(40, 80),
        url: pinUrl
      };

      const marker = new google.maps.Marker({
        icon: mapPin,
        map,
        position: location,
        title: point.title
      });

      if (points.length > 1) map.fitBounds(bounds);

      const contentString = point.title;

      const infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      google.maps.event.addListener(marker, 'click', () => {
        infowindow.open(map, marker);
      });
    });

    const zoomChangeBoundsListener = google.maps.event.addListenerOnce(
      map,
      'bounds_changed',
      function setMapZoom() {
        if (map.getZoom() > zoom) map.setZoom(zoom);
      }
    );

    return setTimeout(() => {
      google.maps.event.removeListener(zoomChangeBoundsListener);
    }, 2000);
  };

  const createMap = () => {
    const mapOptions = {
      center: new google.maps.LatLng(latitude, longitude),
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.TERRAIN,
          google.maps.MapTypeId.SATELLITE
        ],
        position: google.maps.ControlPosition.TOP_CENTER,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
      },
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      },
      zoom,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
        style: google.maps.ZoomControlStyle.LARGE
      }
    };

    const map = new google.maps.Map(mapRef.current, mapOptions);
    updateMarkers(map);
  };

  useEffect(() => {
    if (google) createMap();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const style = {
    height,
    marginLeft,
    width
  };

  return <div className="google-map" ref={mapRef} style={style} />;
};

export default GoogleMap;
