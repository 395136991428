import React, { useEffect } from 'react';
import { PlaidLinkOptionsWithLinkToken, usePlaidLink } from 'react-plaid-link';

interface Props {
  onReady: () => void;
  options: PlaidLinkOptionsWithLinkToken;
}

const PlaidLinkCore = ({ onReady, options }: Props) => {
  const { onExit, onSuccess, receivedRedirectUri, token } = options;
  const { open, ready } = usePlaidLink({
    onExit,
    onSuccess,
    receivedRedirectUri,
    token
  });

  useEffect(() => {
    if (ready) {
      onReady();
      open();
    }
  }, [ready, open, onReady]);

  return <></>;
};

export default PlaidLinkCore;
