import {
  Button,
  Card,
  EMLoadingIcon,
  ErrorLabel
} from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  clearInvestmentAccount,
  loadInvestmentAccounts
} from 'redux/actions/account';
import { loadAuth } from 'redux/actions/auth';
import { LoadInvestmentAccountsResponse } from 'types/actions/account';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { canAccessPage, mustCompleteProfile } from 'utilities/user';

import * as sharedStyles from '../../AccountShared.module.scss';
import { accountFlag, accountStatus } from '../../contents';
import {
  getIncompleteAccountLink,
  hasShellInvestmentAccount,
  isComplete
} from '../../helpers';
import * as styles from './Accounts.module.scss';

// Direct users to the first incomplete step

const getInvestmentAccountsThatMeetResidencyRequirements = (
  residenceStatus,
  investmentAccounts
) => {
  let filteredArray = [];

  if (!investmentAccounts) return null;

  if (residenceStatus !== 'Other') return investmentAccounts;

  if (residenceStatus === 'Other')
    filteredArray = investmentAccounts.filter(
      account => account.account_type === 'entity'
    );

  return filteredArray;
};

const investmentAccountFlag = type => accountFlag(type);

interface Props {
  dispatch: Dispatch;
  investmentAccounts: LoadInvestmentAccountsResponse;
  loading: boolean;
  user: User;
}

const Accounts = ({ dispatch, investmentAccounts, loading, user }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'My Accounts | EquityMultiple ';
    // Reload the user since routing of incomplete accounts can depend on user data
    // which may have been updated since the last time it was loaded
    dispatch(loadAuth())
      .then(userRes => {
        if (!canAccessPage('accounts', userRes) || mustCompleteProfile(userRes))
          navigate('/', { replace: true });
        dispatch(clearInvestmentAccount());
        dispatch(loadInvestmentAccounts())
          .then(accounts => {
            if (accounts?.length < 2 && hasShellInvestmentAccount(accounts))
              navigate('/accounts/new');
          })
          .catch(error => handleErrorResponse(navigate, error));
      })
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, navigate]);

  const filteredInvestmentAccounts =
    getInvestmentAccountsThatMeetResidencyRequirements(
      user.investor_profile?.residence_status,
      investmentAccounts
    );

  return loading || !filteredInvestmentAccounts ? (
    <EMLoadingIcon data-testid="loadingIcon" />
  ) : (
    <Container
      className={`clearfix-after container-narrow ${sharedStyles.containerSidebar}`}
    >
      <h1 className="margin-top-0">My Accounts</h1>
      {filteredInvestmentAccounts.length > 0 && (
        <Card>
          {filteredInvestmentAccounts.map(investmentAccount => (
            <div className={styles.account} key={investmentAccount.id}>
              {investmentAccount.needs_update &&
                isComplete(investmentAccount) && (
                  <ErrorLabel
                    message={investmentAccountFlag(investmentAccount.type)}
                  />
                )}

              <div className={styles.accountDescription}>
                <span className={styles.accountName}>
                  {investmentAccount.entity_name || '(Unfinished Account)'}
                </span>
                <span className={styles.accountType}>
                  {investmentAccount.type}
                </span>
              </div>
              <div className={styles.accountRight}>
                <strong
                  className={`${styles.accountStatus} ${
                    investmentAccount.status === accountStatus.created
                      ? 'text-green'
                      : 'text-yellow'
                  }`}
                >
                  {investmentAccount.status[0].toUpperCase() +
                    investmentAccount.status.substring(1)}
                </strong>
                {isComplete(investmentAccount) ? (
                  <Link
                    className={`text-link ${styles.viewAccount}`}
                    to={`/accounts/update/${investmentAccount.account_type}/${investmentAccount.reference_id}`}
                  >
                    {investmentAccount.type === 'ira'
                      ? 'View Account Information'
                      : 'Edit Account'}
                  </Link>
                ) : (
                  <Link
                    className={`text-link ${styles.viewAccount}`}
                    title="Complete Account"
                    to={getIncompleteAccountLink(investmentAccount, user)}
                  >
                    Complete Account
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Card>
      )}

      <Button
        className={`${
          filteredInvestmentAccounts?.length > 0 ? 'float-right' : ''
        }`}
        wrapper={<Link to="/accounts/new" />}
      >
        Open New Account
      </Button>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccounts: state.account.investmentAccounts,
    loading: state.account.loading || state.auth.loading,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Accounts);
