import { Alert, Button, Input, Select } from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import {
  iraAccountTypeOptions,
  iraCustodianOptions
} from 'containers/Accounts/contents';
import { IraFields } from 'containers/Accounts/types';
import React, { useEffect } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';

import BackLink from '../BackLink/BackLink';

interface Props {
  businessType?: string;
  control: Control<IraFields>;
  entityName?: string;
  errors: FieldErrors<IraFields>;
  irRep: string;
  submitting: boolean;
}

const IraAccountFields = ({
  businessType,
  control,
  entityName,
  errors,
  irRep,
  submitting
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Link IRA';
  }, []);

  const entityNameOther = entityName === 'Other';

  return (
    <>
      <h4 className="margin-top-0">Link Your Individual Retirement Account</h4>

      <Controller
        control={control}
        name="investment_account.entity_name"
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            className="input-fixed-width-wide"
            label="Custodian"
            options={iraCustodianOptions}
          />
        )}
      />

      {entityNameOther ? (
        <Alert type="warning">
          We work with a variety of custodians. If you have an existing IRA with
          a custodian not on the list, please schedule a call with us to talk
          about your options
        </Alert>
      ) : (
        <div>
          <Controller
            control={control}
            name="investment_account.business_type"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                className="input-fixed-width-wide"
                label="Account Type"
                options={iraAccountTypeOptions}
              />
            )}
          />

          {businessType === 'Other' && (
            <Controller
              control={control}
              name="investment_account.business_type_other"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  className="input-fixed-width-wide"
                  label="Other Account Type"
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="investment_account.ira_account_number"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                className="input-fixed-width-wide"
                label="Account Number"
              />
            )}
          />
        </div>
      )}

      <FormError errors={errors} />

      <div className="forwardBackButtonWrapCompact">
        {entityNameOther ? (
          <Button
            data-testid="scheduleButton"
            href={irRep}
            loading={submitting}
            rel="noreferrer"
            style={{ width: 200 }}
            target="_blank"
          >
            <span>
              <i className="fa fa-phone" /> Schedule
            </span>
          </Button>
        ) : (
          <Button
            loading={submitting}
            style={{ width: 200 }}
            type="submit"
            variant="orange"
          >
            Save
          </Button>
        )}
        <BackLink route={'/accounts/new'} />
      </div>
    </>
  );
};

export default IraAccountFields;
