// Closing stage values from the back end and their associated labels
export const closingStages = {
  active: 'active',
  cashflowing: 'cashflowing',
  draft: 'draft',
  exited: 'exited',
  funded: 'funded',
  interest: 'pre-launch',
  limited_access: 'limited access',
  waitlist: 'waitlist'
};

export const closingStageTooltips = {
  interest:
    'Due to the high volume of interest, this offering is currently in Prelaunch mode. Prelaunch allows investors to indicate interest and get priority access when funding becomes available.',
  waitlist:
    'This offering is currently oversubscribed. Join the waitlist and we will be sure to invite you as allocations become available!'
};
