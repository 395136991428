import { Tooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { User } from 'types/actions/auth';
import { isAccredited } from 'utilities/user';

import * as styles from './Header.module.scss';

interface Props {
  handleLinkClick: (segmentTrackName: string, isSecondary: boolean) => void;
  user: User;
}

const ResourceLinks = ({ handleLinkClick, user }: Props) => {
  const notAccredited = !isAccredited(user);

  return (
    <>
      {notAccredited ? (
        <div className={styles.disabledLink}>
          <span className="link-text">
            Track Record
            <Tooltip
              className={styles.infoIcon}
              infoIcon
              infoIconColor="#656E87 !important"
              tooltipClassName={styles.tooltip}
              tooltipContent="EquityMultiple is a platform for Accredited investors. If you believe you are accredited update your status to proceed."
            />
          </span>
        </div>
      ) : (
        <a
          href="https://equitymultiple.com/wp-content/uploads/2024/09/summary-track-record-3Q2024.pdf"
          onClick={() => handleLinkClick('Track Record', true)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="link-text">Track Record</span>
        </a>
      )}
      <a
        href="//equitymultiple.com/blog"
        onClick={() => handleLinkClick('Articles', true)}
      >
        <span className="link-text">Articles</span>
      </a>
      <a
        href="//help.equitymultiple.com/en/"
        onClick={() => handleLinkClick('Help Center', true)}
      >
        <span className="link-text">Help Center</span>
      </a>
      <a
        href="//equitymultiple.com/blog/category/investor-stories/"
        onClick={() => handleLinkClick('Testimonials', true)}
      >
        <span className="link-text">Testimonials</span>
      </a>
      <a
        href="//equitymultiple.com/faq"
        onClick={() => handleLinkClick('FAQ', true)}
      >
        <span className="link-text">FAQ</span>
      </a>
      <a
        href="//equitymultiple.com/press"
        onClick={() => handleLinkClick('Press', true)}
      >
        <span className="link-text">Press</span>
      </a>
      <a
        href="//equitymultiple.com/glossary#a"
        onClick={() => handleLinkClick('Glossary', true)}
      >
        <span className="link-text">Glossary</span>
      </a>
    </>
  );
};

export default ResourceLinks;
