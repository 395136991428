import EmAnalytics from './em_analytics';

export const trackInvestorPacketClick = (location, offeringTitle, user) => {
  EmAnalytics.track('Clicked on Investor Packet', '', {
    investmentExperience: user.track.level_of_investing_experience,
    location,
    offeringTitle,
    stage: user.track.stage,
    url: window.location.href
  });
};
