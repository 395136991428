import {
  Alert,
  Button,
  Card,
  Input,
  RadioButton,
  Select,
  Tooltip
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import {
  stnElectionsStepOneSchema,
  stnElectionsStepTwoSchema
} from 'containers/Investment/validations';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  loadReinvestment,
  updateReinvestmentElections
} from 'redux/actions/investments';
import {
  Reinvestment,
  UpdateReinvestmentElectionsPayload
} from 'types/actions/investments';
import { Dispatch } from 'types/redux';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';
import { numberMaskOptions } from 'utilities/masks';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as styles from './STNElections.module.scss';

type ReinvestmentOption = 'full' | 'partial' | 'opt_out';

type FormFields = UpdateReinvestmentElectionsPayload;

const reinvestmentOptions = [
  {
    label: 'Full Reinvestment',
    value: 'full'
  },
  {
    label: 'Partial Reinvestment',
    value: 'partial'
  },
  {
    label: 'Opt-out',
    value: 'opt_out'
  }
];

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h3 className={styles.heading}>
      <Skeleton width="60%" />
    </h3>
    <Row>
      <Col md={6}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
      <Col md={6}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
    </Row>
    <h5 className="margin-top-xx margin-xxx">
      <Skeleton width="50%" />
    </h5>
    <p className="margin-xxx">
      <Skeleton width={200} />
    </p>
    <p className="margin-xxx">
      <Skeleton width={200} />
    </p>
    <Skeleton className={styles.loadingButton} />
  </div>
);

const getFormattedReinvestmentOption = (
  reinvestmentOption: ReinvestmentOption,
  reinvestmentAmount?: string
) => {
  switch (reinvestmentOption) {
    case 'opt_out':
      return 'Opt Out';
    case 'partial':
      return `Partial Reinvestment: ${reinvestmentAmount}`;
    case 'full':
      return `Full Reinvestment: ${reinvestmentAmount}`;
  }
};

const getReinvestmentDetails = (reinvestment, offering) => {
  const { redeemed, reinvestment_option: reinvestmentOption } = reinvestment;
  const optOut = reinvestmentOption === 'opt_out';
  const partiallyReinvest = reinvestmentOption === 'partial';
  let reinvestmentAmount = utils.formatCurrency(reinvestment.amount);
  if (partiallyReinvest)
    reinvestmentAmount = utils.formatCurrency(reinvestment.reinvestment_amount);
  if (redeemed)
    reinvestmentAmount = utils.formatCurrency(reinvestment.remaining_amount);

  const windowLengthDays = moment
    .duration(
      moment(reinvestment.elections_end_date).diff(
        moment(reinvestment.elections_start_date)
      )
    )
    .asDays();
  const daysElapsed = moment
    .duration(moment().diff(moment(reinvestment.elections_start_date)))
    .asDays();
  const daysElapsedPercentage = Math.round(
    (daysElapsed / windowLengthDays) * 100
  );

  return (
    <div data-testid="reinvestmentDetails">
      <div className="text-label">Current Election Status</div>
      <p className="weight-md-heavy margin-xx">
        {optOut
          ? 'Opt-Out'
          : getFormattedReinvestmentOption(
              reinvestmentOption,
              reinvestmentAmount
            )}
      </p>

      <Row className="margin-xx">
        <Col md={4} xs={6}>
          <div className="text-label">Original Source Investment</div>
          <p>{offering.title}</p>
        </Col>
        <Col md={4} xs={6}>
          <div className="text-label">Original Investment Amount</div>
          <p> {utils.formatCurrency(reinvestment.amount)}</p>
        </Col>
        <Col md={4} xs={6}>
          <div className="text-label">Maturity Date</div>
          <p data-testid="maturityDate">
            {utils.dateFormat(offering.matures_on) || 'TBD'}
          </p>
        </Col>
        {reinvestment.redeemed && (
          <Col md={4} xs={6}>
            <div className="text-label flex align-center">
              Remaining Balance
              <Tooltip
                className="info-icon-margin-left"
                infoIcon
                tooltipContent="This is the remaining balance left on this investment after redemption."
              />
            </div>
            <p data-testid="remainingAmount">
              {utils.formatCurrency(reinvestment.remaining_amount)}
            </p>
          </Col>
        )}
        <Col md={4} xs={6}>
          <div className="text-label flex align-center">
            Interest To Accrue
            <Tooltip
              className="info-icon-margin-left"
              infoIcon
              tooltipContent="This is the interest that you should expect to have accrued on your investment at the date of maturity. Should this investment mature with the accrued interest, this is the amount that you will be paid in interest at the date of maturity."
            />
          </div>
          <p> {utils.formatCurrency(reinvestment.earnings)}</p>
        </Col>
        <Col md={4} xs={6}>
          {reinvestment.referral_bonus > 0 && (
            <>
              <div className="text-label flex align-center">
                Anticipated Referral Bonus
                <Tooltip
                  className="info-icon-margin-left"
                  infoIcon
                  tooltipContent="This is the amount that you have received as a referral bonus credit that will be paid out, along with your principal payment and interest earned, at the time of maturity."
                />
              </div>
              <p> {utils.formatCurrency(reinvestment.referral_bonus)}</p>
            </>
          )}
        </Col>
      </Row>
      <div>
        <div className={styles.timeline}>
          <div className={styles.window}>
            <div
              className={styles.windowProgress}
              data-testid="electionWindowProgress"
              style={{ width: `${daysElapsedPercentage}%` }}
            />
          </div>
          <div className={styles.timelineSeparator} />
        </div>
        <div className={styles.timelineDates}>
          <div data-testid="electionsDateRange">
            <span className="weight-md">Reinvestment Elections: </span>
            {utils.dateFormat(reinvestment.elections_start_date)} -{' '}
            {utils.dateFormat(reinvestment.elections_end_date)}
          </div>
          <div data-testid="timelineMaturityDate">
            <span className="weight-md">Maturity Date: </span>
            {utils.dateFormat(offering.matures_on) || 'TBD'}
          </div>
        </div>
      </div>
    </div>
  );
};

const getReinvestmentSummary = (
  formValues: FormFields,
  reinvestment: Reinvestment
) => {
  const {
    reinvest_interest: reinvestInterest,
    reinvestment_option: reinvestmentOption
  } = formValues;

  const optOut = reinvestmentOption === 'opt_out';
  const partiallyReinvest = reinvestmentOption === 'partial';
  const fullReinvestment = reinvestmentOption === 'full';
  const redeemed = reinvestment.redeemed;

  const referralBonus = parseFloat(reinvestment.referral_bonus);
  const interestEarned = parseFloat(reinvestment.earnings);
  const remainingAmount = parseFloat(reinvestment.remaining_amount);
  const redemptionAmount =
    reinvestment.redemption_amount &&
    parseFloat(reinvestment.redemption_amount);
  const amount = parseFloat(reinvestment.amount);
  const reinvestmentAmount = parseFloat(formValues.reinvestment_amount);
  let rolloverFromBalance = partiallyReinvest ? reinvestmentAmount : amount;
  let balanceToWithdraw = amount - reinvestmentAmount - redemptionAmount;

  if (redeemed) {
    rolloverFromBalance = remainingAmount;
    balanceToWithdraw = optOut ? remainingAmount : 0;
  }

  if (optOut) {
    rolloverFromBalance = 0;
    balanceToWithdraw = remainingAmount;
  }

  const totalAmount =
    reinvestInterest || optOut
      ? rolloverFromBalance + redemptionAmount + interestEarned + referralBonus
      : rolloverFromBalance + redemptionAmount;

  let withdrawalTotal = 0;
  if (fullReinvestment) {
    if (!reinvestInterest) withdrawalTotal = interestEarned + referralBonus;
  } else {
    withdrawalTotal = reinvestInterest
      ? balanceToWithdraw
      : balanceToWithdraw + interestEarned + referralBonus;
  }

  let columnDistribution;
  if (!fullReinvestment || (fullReinvestment && reinvestInterest)) {
    columnDistribution = redemptionAmount && reinvestInterest ? 2.4 : 3;
  } else {
    columnDistribution = redemptionAmount && reinvestInterest ? 3 : 4;
  }
  const showRolloverRow = !optOut || !!redemptionAmount;
  const showWithdrawalRow = !!withdrawalTotal;
  const showWithdrawalRowInterest = optOut || !reinvestInterest;
  const showWithdrawalRowReferralBonus =
    !!referralBonus && (optOut || !reinvestInterest);

  return (
    <div data-testid="summary">
      {showRolloverRow && (
        <Row>
          <Col md={columnDistribution}>
            <div className="text-label">Rollover Total</div>
            <p className="strong">{utils.formatCurrency(totalAmount)}</p>
          </Col>
          <Col md={columnDistribution}>
            <div className="text-label">Balance Rollover</div>
            <p>{utils.formatCurrency(rolloverFromBalance)}</p>
          </Col>
          {redemptionAmount && (
            <Col md={columnDistribution}>
              <div className="text-label">Balance Redemption</div>
              <p>{utils.formatCurrency(redemptionAmount)}</p>
            </Col>
          )}
          {reinvestInterest && (
            <>
              {referralBonus > 0 && (
                <Col md={columnDistribution}>
                  <div className="text-label">Referral Bonus Rollover</div>
                  <p>{utils.formatCurrency(referralBonus)}</p>
                </Col>
              )}
              <Col md={columnDistribution}>
                <div className="text-label">Interest Rollover</div>
                <p>{utils.formatCurrency(interestEarned)}</p>
              </Col>
            </>
          )}
        </Row>
      )}
      {showWithdrawalRow && (
        <>
          <hr className={styles.rowSeparator} />
          <Row>
            <Col md={columnDistribution}>
              <div className="text-label">Withdrawal Total</div>
              <p className="strong">{utils.formatCurrency(withdrawalTotal)}</p>
            </Col>
            {!fullReinvestment && (
              <Col md={columnDistribution}>
                <div className="text-label">Balance Withdrawal</div>
                <p>{utils.formatCurrency(balanceToWithdraw)}</p>
              </Col>
            )}
            {showWithdrawalRowInterest && (
              <Col md={columnDistribution}>
                <div className="text-label">Interest Withdrawal</div>
                <p>{utils.formatCurrency(interestEarned)}</p>
              </Col>
            )}
            {showWithdrawalRowReferralBonus && (
              <Col md={columnDistribution}>
                <div className="text-label">Referral Bonus Withdrawal</div>
                <p>{utils.formatCurrency(referralBonus)}</p>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

const getReinvestmentSummaryParagraph = (formValues: FormFields) => {
  const {
    reinvest_interest: reinvestInterest,
    reinvestment_option: reinvestmentOption
  } = formValues;

  if (reinvestmentOption === 'full') {
    if (reinvestInterest)
      return 'You are going to reinvest all of your funds and accrued interest to the next destination offering.';
    else
      return 'You are going to withdraw your accrued interest and reinvest remaining funds to the next destination offering.';
  }

  if (reinvestmentOption === 'partial') {
    if (reinvestInterest)
      return 'You are going to reinvest part of your funds and all of your accrued interest to the next destination offering.';
    else
      return 'You are going to withdraw your accrued interest and reinvest part of your funds to the next destination offering.';
  }

  return 'You are going to opt-out of reinvesting.';
};

type Params = {
  investment_id: string;
};

type Props = {
  dispatch: Dispatch;
  loading: boolean;
  reinvestment: Reinvestment;
};

const STNElections = ({ dispatch, loading, reinvestment }: Props) => {
  const params = useParams<Params>();
  const [internalError, setInternalError] = useState('');
  const [step, setStep] = useState(1);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0
  });

  const investmentAmount = reinvestment?.amount;
  const investmentIncrement = reinvestment?.offering?.investment_increment;
  const redemptionAmount = reinvestment?.redemption_amount;

  const defaultValues = {
    reinvest_interest: reinvestment ? reinvestment.reinvest_interest : true,
    reinvestment_amount: reinvestment?.reinvestment_amount,
    reinvestment_option: reinvestment?.reinvestment_option
  };

  const validationSchema =
    step === 1 ? stnElectionsStepOneSchema : stnElectionsStepTwoSchema;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    context: {
      amount: investmentAmount,
      increment: investmentIncrement
    },
    resolver: yupResolver(validationSchema),
    values: defaultValues
  });

  const formValues = watch();
  const selectedReinvestmentOption = formValues.reinvestment_option;

  useEffect(() => {
    document.title = 'Investment Elections | EquityMultiple';

    dispatch(loadReinvestment(params.investment_id)).catch(err => {
      setInternalError(err.body.message);
    });
  }, [dispatch, params.investment_id]);

  useEffect(() => {
    const handleSetTimeLeft = () => {
      const deadline = reinvestment.elections_end_date;
      const end = moment(deadline).tz('America/New_York').endOf('day');
      const now = moment();
      let days = 0;
      let hours = 0;
      let minutes = 0;

      const diff = end.diff(now);

      if (Math.sign(diff) === 1) {
        const diffDuration = moment.duration(diff);
        // Since we're showing exact hours, round down the number of days to the nearest integer.
        // This will show 0 if under 24 hours
        days = Math.floor(diffDuration.asDays());
        hours = diffDuration.hours();
        minutes = diffDuration.minutes();
      } else {
        // Time has expired, reload the page and the API will return error state
        window.location.reload();
      }

      setTimeLeft({
        days,
        hours,
        minutes
      });
    };

    let timerInterval;

    if (reinvestment) {
      handleSetTimeLeft();
      timerInterval = setInterval(handleSetTimeLeft, 30000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [reinvestment]);

  const onStepOneSubmit = () => {
    setStep(2);
    window.scrollTo(0, 0);
  };

  const handleReinvestmentOptionChange = value => {
    if (reinvestment.redeemed) {
      setValue('reinvest_interest', value === 'full');
    }
  };

  const onSubmit = values => {
    const submitValues = { ...values };
    if (submitValues.reinvestment_option !== 'opt_out') {
      submitValues.opt_out_reason = null;
    } else if (submitValues.opt_out_reason === 'Other') {
      submitValues.opt_out_reason = submitValues.other_reason;
    }
    delete submitValues.other_reason;

    return dispatch(
      updateReinvestmentElections(params.investment_id, submitValues)
    )
      .then(() => {
        setStep(3);
      })
      .catch(err => throwReactHookFormSubmissionErrors(err, setError));
  };

  const offering = reinvestment?.offering;
  const destinationOffering = reinvestment?.destination_offering;
  const destinationClosing = reinvestment?.destination_closing;

  return (
    <Container className="container-narrow">
      <h2 className={styles.heading}>Your Alpine Note Investment</h2>

      {redemptionAmount && (
        <Alert type="warning">
          This investment has been flagged for pre-maturity redemption and
          reinvestment into another offering. Please reach out to IR for
          questions or clarification.
        </Alert>
      )}

      {loading ? (
        <Card>
          <LoadingSkeleton />
        </Card>
      ) : (
        <>
          {internalError && (
            <Card>
              <div className="text-center">
                <h4 className="margin-xx">{internalError}</h4>
                <Button className={styles.backButton} wrapper={<Link to="/" />}>
                  Back to Home
                </Button>
              </div>
            </Card>
          )}
          {!internalError && reinvestment && (
            <>
              {step === 1 && (
                <>
                  <Card>
                    <h4 className="margin-xx margin-top-0">At A Glance</h4>
                    {getReinvestmentDetails(reinvestment, offering)}
                  </Card>
                  <form onSubmit={handleSubmit(onStepOneSubmit)}>
                    <Card>
                      <h4 className="margin-xx margin-top-0">
                        Time Remaining until Reinvestment
                      </h4>
                      <div className={styles.timer}>
                        <div
                          className={styles.timerGroup}
                          data-testid="countdownDays"
                        >
                          <div>{timeLeft.days}</div>
                          <div>Days</div>
                        </div>
                        <div
                          className={styles.timerGroup}
                          data-testid="countdownHours"
                        >
                          <div>{timeLeft.hours}</div>
                          <div>Hours</div>
                        </div>
                        <div
                          className={styles.timerGroup}
                          data-testid="countdownMinutes"
                        >
                          <div>{timeLeft.minutes}</div>
                          <div>Minutes</div>
                        </div>
                      </div>
                      {destinationOffering && (
                        <Row className="margin-x">
                          <Col md={4} xs={6}>
                            <div className="text-label">
                              Destination Offering
                            </div>
                            <p>{destinationOffering.title}</p>
                          </Col>
                          <Col md={4} xs={6}>
                            <div className="text-label">Effective Date</div>
                            <p data-testid="effectiveDate">
                              {utils.dateFormat(
                                destinationClosing?.date_invested
                              ) || 'TBD'}
                            </p>
                          </Col>
                          <Col md={4} xs={6}>
                            <div className="text-label">Maturity Date</div>
                            <p data-testid="maturityDate">
                              {utils.dateFormat(
                                destinationOffering.matures_on
                              ) || 'TBD'}
                            </p>
                          </Col>
                        </Row>
                      )}

                      {!redemptionAmount && (
                        <>
                          <h4 className="margin-xx">Reinvestment Options</h4>

                          <Row className="margin-x">
                            <Col md={6}>
                              <Controller
                                control={control}
                                name="reinvestment_option"
                                render={({ field }) => (
                                  <Select
                                    {...setFieldProps(field, errors)}
                                    label="Reinvestment Option"
                                    onChange={e => {
                                      field.onChange(e);
                                      handleReinvestmentOptionChange(e);
                                    }}
                                    options={
                                      reinvestment.redeemed
                                        ? reinvestmentOptions.filter(
                                            option => option.value != 'partial'
                                          )
                                        : reinvestmentOptions
                                    }
                                  />
                                )}
                              />
                            </Col>
                            {selectedReinvestmentOption === 'partial' && (
                              <Col md={6}>
                                <Controller
                                  control={control}
                                  name="reinvestment_amount"
                                  render={({ field }) => (
                                    <Input
                                      {...setFieldProps(field, errors)}
                                      dollarMask
                                      inputMaskOptions={numberMaskOptions}
                                      label="Amount"
                                      maxLength={12}
                                    />
                                  )}
                                />
                              </Col>
                            )}
                          </Row>

                          {selectedReinvestmentOption !== 'opt_out' &&
                            !reinvestment.redeemed && (
                              <div className="margin-x">
                                <Controller
                                  control={control}
                                  name="reinvest_interest"
                                  render={({ field }) => (
                                    <>
                                      <RadioButton
                                        {...setRadioFieldProps(
                                          field,
                                          errors,
                                          true
                                        )}
                                        hideError
                                        label="Rollover Interest Earned"
                                      />
                                      <RadioButton
                                        {...setRadioFieldProps(
                                          field,
                                          errors,
                                          false
                                        )}
                                        hideError
                                        label="Withdraw Interest Earned"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            )}
                        </>
                      )}
                    </Card>
                    {formValues.reinvestment_option && (
                      <Card>
                        <h4 className="margin-x margin-top-0">Summary</h4>
                        <p className="margin-xx">
                          {getReinvestmentSummaryParagraph(formValues)}
                        </p>
                        {getReinvestmentSummary(formValues, reinvestment)}
                        {!redemptionAmount && (
                          <Button
                            className="float-right margin-top-xx"
                            style={{
                              width: '180px'
                            }}
                            type="submit"
                          >
                            Save
                          </Button>
                        )}
                      </Card>
                    )}
                  </form>
                </>
              )}

              {step === 2 && (
                <Card>
                  <h4 className="margin-xxx">Confirm Your Elections</h4>

                  <div>You have elected to:</div>
                  <ul>
                    <h6>
                      {selectedReinvestmentOption === 'full' && (
                        <li>Fully opt-in to reinvesting</li>
                      )}
                      {selectedReinvestmentOption === 'partial' && (
                        <li>Partially opt-in to reinvesting</li>
                      )}
                      {selectedReinvestmentOption === 'opt_out' && (
                        <li>Fully opt-out of reinvesting</li>
                      )}
                      {selectedReinvestmentOption !== 'opt_out' &&
                        (formValues.reinvest_interest ? (
                          <li>Reinvest interest earned</li>
                        ) : (
                          <li>Withdraw interest earned</li>
                        ))}
                    </h6>
                  </ul>
                  <div>
                    Please click confirm to save these changes or cancel to make
                    further changes.
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    {selectedReinvestmentOption === 'opt_out' && (
                      <div className="margin-xx">
                        <h6>Reasons for opting out:</h6>
                        <div className="margin-xx">
                          <Controller
                            control={control}
                            name="opt_out_reason"
                            render={({ field }) => (
                              <>
                                <RadioButton
                                  {...setRadioFieldProps(
                                    field,
                                    errors,
                                    'The return rate is too low.'
                                  )}
                                  hideError
                                  label="The return rate is too low."
                                />
                                <RadioButton
                                  {...setRadioFieldProps(
                                    field,
                                    errors,
                                    'I need liquidity at this time.'
                                  )}
                                  hideError
                                  label="I need liquidity at this time."
                                />
                                <RadioButton
                                  {...setRadioFieldProps(
                                    field,
                                    errors,
                                    'I am withdrawing to fund a different EquityMultiple investment.'
                                  )}
                                  hideError
                                  label="I am withdrawing to fund a different EquityMultiple investment."
                                />
                                <RadioButton
                                  {...setRadioFieldProps(
                                    field,
                                    errors,
                                    'I am withdrawing to invest on a different platform.'
                                  )}
                                  hideError
                                  label="I am withdrawing to invest on a different platform."
                                />
                                <RadioButton
                                  {...setRadioFieldProps(
                                    field,
                                    errors,
                                    'Other'
                                  )}
                                  label="Other (please specify)"
                                />
                              </>
                            )}
                          />
                        </div>
                        {formValues.opt_out_reason === 'Other' && (
                          <Controller
                            control={control}
                            name="other_reason"
                            render={({ field }) => (
                              <Input
                                {...setFieldProps(field, errors)}
                                label="Reason"
                              />
                            )}
                          />
                        )}
                      </div>
                    )}

                    <FormError errors={errors} />

                    <div className="forwardBackButtonWrap margin-top-xx">
                      <Button
                        loading={isSubmitting}
                        style={{
                          width: '140px'
                        }}
                        type="submit"
                      >
                        Confirm
                      </Button>
                      <button
                        className="text-link"
                        onClick={() => setStep(1)}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Card>
              )}

              {step === 3 && (
                <Card className="text-center">
                  <h4 className="margin-xxx">
                    Your elections have been confirmed.
                  </h4>

                  <div className="forwardBackButtonWrap margin-top-xx">
                    <Button
                      style={{
                        width: '140px'
                      }}
                      wrapper={<Link to="/" />}
                    >
                      Okay
                    </Button>
                    <button
                      className="text-link"
                      onClick={() => setStep(1)}
                      type="button"
                    >
                      Change elections
                    </button>
                  </div>
                </Card>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.investments.loadingReinvestment,
    reinvestment: store.investments.reinvestment
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(STNElections);
