import { Button, EMLoadingIcon } from '@equitymultiple/react-eui';
import xLogo from 'images/logos/x.svg?url';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { User } from 'types/actions/auth';
import { LoadInvitationsResponse } from 'types/actions/invite';

import {
  loadInvitations,
  resendInviteAction
} from '../../redux/actions/invite';
import {
  INVITE_SHOW_LEARN_MORE_MODAL,
  SHOW_EMAIL_INVITE_MODAL
} from '../../redux/actions/modals';
import { Dispatch } from '../../types/redux';
import humane from '../../utilities/humane';
import { linkedInShare, twitterShare } from '../../utilities/socialShare';
import {
  CreatedInvites,
  EmailInviteModal,
  LearnMoreModal,
  PendingInvites
} from './components';
import * as styles from './Invite.module.scss';

type Props = {
  dispatch: Dispatch;
  invitationsData: LoadInvitationsResponse;
  inviteShowLearnMoreModal: boolean;
  loading: boolean;
  resending: boolean;
  showEmailInviteModal: boolean;
  user: User;
};

const Invite = ({
  dispatch,
  invitationsData,
  inviteShowLearnMoreModal,
  loading,
  resending,
  showEmailInviteModal,
  user
}: Props) => {
  const [copied, setCopied] = useState(false);

  const resendInvite = (e, userToResend) => {
    e.preventDefault();
    const id = userToResend.id;
    dispatch(resendInviteAction(id))
      .then(() => {
        humane.notice(
          'Successfully delivered invitation mail to ' + userToResend.email
        );
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const showLearnMoreModal = e => {
    e.preventDefault();

    dispatch({ showModal: true, type: INVITE_SHOW_LEARN_MORE_MODAL });
  };

  const hideLearnMoreModal = () => {
    dispatch({ showModal: false, type: INVITE_SHOW_LEARN_MORE_MODAL });
  };

  const showEmailInviteModalHandler = e => {
    e.preventDefault();
    window.scrollTo(0, 0);

    dispatch({ showEmailModal: true, type: SHOW_EMAIL_INVITE_MODAL });
  };

  const hideEmailInviteModal = () => {
    dispatch({ showEmailModal: false, type: SHOW_EMAIL_INVITE_MODAL });
  };

  const shareToLinkedIn = e => {
    e.preventDefault();

    linkedInShare(e.currentTarget);
  };

  const shareToTwitter = e => {
    e.preventDefault();

    twitterShare(e.currentTarget);
  };

  useEffect(() => {
    document.title = 'Invite | EquityMultiple';
    dispatch(loadInvitations());
  }, [dispatch]);

  const pending = invitationsData?.invitations?.pending;

  const created = invitationsData?.invitations?.created;
  const userInvitiationData = invitationsData?.current_user;

  return loading || !userInvitiationData || !user ? (
    <EMLoadingIcon />
  ) : (
    <div className={styles.requestInvite}>
      <div className={styles.inviteImageContainer}>
        <div className={styles.backgroundImageContainer}>
          <div className={styles.backgroundImage} />
        </div>
        <div className={styles.imageOverlay}>
          <h1>Even Better Returns For You and Your Friends</h1>
          <p>
            When you invite your friends and they invest, you both earn credit
            toward improved return.{' '}
          </p>
          <button
            className={styles.hiwButton}
            onClick={showLearnMoreModal}
            type="button"
          >
            How it works <i className="fa fa-arrow-right" />
          </button>
          <div className={styles.inviteContent}>
            <div className={styles.urlInvite} role="tabpanel">
              <div className={styles.paneContent}>
                <p>Your Personal Referral Link</p>
                <span className={styles.referralLink}>
                  {userInvitiationData.referral_link}
                </span>
                <CopyToClipboard
                  onCopy={() => setCopied(true)}
                  text={userInvitiationData.referral_link}
                >
                  <div>
                    {!copied && (
                      <Button
                        className={styles.referralLink}
                        data-clipboard-target="#referralLink"
                        variant="outlinedWhite"
                      >
                        Copy Link
                      </Button>
                    )}
                    {copied && (
                      <div>
                        <span className={styles.copiedToClipboardText}>
                          Copied to Clipboard
                        </span>
                      </div>
                    )}
                  </div>
                </CopyToClipboard>
                <div className={styles.paneContentDivider}>
                  {' '}
                  <hr />{' '}
                </div>

                <ul className={styles.navTabs} role="tablist">
                  <li className={styles.emailInvite}>
                    <button onClick={showEmailInviteModalHandler} type="button">
                      <i className="fa fa-envelope-o" />
                      &nbsp;
                      <span>Email</span>
                    </button>
                  </li>

                  <li>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${userInvitiationData.referral_link}/?utm_campaign=user-ref&utm_content=${user.email}&utm_medium=social&utm_source=facebook`}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Share on Facebook"
                    >
                      <i className="fa fa-facebook" />
                      &nbsp;
                      <span>Facebook</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href={userInvitiationData.linked_in}
                      onClick={shareToLinkedIn}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Share on LinkedIn"
                    >
                      <i className="fa fa-linkedin" />
                      &nbsp;
                      <span>LinkedIn</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href={userInvitiationData.twitter}
                      onClick={shareToTwitter}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Share on X"
                    >
                      <img alt="X" src={xLogo} />
                      <span> X </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.yourInvitations}>
        <h2>Your invitations</h2>
        <section
          className={`${styles.inviteMetaDetails} ${styles.creditStatistics}`}
        >
          <Row>
            <Col className={styles.metaBlock} sm={4} xs={12}>
              <p className={styles.metaTitle}>earned from credits</p>
              <p className={`${styles.userCreditData} dollar-amount`}>
                {userInvitiationData.earned_from_credits
                  ? `$${userInvitiationData.earned_from_credits}`
                  : '$0'}
              </p>
            </Col>
            <Col className={styles.metaBlock} sm={4} xs={12}>
              <p className={styles.metaTitle}>remaining credits</p>
              <p className={styles.userCreditData}>
                {userInvitiationData.remaining_credits}
              </p>
            </Col>
            <Col className={styles.metaBlock} sm={4} xs={12}>
              <p className={styles.metaTitle}>credits used</p>
              <p className={styles.userCreditData}>
                {userInvitiationData.credits_used}
              </p>
            </Col>
          </Row>
        </section>
        <hr className={styles.yourInvitationsMetricsDivider} />

        <PendingInvites
          pending={pending}
          resendInvite={resendInvite}
          resending={resending}
        />

        <CreatedInvites created={created} />

        {inviteShowLearnMoreModal && (
          <LearnMoreModal
            hideLearnMoreModal={hideLearnMoreModal}
            showModal={inviteShowLearnMoreModal}
          />
        )}

        {showEmailInviteModal && (
          <EmailInviteModal
            hideEmailInviteModal={hideEmailInviteModal}
            showEmailModal={showEmailInviteModal}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    invitationsData: store.invite.invitationsData,
    inviteShowLearnMoreModal: store.modals.inviteShowLearnMoreModal,
    loadErrors: store.invite.loadErrors,
    loading: store.invite.loading,
    resending: store.invite.resending,
    showEmailInviteModal: store.modals.showEmailInviteModal,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Invite);
