import React from 'react';

import * as sharedStyles from '../../OfferingShared.module.scss';
import * as styles from './Media.module.scss';

interface Props {
  data: {
    description: string;
    name: string;
    source: 'soundcloud' | 'vimeo' | 'youtube';
    url: string;
  };
}

const Media = ({ data: { description, name, source, url } }: Props) => {
  const getEmbedUrl = () => {
    let embedUrl;
    switch (source) {
      case 'soundcloud':
        embedUrl = `https://w.soundcloud.com/player/?url=${url}&color=%23546880&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
        break;
      case 'vimeo':
        embedUrl = `https://player.vimeo.com/video/${url.split('/').pop()}`;
        break;
      case 'youtube':
        embedUrl = `${url.replace(
          '/watch?v=',
          '/embed/'
        )}?rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=ytplayer&amp`;
        break;
    }
    return embedUrl;
  };

  const embedUrl = getEmbedUrl();

  return (
    <div>
      <div
        className={`${
          source === 'soundcloud'
            ? styles.soundcloudEmbed
            : 'embed-responsive-16by9 embed-responsive'
        }`}
      >
        <iframe
          allowFullScreen={source !== 'soundcloud'}
          frameBorder="0"
          height="100%"
          scrolling="no"
          src={embedUrl}
          title={name}
          width="100%"
        />
      </div>
      {description && (
        <div className={sharedStyles.mediaDescription}>{description}</div>
      )}
    </div>
  );
};

export default Media;
