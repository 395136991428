import React from 'react';

import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import * as styles from './Articles.module.scss';

const Articles = () => (
  <div className={styles.articles}>
    <ArticleCard
      className={styles.articleCard}
      link="https://equitymultiple.com/wp-content/uploads/2024/07/Sample-Starter-Portfolios-Foundations-Alpine-and-Ascent.pdf"
      title="[Case Study] Sample Starter Portfolios: Foundations, Ascent, Alpine"
    />
    <ArticleCard
      className={styles.articleCard}
      link="https://equitymultiple.com/wp-content/uploads/2024/09/summary-track-record-3Q2024.pdf"
      title="Full Investment-Level Track Record"
    />
    <ArticleCard
      className={styles.articleCard}
      link="https://equitymultiple.com/wp-content/uploads/2024/07/Diversification-A-Comprehensive-Guide.pdf"
      title="Diversification: A Comprehensive Guide"
    />
  </div>
);

export default Articles;
