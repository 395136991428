import { getFirstIncompleteStepRoute } from 'containers/Accounts/helpers';
import { loadDistributionReinvestmentInOffering } from 'redux/actions/offerings';
import { TransactionStatus } from 'types/actions/investments';
import { Dispatch } from 'types/redux';
import { ascentOfferingId } from 'utilities/constants';

import humane from '../../utilities/humane';
import { canAccessPage, mustCompleteProfile } from '../../utilities/user';

export const checkIfEditing = (amount, transactions = [], refunds = []) => {
  let totalFunded = 0;
  transactions.forEach(transaction => {
    totalFunded += parseFloat(transaction.amount);
  });
  refunds.forEach(transaction => {
    totalFunded -= parseFloat(transaction.amount);
  });
  return amount > totalFunded;
};

export const amountInvested = (transaction, originationFees = []) => {
  let totalAmount = parseFloat(transaction.amount);
  originationFees
    .filter(fee => fee.event_relationships === transaction.event_relationships)
    .forEach(fee => {
      totalAmount += parseFloat(fee.amount);
    });
  return totalAmount;
};

export const getTransactionStatusColor = (status: TransactionStatus) => {
  if (status === 'pending') {
    return '#ef5b09';
  } else if (status === 'posted') {
    return '#4caf50';
  } else if (status === 'draft') {
    return '#2a2b30';
  }
  return '#eb5424';
};

export const hasPendingRolloverTransaction = transactions =>
  transactions?.some(
    transaction =>
      transaction.status === 'pending' &&
      transaction.payment_method === 'ROLLOVER'
  );

export const redirectIfPendingRollover = (navigate, investment, closingId) => {
  if (hasPendingRolloverTransaction(investment?.transactions?.contributions)) {
    humane.error(
      'You already have a pending roll over transaction for this investment'
    );
    navigate(`/invest/${closingId}/investment/${investment.id}/confirm`, {
      replace: true
    });
  }
};

export const redirectIfUserCannotInvest = (navigate, user) => {
  if (!canAccessPage('investment', user)) {
    humane.error('Please complete an investment account before investing');

    let route = '/';

    if (mustCompleteProfile(user)) {
      route = getFirstIncompleteStepRoute(user);
    }

    navigate(route, { replace: true });
  }
};

export const redirectIfInvestmentIsClosed = (navigate, closing) => {
  if (!closing.can_invest) {
    humane.error('Investment is no longer available in this closing');
    navigate('/', { replace: true });
  }
};

export const redirectIfInvestmentOnWaitlist = (
  navigate,
  investment,
  closingId
) => {
  if (investment.status === 'waitlist') {
    humane.error('You are already on the waitlist for this offering');
    navigate(`/invest/${closingId}/investment/${investment.id}/interest`, {
      replace: true
    });
  }
};

export const redirectUserWithInterestAscentInvestment = (
  navigate,
  dispatch: Dispatch,
  offeringId: number
) => {
  if (offeringId === ascentOfferingId) {
    dispatch(loadDistributionReinvestmentInOffering(ascentOfferingId))
      .then(() => {
        navigate(`/offerings/${ascentOfferingId}/distribution_reinvestment`, {
          replace: true
        });
      })
      .catch(() => {});
  }
};
