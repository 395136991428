import { AnyAction } from 'redux';

import * as actions from '../actions/offering-tax-statuses';
const initialState = {
  loadedOfferingTaxStatuses: false,
  loadingOfferingTaxStatuses: false,
  offeringTaxStatuses: []
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.FETCH_TAX_OFFERING_STATUSES:
      return {
        ...state,
        loadedOfferingTaxStatuses: false,
        loadingOfferingTaxStatuses: true
      };
    case actions.FETCH_TAX_OFFERING_STATUSES_SUCCESS:
      return {
        ...state,
        loadedOfferingTaxStatuses: true,
        loadingOfferingTaxStatuses: false,
        offeringTaxStatuses: action.result.offering_tax_statuses,
        startOfSeason: action.result.start_of_season
      };
    case actions.FETCH_TAX_OFFERING_STATUSES_FAIL:
      return {
        ...state,
        loadedOfferingTaxStatuses: false,
        loadingOfferingTaxStatuses: false
      };
    default:
      return state;
  }
};
