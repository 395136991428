import iconDocPdf from 'images/icons/icon-document-pdf.png';
import React from 'react';
import { AccountDocument } from 'types/actions/account';

import * as styles from './DocumentPreview.module.scss';

interface Props {
  document: AccountDocument;
  documentType: string;
}

const DocumentPreview = ({ document, documentType }: Props) => {
  const isPdf = document?.name?.endsWith('.pdf');

  return (
    <div className={styles.documentPreview}>
      <img
        alt={documentType}
        className={isPdf ? styles.filePdf : styles.fileImage}
        src={isPdf ? iconDocPdf : document.url}
      />
    </div>
  );
};

export default DocumentPreview;
