import { Alert, Button, Input, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { stateOptions } from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { ProfileValues, SharedProfileValues } from '../types';
import { addressSchema } from '../validation';

const Address = ({ dispatch, user }: SharedProfileValues) => {
  const navigate = useNavigate();

  const defaultValues = getUserAttributes(user);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<ProfileValues>({
    resolver: yupResolver(addressSchema),
    values: defaultValues
  });

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'address_attributes.address',
      'address_attributes.address2',
      'address_attributes.city',
      'address_attributes.state',
      'address_attributes.postal_code'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/document_identification');
        });
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  return (
    <AccountProgressContainer showSkipLink signupStage="profile">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Address</h3>
        <p className="margin-xx">
          Please provide your current residential address below. If your address
          changes in the future, remember to update it in your profile.
        </p>
        <Alert type="neutral">
          <div>
            1. We prohibit PO Boxes
            <br />
            2. The address must be a US address and cannot be for a registered
            agent
          </div>
        </Alert>
        <Row>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="address_attributes.address"
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="Address" />
              )}
            />
          </Col>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="address_attributes.address2"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Unit / Apt / Suite"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <Controller
              control={control}
              name="address_attributes.city"
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="City" />
              )}
            />
          </Col>
          <Col md={4} xs={12}>
            <Controller
              control={control}
              name="address_attributes.state"
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  label="State"
                  options={stateOptions}
                />
              )}
            />
          </Col>
          <Col md={4} xs={12}>
            <Controller
              control={control}
              name="address_attributes.postal_code"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Zip Code"
                  mask="99999"
                />
              )}
            />
          </Col>
        </Row>

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            className="button-fixed-width margin-top-xx"
            loading={isSubmitting}
            type="submit"
            variant="orange"
          >
            Continue
          </Button>
          <Button
            className="button-fixed-width margin-top-xx arrowBackButton"
            data-testid="backLink"
            disabled={isSubmitting}
            variant="outlined"
            wrapper={<Link to="/users/profile/kyc" />}
          >
            <Back />
          </Button>
        </div>
      </form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Address);
