export const taxDocumentTableHeaders = [
  { label: 'select', value: 'select' },
  { label: 'Document', value: 'title' },
  { label: 'Account', value: 'investment_account_title' },
  { label: 'Type', value: 'type' },
  { label: 'Year', value: 'year' },
  { label: 'Download', value: 'download' }
];

export const getDocumentsByOfferingIdAndYear = (documents, offeringId, years) =>
  documents.filter(
    document =>
      document.offering_id === parseInt(offeringId) &&
      (years.length === 0 || years.includes(document.year))
  );

export const constructTaxDocumentSelection = documents => {
  const years = [
    ...new Set(
      documents
        .map(document => document.year)
        .sort((a, b) => (parseInt(a) < parseInt(b) ? 1 : -1))
    )
  ];
  const selectOptions = [];
  years.forEach(year => {
    selectOptions.push({
      label:
        year +
        ' (' +
        documents
          .map(document => document.year)
          .filter(document => document === year).length +
        ')',
      value: year
    });
  });

  return selectOptions;
};

export const compileMetricsForTaxDocumentsPage = offeringTaxStatuses => ({
  available: offeringTaxStatuses.filter(status => status.status === 'Available')
    .length,
  totalDocs: offeringTaxStatuses.length
});

export const determineCellValue = variable =>
  variable[taxDocumentTableHeaders[1].value];

export const filterDocuments = (documents, selectedYears) =>
  documents.filter(
    document =>
      selectedYears.length === 0 || selectedYears.includes(document.year)
  );

export const sortDocuments = documents => {
  const documentsSorted = documents.sort((a, b) =>
    determineCellValue(a) === determineCellValue(b)
      ? 0
      : determineCellValue(a) > determineCellValue(b)
        ? 1
        : -1
  );
  return documentsSorted;
};

export const sortAndFilterDocuments = (documents, selectedYears) =>
  sortDocuments(filterDocuments(documents, selectedYears));
