import {
  CreateSignInPayload,
  FetchSignInResponse,
  ForgotPasswordPayload,
  LoadAuthResponse,
  ResetPasswordPayload,
  UserDevicesResponse
} from 'types/actions/auth';
import { AsyncActionCreator } from 'types/redux';

export const LOAD = 'auth/LOAD_ALL';
export const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
export const LOAD_FAIL = 'auth/LOAD_FAIL';

export const LOAD_DEVICES = 'auth/LOAD_DEVICES';
export const LOAD_DEVICES_SUCCESS = 'auth/LOAD_DEVICES_SUCCESS';
export const LOAD_DEVICES_FAIL = 'auth/LOAD_DEVICES_FAIL';

export const RESEND_UNLOCK_EMAIL = 'auth/RESEND_UNLOCK_EMAIL';
export const RESEND_UNLOCK_EMAIL_SUCCESS = 'auth/RESEND_UNLOCK_EMAIL_SUCCESS';
export const RESEND_UNLOCK_EMAIL_FAIL = 'auth/RESEND_UNLOCK_EMAIL_FAIL';

export const RESEND_CONFIRMATION_EMAIL = 'auth/RESEND_CONFIRMATION_EMAIL';
export const RESEND_CONFIRMATION_EMAIL_SUCCESS =
  'auth/RESEND_CONFIRMATION_EMAIL_SUCCESS';
export const RESEND_CONFIRMATION_EMAIL_FAIL =
  'auth/RESEND_CONFIRMATION_EMAIL_FAIL';

export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'auth/FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'auth/RESET_PASSWORD_FAIL';

export const VALIDATE_RESET_PASSWORD = 'auth/VALIDATE_RESET_PASSWORD';
export const VALIDATE_RESET_PASSWORD_SUCCESS =
  'auth/VALIDATE_RESET_PASSWORD_SUCCESS';
export const VALIDATE_RESET_PASSWORD_FAIL = 'auth/VALIDATE_RESET_PASSWORD_FAIL';

export const GET_SIGNIN = 'auth/GET_SIGNIN';
export const GET_SIGNIN_SUCCESS = 'auth/GET_SIGNIN_SUCCESS';
export const GET_SIGNIN_FAIL = 'auth/GET_SIGNIN_FAIL';

export const GET_GOOGLE_SIGNIN = 'auth/GET_GOOGLE_SIGNIN';
export const GET_GOOGLE_SIGNIN_SUCCESS = 'auth/GET_GOOGLE_SIGNIN_SUCCESS';
export const GET_GOOGLE_SIGNIN_FAIL = 'auth/GET_GOOGLE_SIGNIN_FAIL';

export const CREATE_SIGNIN = 'auth/CREATE_SIGNIN';
export const CREATE_SIGNIN_SUCCESS = 'auth/CREATE_SIGNIN_SUCCESS';
export const CREATE_SIGNIN_FAIL = 'auth/CREATE_SIGNIN_FAIL';

export const LOGOUT = 'auth/LOGOUT_ALL';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';

export const START_SUBMISSION = 'auth/START_SUBMISSION';

export function createSignin(data: CreateSignInPayload) {
  return {
    promise: client => client.post('/mkt/users/sign_in', { data }),
    types: [CREATE_SIGNIN, CREATE_SIGNIN_SUCCESS, CREATE_SIGNIN_FAIL]
  };
}

export function fetchSignin(): AsyncActionCreator<FetchSignInResponse> {
  return {
    promise: client => client.get<FetchSignInResponse>('/mkt/users/sign_in'),
    types: [GET_SIGNIN, GET_SIGNIN_SUCCESS, GET_SIGNIN_FAIL]
  };
}

export function loadAuth(): AsyncActionCreator<LoadAuthResponse> {
  return {
    promise: client => client.get<LoadAuthResponse>('/mkt/users/me'),
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL]
  };
}

export function userDevices(): AsyncActionCreator<UserDevicesResponse> {
  return {
    promise: client => client.get<UserDevicesResponse>('/mkt/users/devices'),
    types: [LOAD_DEVICES, LOAD_DEVICES_SUCCESS, LOAD_DEVICES_FAIL]
  };
}

export function removeDevice(id) {
  return {
    promise: client => client.del(`/mkt/users/devices/${id}`),
    types: [LOAD_DEVICES, LOAD_DEVICES_SUCCESS, LOAD_DEVICES_FAIL]
  };
}

export function resendUnlockEmail(data) {
  return {
    promise: client => client.post('/mkt/users/resend_unlock_email', { data }),
    types: [
      RESEND_UNLOCK_EMAIL,
      RESEND_UNLOCK_EMAIL_SUCCESS,
      RESEND_UNLOCK_EMAIL_FAIL
    ]
  };
}

export function resendConfirmationEmail(data) {
  return {
    promise: client => client.post('/mkt/users/confirmation', { data }),
    types: [
      RESEND_CONFIRMATION_EMAIL,
      RESEND_CONFIRMATION_EMAIL_SUCCESS,
      RESEND_CONFIRMATION_EMAIL_FAIL
    ]
  };
}

export function forgotPassword(data: ForgotPasswordPayload) {
  return {
    promise: client => client.post('/mkt/users/password', { data }),
    types: [FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL]
  };
}

export function resetPassword(data: ResetPasswordPayload) {
  return {
    promise: client => client.put('/mkt/users/password', { data }),
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL]
  };
}

export function validateResetPasswordToken(
  token
): AsyncActionCreator<Record<string, never>> {
  return {
    promise: client =>
      client.get<Record<string, never>>(
        `/mkt/users/validate_reset_password_token?reset_password_token=${token}`
      ),
    types: [
      VALIDATE_RESET_PASSWORD,
      VALIDATE_RESET_PASSWORD_SUCCESS,
      VALIDATE_RESET_PASSWORD_FAIL
    ]
  };
}

export function logout(): AsyncActionCreator<Record<string, never>> {
  return {
    promise: client => client.get<Record<string, never>>('/mkt/users/sign_out'),
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL]
  };
}

export function setAuthSubmitting() {
  return {
    type: START_SUBMISSION
  };
}
