import {
  Card,
  ErrorBoundary,
  ToggleButton,
  Tooltip
} from '@equitymultiple/react-eui';
import { Investment } from 'containers/Portfolio/types';
import React, { useState } from 'react';

import EmAnalytics from '../../../../utilities/em_analytics';
import utils from '../../../../utilities/utils';
import { tooltips } from '../../constants';
import * as sharedStyles from '../../PortfolioShared.module.scss';
import EarnInvestments from '../EarnInvestments/EarnInvestments';
import ExitedInvestments from '../ExitedInvestments/ExitedInvestments';
import GrowInvestments from '../GrowInvestments/GrowInvestments';
import KeepInvestments from '../KeepInvestments/KeepInvestments';
import * as styles from './InvestmentSection.module.scss';

export type Section = 'keep' | 'earn' | 'grow' | 'exited';

interface Props {
  investments: Investment[];
  loading: boolean;
  section: Section;
}

const InvestmentSection = ({ investments, loading, section }: Props) => {
  const [timePeriod, setTimePeriod] = useState<'Total' | 'YTD'>('Total');

  const getTableWrap = (sectionName: Section) => {
    const props = {
      investments,
      loading,
      timePeriod
    };

    switch (sectionName) {
      case 'keep':
        return <KeepInvestments {...props} />;
      case 'earn':
        return <EarnInvestments {...props} />;
      case 'grow':
        return <GrowInvestments {...props} />;
      case 'exited':
        return <ExitedInvestments {...props} />;
    }
  };

  const handleSetTimePeriod = newTimePeriod => {
    setTimePeriod(newTimePeriod);
    EmAnalytics.track('Toggle Total and YTD', '', {
      portfolioTable: section,
      toggleType: newTimePeriod
    });
  };

  return (
    <Card className={styles.investmentSection}>
      <div className={sharedStyles.cardHeading}>
        <h3>{utils.capitalize(section)}</h3>
        <Tooltip
          className={`info-icon-margin-left ${styles.tooltip}`}
          infoIcon
          placement="top"
          tooltipContent={tooltips.titles[section]}
        />
        <ToggleButton
          activeValue={timePeriod}
          className={sharedStyles.toggleButtonWrapper}
          label1="Total"
          label2="YTD"
          onChange={value => handleSetTimePeriod(value)}
        />
      </div>
      <ErrorBoundary name={section}>{getTableWrap(section)}</ErrorBoundary>
    </Card>
  );
};

export default InvestmentSection;
