import { Button } from '@equitymultiple/react-eui';
import {
  getInvestmentWizardRoute,
  getOfferingCardValue
} from 'containers/Invest/components/OfferingCard/helpers';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { User } from 'types/actions/auth';
import { Closing } from 'types/api/closing';
import { Offering } from 'types/api/offering';
import { segmentTrackData } from 'utilities/analyticsHelpers';
import EmAnalytics from 'utilities/em_analytics';

import * as styles from './InvestButton.module.scss';

export const getButtonData = (closing: Closing) => {
  if (closing?.is_investment_allowed) {
    const closingInInterest = closing.stage === 'interest';

    let investRoute = `/invest/${closing.id}/investment/new`;
    let investButtonText = getOfferingCardValue(
      closing.stage,
      closing.investment
    );

    if (closing.investment) {
      investRoute = getInvestmentWizardRoute(closing.id, closing.investment);
    } else {
      if (closingInInterest) investButtonText = 'Indicate Interest';
      else investButtonText = 'Invest';
    }

    return {
      route: investRoute,
      text: investButtonText
    };
  }

  return {};
};

interface Props {
  className?: string;
  closing: Closing;
  mustVerifyToInvest: boolean;
  offering: Offering;
  user: User;
}

const sendSegmentEvent = (offering, user) => {
  EmAnalytics.track('Clicked Invest Button', 'Navigation', {
    ...segmentTrackData(offering, user),
    action: 'Clicked Invest Button On OM'
  });
};

const InvestButton = ({
  className = '',
  closing,
  mustVerifyToInvest,
  offering,
  user
}: Props) => {
  const navigate = useNavigate();
  const { route, text } = getButtonData(closing);
  const showButton =
    offering && closing && user && closing.is_investment_allowed;

  const handleClick = () => {
    sendSegmentEvent(offering, user);
    navigate(route);
  };

  return showButton ? (
    <Button
      className={`${styles.button} ${className}`}
      data-testid="investButton"
      disabled={mustVerifyToInvest}
      onClick={() => handleClick()}
      variant="orange"
    >
      {text}
    </Button>
  ) : null;
};

function mapStateToProps(store) {
  return {
    closing: store.offerings.closing,
    offering: store.offerings.offering,
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(InvestButton);
