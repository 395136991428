import { Button, Card } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { HeaderSimple } from '../../components/HeaderSimple';
import mail from '../../images/mailpic.png';
import { resendUnlockEmail } from '../../redux/actions/auth';
import { Dispatch } from '../../types/redux';
import humane from '../../utilities/humane';
import * as styles from './UnlockInstructions.module.scss';

type Props = {
  dispatch: Dispatch;
  sendingUnlockEmail: boolean;
};

const UnlockInstructions = ({ dispatch, sendingUnlockEmail }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const email = location?.state?.email;

  useEffect(() => {
    if (!email) navigate('/users/sign_in', { replace: true });
  }, [email, navigate]);

  useEffect(() => {
    document.title = 'Unlock Instructions | EquityMultiple';
  }, []);
  const resendEmail = () => {
    dispatch(resendUnlockEmail({ email })).then(res => {
      humane.notice(res.message);
      setTimeout(() => {
        navigate('/users/sign_in', { replace: true });
      }, 1000);
    });
  };

  return (
    <div>
      <HeaderSimple />
      <Container className={styles.unlockInstructions}>
        <Card>
          <img alt="" src={mail} />
          <h3>For security reasons, your account has been locked.</h3>
          <p>
            Please follow the instructions sent to your e-mail address to unlock
            your account.
            <br />
            Or you can wait 24 hours for your account to be unlocked
            automatically, and you can try signing in again.
          </p>
          <Button loading={sendingUnlockEmail} onClick={resendEmail}>
            Resend Email
          </Button>
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    sendingUnlockEmail: store.auth.sendingUnlockEmail
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(UnlockInstructions);
