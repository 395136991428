import { Alert } from '@equitymultiple/react-eui';
import RecoveryCodes from 'components/RecoveryCodes/RecoveryCodes';
import SmsConfirmationForm from 'components/SmsConfirmationForm/SmsConfirmationForm';
import { PhoneFormValues } from 'containers/SignUp/types';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import {
  clearRecoveryCodes,
  regenerateRecoveryCodes
} from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { hasTwoFaEnabled } from 'utilities/user';

import PhoneForm from './PhoneForm';

interface Props {
  dispatch: Dispatch;
  loadingRecoveryCodes: boolean;
  recoveryCodes: string[];
  user: User;
}

const TwoFactorAuthentication: React.FC<Props> = ({
  dispatch,
  loadingRecoveryCodes,
  recoveryCodes,
  user
}) => {
  const [showPhoneForm, setShowPhoneForm] = useState(true);
  const [showSmsConfirmationForm, setShowSmsConfirmationForm] = useState(false);
  const [phoneFormValues, setPhoneFormValues] = useState<PhoneFormValues>();
  const [twoFaNowEnabled, setTwoFaNowEnabled] = useState(false);

  useRecaptcha();

  useEffect(() => {
    if (hasTwoFaEnabled(user)) {
      setShowPhoneForm(false);
    }
  }, [user]);

  const setTwoFaEnabledState = () => {
    setShowSmsConfirmationForm(false);
    setTwoFaNowEnabled(true);
  };

  const handlePhoneFormSubmitSuccess = (values: PhoneFormValues) => {
    setPhoneFormValues({
      ...values,
      enable_two_factor: true
    });
    setShowPhoneForm(false);
    setShowSmsConfirmationForm(true);
  };

  const handleSmsSubmitSuccess = () => {
    dispatch(loadAuth());
    setTwoFaEnabledState();
  };

  const toggleShowPhoneForm = () => {
    setShowPhoneForm(!showPhoneForm);

    // If the user has already generated backup codes in this session then clear them to prevent cluttering the form
    dispatch(clearRecoveryCodes());
  };

  const handleRegenerateRecoveryCodes = () => {
    dispatch(regenerateRecoveryCodes());
  };

  const smsEnabled = user?.otp_required_for_login;
  const twoFaEnabled = hasTwoFaEnabled(user);
  const twoFaEnabledDefaultState =
    twoFaEnabled &&
    !twoFaNowEnabled &&
    !showPhoneForm &&
    !showSmsConfirmationForm;
  const hasRecoveryCodes = recoveryCodes?.length > 0;

  return (
    <>
      <div className="margin-xxx">
        {twoFaEnabled ? (
          <Alert type="positive">
            {twoFaNowEnabled
              ? 'Thank you, two-factor authentication is now enabled through SMS.'
              : 'Two-factor authentication is enabled through SMS.'}
          </Alert>
        ) : (
          <Alert type="warning">
            <strong>
              In order to secure your account, you're required to enable
              two-factor authentication through SMS before proceeding.
            </strong>
          </Alert>
        )}
      </div>

      {twoFaEnabledDefaultState && (
        <>
          {smsEnabled && (
            <button
              className="text-link underline margin-xx"
              onClick={toggleShowPhoneForm}
              type="button"
            >
              Change Phone Number
            </button>
          )}
          {!hasRecoveryCodes && (
            <>
              <p>
                You may regenerate recovery codes that can be used to access
                your account if you&apos;re unable to retrieve a one-time
                password.
              </p>
              <p>
                Existing recovery codes will be invalidated and can no longer be
                used.
              </p>
              <button
                className={`text-link underline ${
                  loadingRecoveryCodes ? 'loading' : ''
                }`}
                disabled={loadingRecoveryCodes}
                onClick={handleRegenerateRecoveryCodes}
                type="button"
              >
                Regenerate Recovery Codes
              </button>
            </>
          )}
        </>
      )}

      {showPhoneForm && (
        <PhoneForm onSubmitSuccess={handlePhoneFormSubmitSuccess} user={user} />
      )}

      {showSmsConfirmationForm && (
        <SmsConfirmationForm
          changePhone={() => setShowSmsConfirmationForm(false)}
          onSubmitSuccess={handleSmsSubmitSuccess}
          phoneFormValues={phoneFormValues}
        />
      )}

      {hasRecoveryCodes && <RecoveryCodes recoveryCodes={recoveryCodes} />}

      {showPhoneForm && twoFaEnabled && (
        <button
          className="text-link underline margin-top-xx"
          onClick={toggleShowPhoneForm}
          type="button"
        >
          Cancel
        </button>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.userSettings.loading,
    loadingRecoveryCodes: state.userSettings.loadingRecoveryCodes,
    recoveryCodes: state.userSettings.twoFactorRecoveryCodes,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(TwoFactorAuthentication);
