import { Button, RadioButton } from '@equitymultiple/react-eui';
import React, { useState } from 'react';

import { UpdateAlert } from '../types';
import * as sharedStyles from '../UserUpdateAlertsShared.module.scss';

interface Props {
  alert: UpdateAlert;
  handleSubmitAlert: (alert: UpdateAlert) => void;
}

const TaxFilingStatus = ({ alert, handleSubmitAlert }: Props) => {
  const [value, setValue] = useState('');

  const onSubmit = () => {
    alert.action = value;
    handleSubmitAlert(alert);
  };

  return (
    <>
      <h4 className="margin-x">Tax Filing Status </h4>
      <p className="size-16">
        Please indicate your tax filing status for your Investment Account
      </p>
      <hr className={sharedStyles.hr} />
      <p className="margin-x size-16">What is your tax filing status?</p>
      <RadioButton
        checked={value === 'TF-0001'}
        hideError
        id="TF-0001"
        label="Single"
        name="tax-filing-status"
        onChange={() => {
          setValue('TF-0001');
        }}
      />
      <RadioButton
        checked={value === 'TF-0002'}
        hideError
        id="TF-0002"
        label="Married Filing Jointly"
        name="tax-filing-status"
        onChange={() => {
          setValue('TF-0002');
        }}
      />
      <RadioButton
        checked={value === 'TF-0003'}
        hideError
        id="TF-0003"
        label="Married Filing Separately"
        name="tax-filing-status"
        onChange={() => {
          setValue('TF-0003');
        }}
      />
      <RadioButton
        checked={value === 'TF-0004'}
        hideError
        id="TF-0004"
        label="Head of Household"
        name="tax-filing-status"
        onChange={() => {
          setValue('TF-0004');
        }}
      />
      <RadioButton
        checked={value === 'TF-0005'}
        id="TF-0005"
        label="Qualifying Widow(er) with Dependent Child"
        name="tax-filing-status"
        onChange={() => {
          setValue('TF-0005');
        }}
      />
      <hr className={sharedStyles.hr} />
      <div className={`${sharedStyles.footer} clearfix-after`}>
        <div className={sharedStyles.disclaimer}>
          By clicking verify you represent that this information is up-to-date
          and accurate.
        </div>
        <Button disabled={!value} onClick={onSubmit} style={{ width: 240 }}>
          Update
        </Button>
      </div>
    </>
  );
};

export default TaxFilingStatus;
