import { Table } from '@equitymultiple/react-eui';
import { Transfer } from 'containers/Portfolio/types';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import utils from '../../../../utilities/utils';
import * as sharedPaymentStyles from '../PaymentsTableShared.module.scss';
import * as styles from './TransferDetails.module.scss';

interface Props {
  transfer: Transfer;
}

const TransferDetails = ({ transfer }: Props) => {
  const transactionRows = transfer.transactions.map(transaction => ({
    cells: [
      transaction.type,
      <div key="amount">
        {utils.formatCurrency(transaction.amount, 2, true)}
      </div>
    ]
  }));
  transactionRows.push({
    cells: [
      `Total ${transfer.event_type}`,
      <div
        className={sharedPaymentStyles[transfer.event_type.toLowerCase()]}
        key="amount"
      >
        {utils.formatCurrency(transfer.amount, 2, true)}
      </div>
    ]
  });
  return (
    <div className={styles.transferDetails}>
      <Row>
        <Col lg={6}>
          <div className={styles.transferDetailsHeading}>Transfer Details</div>
          <Row>
            <Col sm={6}>
              <div className={`text-label ${styles.textLabel}`}>
                Effective Date
              </div>
              <div className={styles.value}>{transfer.effective_date}</div>
            </Col>
            <Col sm={6}>
              <div className={`text-label ${styles.textLabel}`}>
                Bank Account
              </div>
              <div className={styles.value}>
                {transfer.bank_account?.name || '-'}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className={`text-label ${styles.textLabel}`}>
                Payment Method
              </div>
              <div className={styles.value}>{transfer.payment_method}</div>
            </Col>
          </Row>
          {transfer.description && (
            <>
              <div className={`text-label ${styles.textLabel}`}>
                Description
              </div>
              <div className={styles.value}>{transfer.description}</div>
            </>
          )}
        </Col>
        <Col lg={6}>
          <Table
            className={styles.transactionsTable}
            columnHeaders={['Transactions', 'Amount']}
            rows={transactionRows}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransferDetails;
