import { Card, ToggleButton } from '@equitymultiple/react-eui';
import { DashboardInvestment } from 'containers/Portfolio/types';
import React, { useEffect, useState } from 'react';

import * as sharedStyles from '../../PortfolioShared.module.scss';
import ChartLoading from '../ChartLoading/ChartLoading';
import DiversificationChart from '../DiversificationChart/DiversificationChart';
import * as styles from './Diversification.module.scss';

interface Props {
  investments: DashboardInvestment[];
  loading: boolean;
  showNullState: boolean;
}

const Diversification = ({ investments, loading, showNullState }: Props) => {
  const [activeChart, setActiveChart] = useState('Offering Type');
  const [offeringTypeCounts, setOfferingTypeCounts] = useState(null);
  const [propertyTypeCounts, setPropertyTypeCounts] = useState(null);
  const [totalBalance, setTotalBalance] = useState(0);

  const setGraphData = () => {
    const newPropertyTypeCounts = {};
    const newOfferingTypeCounts = {};
    let newTotalBalance = 0;
    investments.forEach(investment => {
      const propertyType = investment.asset_type;
      const offeringType = investment.offering_type;
      const approach = investment.offering_approach;

      if (newPropertyTypeCounts[propertyType]) {
        newPropertyTypeCounts[propertyType].balance += investment.balance;
        newPropertyTypeCounts[propertyType].count += 1;
      } else {
        newPropertyTypeCounts[propertyType] = {
          balance: investment.balance,
          count: 1
        };
      }

      let displayedOfferingType;
      if (approach === 'Fund') {
        displayedOfferingType = 'Funds';
      } else {
        switch (offeringType) {
          // Debt + Equity & Debt + JV Equity should only occur for funds, adding them here just in case
          case 'Preferred Equity':
          case 'Senior Equity':
          case 'Debt + Equity':
          case 'Debt + JV Equity':
            displayedOfferingType = 'Preferred Equity';
            break;
          case 'Mezzanine Debt':
          case 'Senior Debt':
            displayedOfferingType = 'Debt';
            break;
          default:
            displayedOfferingType = offeringType;
            break;
        }
      }

      if (newOfferingTypeCounts[displayedOfferingType]) {
        newOfferingTypeCounts[displayedOfferingType].balance +=
          investment.balance;
        newOfferingTypeCounts[displayedOfferingType].count += 1;
      } else {
        newOfferingTypeCounts[displayedOfferingType] = {
          balance: investment.balance,
          count: 1
        };
      }

      newTotalBalance += investment.balance;
    });
    setTotalBalance(newTotalBalance);
    setOfferingTypeCounts(newOfferingTypeCounts);
    setPropertyTypeCounts(newPropertyTypeCounts);
  };

  useEffect(() => {
    if (investments) setGraphData();
  }, [investments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className={styles.diversification}>
      <div className={styles.title}>
        <h3>Diversification</h3>
        <ToggleButton
          activeValue={activeChart}
          className={`${sharedStyles.toggleButtonWrapper} ${styles.toggleButtonWrapper}`}
          label1="Offering Type"
          label2="Property Type"
          onChange={chart => setActiveChart(chart)}
        />
      </div>

      {loading ? (
        <ChartLoading heading={`${activeChart}s`} />
      ) : (
        <>
          {activeChart === 'Offering Type' && (
            <DiversificationChart
              dataPointCounts={offeringTypeCounts}
              heading="Offering Types"
              showNullState={showNullState}
              totalBalance={totalBalance}
            />
          )}
          {activeChart === 'Property Type' && (
            <DiversificationChart
              dataPointCounts={propertyTypeCounts}
              heading="Property Types"
              showNullState={showNullState}
              totalBalance={totalBalance}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default Diversification;
