import { InvestOffering } from 'types/api/offering';
import utils from 'utilities/utils';

/*
  IRR values are saved in various format e.g x, x% , x-x%
  This function takes original irr value and will return rounded value
*/
export const formatIrrForOfferingCard = irr => {
  let roundedValue = '';

  if (irr === 0) roundedValue = '0%';

  if (irr) {
    let irrString = irr.toString().trim();

    if (irrString.length) {
      irrString = irrString.replaceAll('%', '');
      const irrRange = irrString.split('-');

      if (irrRange?.length <= 2) {
        const roundedIRRRange = irrRange.map(val =>
          Math.round(parseFloat(val))
        );
        roundedValue = roundedIRRRange.join('-') + '%';
      }
    }
  }

  return roundedValue;
};

export const isAnnualizedYield = (offering: InvestOffering) =>
  offering.annual_return_label === 'Annualized Yield' &&
  !Number.isNaN(parseFloat(offering.projected_annual_return)) &&
  parseFloat(offering.projected_annual_return) !== 0;

export const getTargetReturnValue = (offering: InvestOffering) => {
  let returnMetric: string | number = offering.rate;
  const strategies = offering.strategy?.split(',');
  const isEquity = strategies.includes('Equity');
  const isSTN = strategies.includes('Short Term Note');

  if (isAnnualizedYield(offering)) {
    returnMetric = offering.total_preferred_return;
    if (isSTN) returnMetric = offering.projected_annual_return;
  } else if (isEquity) {
    returnMetric = offering.irr;
  }

  return returnMetric ? utils.parseToNumber(returnMetric) : null;
};
