import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import IrImages from 'components/IrImages/IrImages';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitSignupForm, updateSignupValues } from 'redux/actions/onboarding';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import { SharedSignupProps } from '../types';
import { nameSchema } from '../validation';
import * as styles from './Name.module.scss';

interface FormFields {
  first_name: string;
  last_name: string;
}

const Name = ({
  defaultValues,
  dispatch,
  loadingAuth,
  user
}: SharedSignupProps) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(nameSchema),
    values: defaultValues as FormFields
  });

  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    if (!loadingAuth)
      redirectIfPreviousStepsIncomplete(navigate, defaultValues, 3, user);
  }, [loadingAuth, defaultValues, user, navigate]);

  const onSubmit = values => {
    return dispatch(
      user
        ? updateUserProfile(values)
        : submitSignupForm({
            step: 3,
            user: {
              ...values,
              email: defaultValues.email
            }
          })
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        navigate('/users/signup/accreditation');
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <SignUpWrap
      introBackgroundColor="bark"
      introContent={
        <>
          <h1>Precision Investing, Personalized Support.</h1>
          <p>
            You'll also have a team of experienced asset managers working to
            maximize your returns on each investment and full access to our
            Investor Relations team, any time, anywhere.
          </p>
          <IrImages className={styles.irImages} />
        </>
      }
      step={3}
      user={user}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Tell us your name</h3>
        <Controller
          control={control}
          name="first_name"
          render={({ field }) => (
            <Input {...setFieldProps(field, errors)} label="First Name" />
          )}
        />

        <Controller
          control={control}
          name="last_name"
          render={({ field }) => (
            <Input {...setFieldProps(field, errors)} label="Last Name" />
          )}
        />

        <FormError errors={errors} />

        <Button
          className="button-fixed-width margin-top-xx"
          loading={isSubmitting}
          type="submit"
          variant="orange"
        >
          Continue
        </Button>
      </form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const signupValues = store.auth.signupValues;
  const user = store.auth.user;
  let defaultValues = signupValues;
  if (!store.auth.loading && user) {
    defaultValues = {
      email: user.email,
      first_name: user.first_name || signupValues.first_name,
      last_name: user.last_name || signupValues.last_name
    };
  }

  return {
    defaultValues,
    loadingAuth: store.auth.loading,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Name);
