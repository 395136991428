import React from 'react';
import { Col, Row } from 'react-grid-system';

import { placeholderDonutChartData } from '../../constants';
import ChartLegend from '../ChartLegend/ChartLegend';
import DonutChart from '../DonutChart/DonutChart';

interface Props {
  heading: string;
}

const ChartLoading = ({ heading }: Props) => {
  return (
    <Row>
      <Col md={6}>
        <ChartLegend heading={heading} loading />
      </Col>
      <Col md={6}>
        <DonutChart dataPoints={placeholderDonutChartData} />
      </Col>
    </Row>
  );
};

export default ChartLoading;
