import React from 'react';
import { Col, Row } from 'react-grid-system';
import { LoadInvestmentResponse } from 'types/actions/investments';
import utils from 'utilities/utils';

import { amountInvested } from '../../../../helpers';
import * as styles from './EditingInvestmentDetails.module.scss';

interface Props {
  investment: LoadInvestmentResponse;
  selectedBalance: number;
}

const EditingInvestmentDetails = ({ investment, selectedBalance }: Props) => (
  <Row>
    <Col md={4}>
      <div className="text-label">New Investment Amount</div>
      <p className="margin-xx">{utils.formatCurrency(investment.amount)}</p>
    </Col>
    <Col md={4}>
      <div className="text-label">Previously Funded</div>
      <p>
        {utils.formatCurrency(
          Number(investment.amount) - Number(investment.unfunded_amount)
        )}
      </p>
      {investment.transactions?.contributions?.length > 0 && (
        <div className={`${styles.previousTransactions} margin-xx`}>
          <strong>Previous Transactions</strong>
          <ul>
            {investment.transactions?.contributions.map(transaction => (
              <li key={transaction.id}>
                {utils.formatCurrency(
                  amountInvested(
                    transaction,
                    investment.transactions?.origination_fees
                  )
                )}{' '}
                via {transaction.payment_method.toLowerCase()}
                {transaction.status === 'pending' && (
                  <span className={transaction.status}>
                    {' '}
                    ({transaction.status})
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {investment.transactions?.refunds?.length > 0 && (
        <div className={`${styles.previousTransactions} margin-xx`}>
          <strong>Refund Transactions</strong>
          <ul>
            {investment.transactions.refunds.map(transaction => (
              <li key={transaction.id}>
                {utils.formatCurrency(transaction.amount)} via{' '}
                {transaction.payment_method.toLowerCase()}
                {transaction.status === 'pending' && (
                  <span className={transaction.status}>
                    {' '}
                    ({transaction.status})
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Col>
    <Col md={4}>
      <div className="text-label">Remainder to Fund</div>
      <div className="margin-xx">
        {selectedBalance > 0 && (
          <div className="margin-x">
            {utils.formatCurrency(selectedBalance)}
            <br />
            <div className={styles.previousTransactions}>
              using Alpine Note Balance
            </div>
          </div>
        )}
        {utils.formatCurrency(
          parseInt(investment.unfunded_amount) - selectedBalance
        )}
      </div>
    </Col>
  </Row>
);

export default EditingInvestmentDetails;
