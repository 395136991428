import { Button } from '@equitymultiple/react-eui';
import { Component } from 'containers/Offerings/types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { User } from 'types/actions/auth';
import { Offering } from 'types/api/offering';

import RichText from '../../../../components/RichText';
import utils from '../../../../utilities/utils';
import { getComponentId } from '../../helpers';
import CashFlow from '../CashFlow/CashFlow';
import Documents from '../Documents/Documents';
import Footnotes from '../Footnotes/Footnotes';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import KeyMetrics from '../KeyMetrics/KeyMetrics';
import Map from '../Map';
import Media from '../Media/Media';
import SingleImage from '../SingleImage/SingleImage';
import SourcesAndUses from '../SourcesAndUses';
import Table from '../Table';
import * as styles from './CanvasComponent.module.scss';

interface Props {
  component: Component;
  offering: Offering;
  user: User;
}

const CanvasComponent = ({ component, offering, user }: Props) => {
  const [hiddenParentId, setHiddenParentId] = useState(null);
  const [hiddenChildrenParentIds, setHiddenChildrenParentIds] = useState([]);

  useEffect(() => {
    let newHiddenParentId = null;
    const newHiddenChildrenParentIds = [];

    // If the top level component is hidden, set its ID
    if (component.collapse) newHiddenParentId = component.id;

    // Add each hidden child's parent ID to array, so collapse can be toggled for all children
    const addHiddenChildrenParentIds = children => {
      if (children?.length > 0) {
        children.forEach(child => {
          if (
            child.collapse &&
            !newHiddenChildrenParentIds.includes(child.parent_id)
          )
            newHiddenChildrenParentIds.push(child.parent_id);
          addHiddenChildrenParentIds(child.children);
        });
      }
    };
    addHiddenChildrenParentIds(component.children);

    setHiddenParentId(newHiddenParentId);
    setHiddenChildrenParentIds(newHiddenChildrenParentIds);
  }, [component]);

  const showComponent = parentId => {
    // If it's a child, show it and all of its hidden siblings. If it's a parent, show its children
    if (!parentId) setHiddenParentId(null);
    else if (hiddenChildrenParentIds.includes(parentId))
      setHiddenChildrenParentIds(
        hiddenChildrenParentIds.filter(id => id !== parentId)
      );
  };

  const isLearnMoreButtonVisible = componentToCheck => {
    // CHeck if component is collapsed
    const isHidden =
      componentToCheck.id === hiddenParentId ||
      (componentToCheck.collapse &&
        hiddenChildrenParentIds.includes(componentToCheck.parent_id));
    // Check if the previous sibling is collapsed, so we don't render multiple 'learn more' buttons on top of each other
    let previousComponentCollapse = false;
    if (componentToCheck.parent_id) {
      const parentComponent = utils.deepObjectSearch(
        component,
        'children',
        children => children?.some(child => child.id === componentToCheck.id)
      );
      if (parentComponent) {
        const componentToCheckIndex = parentComponent.children.findIndex(
          child => child.id === componentToCheck.id
        );
        if (componentToCheckIndex !== 0)
          previousComponentCollapse =
            parentComponent.children[componentToCheckIndex - 1].collapse;
      }
    }
    const showButton = isHidden && !previousComponentCollapse;
    return showButton;
  };

  const isComponentVisible = componentToCheck => {
    return (
      !componentToCheck.collapse ||
      (componentToCheck.id !== hiddenParentId && !componentToCheck.parent_id) ||
      (componentToCheck.parent_id &&
        !hiddenChildrenParentIds.includes(componentToCheck.parent_id))
    );
  };

  const renderTitle = componentWithTitle => {
    const title = componentWithTitle.title;
    if (title) {
      // Section && Section Parallel
      let heading = <h2>{title}</h2>;
      // Nested Section && Section Parallel
      if (componentWithTitle.parent_id) heading = <h3>{title}</h3>;
      if (
        componentWithTitle.type !== 'section' &&
        componentWithTitle.type !== 'section-parallel'
      ) {
        // Non-section
        heading = <h3>{title}</h3>;
        // Nested Non-section
        if (componentWithTitle.parent_id) heading = <h4>{title}</h4>;
      }
      return heading;
    }
    return null;
  };

  const renderComponent = componentToRender => {
    let canvasComponent;
    switch (componentToRender.type) {
      case 'section':
        canvasComponent =
          componentToRender.children.length > 0 &&
          componentToRender.children.map(childComponent =>
            renderComponent(childComponent)
          );
        break;

      case 'section-parallel':
        canvasComponent = componentToRender.children.length > 0 && (
          <Row>
            <Col md={6}>
              {renderComponent(
                componentToRender.children.find(
                  childComponent => childComponent.hpos === 0
                )
              )}
            </Col>
            <Col md={6}>
              {renderComponent(
                componentToRender.children.find(
                  childComponent => childComponent.hpos === 1
                )
              )}
            </Col>
          </Row>
        );
        break;

      case 'cash-flow':
        canvasComponent = (
          <CashFlow
            data={componentToRender.data}
            minInvestmentAmount={offering.minimum_investment_amount}
          />
        );
        break;

      case 'documents':
        canvasComponent = (
          <Documents
            currentUser={user}
            data={componentToRender.data}
            offering={offering}
          />
        );
        break;

      case 'image':
        if (componentToRender.data.omcms?.images?.length > 1) {
          canvasComponent = (
            <ImageCarousel images={componentToRender.data.omcms.images} />
          );
        } else {
          canvasComponent = (
            <SingleImage
              image={
                componentToRender.data.omcms?.images
                  ? componentToRender.data.omcms.images[0]
                  : null
              }
            />
          );
        }
        break;

      case 'metrics':
        canvasComponent = (
          <KeyMetrics
            keyMetrics={
              componentToRender.data.omcms
                ? componentToRender.data.omcms.offering_data
                : null
            }
            offeringData={offering.omcms.data}
          />
        );
        break;

      case 'map':
        canvasComponent = (
          <Map
            locations={componentToRender.data.locations}
            zoom={componentToRender.data.zoom}
          />
        );
        break;

      case 'media':
        canvasComponent = <Media data={componentToRender.data} />;
        break;

      case 'sources-and-uses':
        canvasComponent = <SourcesAndUses data={componentToRender.data} />;
        break;

      case 'table':
        canvasComponent = <Table data={componentToRender.data} />;
        break;

      case 'text':
        canvasComponent = (
          <div className={styles.sectionText}>
            <RichText
              sanitize
              text={componentToRender.data.editor_data.output}
            />
          </div>
        );
        break;

      default:
        canvasComponent = null;
    }

    return (
      <div
        className={styles.component}
        id={getComponentId(componentToRender)}
        key={componentToRender.id}
      >
        {isLearnMoreButtonVisible(componentToRender) && (
          <Button
            className={styles.learnMoreLink}
            onClick={() => showComponent(componentToRender.parent_id)}
            type="button"
            variant="outlined"
          >
            Learn More
          </Button>
        )}
        {isComponentVisible(componentToRender) && (
          <div>
            {renderTitle(componentToRender)}
            {canvasComponent}
            {componentToRender.data.omcms?.footnotes?.editor_data?.output && (
              <Footnotes
                footnotes={
                  componentToRender.data.omcms.footnotes.editor_data.output
                }
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return renderComponent(component);
};

export default CanvasComponent;
