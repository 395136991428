import { FileIcon } from '@equitymultiple/react-eui';
import { saveAs } from 'file-saver';
import React from 'react';
import { User } from 'types/actions/auth';
import { Offering } from 'types/api/offering';
import { trackInvestorPacketClick } from 'utilities/trackEvents';

import * as styles from './Documents.module.scss';

interface Props {
  currentUser: User;
  data: {
    omcms: {
      archive_url: string;
      documents: {
        id: number;
        name: string;
        url: string;
      }[];
    };
  };
  offering: Offering;
}

const Documents = ({ currentUser, data, offering }: Props) => {
  const handleClick = (doc, download?: boolean) => {
    if (doc.name === 'Investor Packet')
      trackInvestorPacketClick(
        'OM documents list',
        offering.title,
        currentUser
      );

    const fileType = doc.original_name.split('.').pop();
    if (fileType === 'pdf' && !download) window.open(doc.url);
    else saveAs(doc.url, doc.original_name);
  };

  return data.omcms ? (
    <div>
      {data.omcms.archive_url && (
        <a
          className={`text-link ${styles.downloadAll}`}
          href={data.omcms.archive_url}
        >
          Download All&nbsp;
          <i className="fa fa-download" />
        </a>
      )}

      <div className={styles.documentList}>
        <ul>
          {data.omcms.documents?.map(doc => (
            <li className={styles.docItem} key={doc.id}>
              <div className={styles.title}>
                <button
                  className="text-link"
                  onClick={() => handleClick(doc)}
                  type="button"
                >
                  <FileIcon className={styles.docIcon} url={doc.url} />
                  {doc.name}
                </button>
              </div>

              <div>
                <button
                  className={styles.download}
                  onClick={() => handleClick(doc, true)}
                  type="button"
                >
                  <i className="fa fa-download" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default Documents;
