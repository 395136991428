import { NavComponent } from 'containers/Offerings/types';
import React, { useEffect, useState } from 'react';

import { getComponentId } from '../../helpers';
import InvestButton from '../InvestButton/InvestButton';
import * as styles from './SideNavigation.module.scss';

interface Props {
  mustVerifyToInvest: boolean;
  navComponents: NavComponent[];
  toggleGallery: () => void;
  trackLinkClick: (linkName: string) => void;
  type: 'top' | 'bottom';
}

const SideNavigation = ({
  mustVerifyToInvest,
  navComponents,
  toggleGallery,
  trackLinkClick,
  type
}: Props) => {
  const [activeSection, setActiveSection] = useState(null);
  const [visible, setVisible] = useState(false);

  const updateNavigation = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 600 && window.innerWidth > 1199) {
      setVisible(true);
      navComponents.forEach(navItem => {
        const winHeight = window.innerHeight;
        const section = document.getElementById(getComponentId(navItem));

        if (section) {
          const sectionTop = section.getBoundingClientRect().top + scrollTop;
          if (
            sectionTop - winHeight / 2 < scrollTop &&
            sectionTop + section.offsetHeight - winHeight / 2 > scrollTop
          )
            setActiveSection(navItem.id);
        }
      });
    } else if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (type === 'bottom') window.addEventListener('scroll', updateNavigation);

    return () => {
      if (type === 'bottom')
        window.removeEventListener('scroll', updateNavigation);
    };
  }, [navComponents, visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (label, id) => {
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        behavior: 'smooth',
        top: sectionTop - 30
      });
      trackLinkClick(label);
    }
  };

  const handlePhotoClick = () => {
    toggleGallery();
    trackLinkClick('Photos');
  };

  let navItems;

  if (navComponents?.length > 0) {
    navItems = navComponents.map(navItem => (
      <div key={navItem.id}>
        <button
          className={`${styles.navItem} ${
            activeSection === navItem.id ? styles.active : ''
          }`}
          onClick={() => {
            handleClick(navItem.title, getComponentId(navItem));
          }}
          type="button"
        >
          <span className={styles.navLabel}>{navItem.title}</span>
        </button>
      </div>
    ));
  }

  const photoLink = (
    <div>
      <button
        className={styles.navItem}
        onClick={handlePhotoClick}
        type="button"
      >
        <span className={styles.navLabel}>Photos</span>
      </button>
    </div>
  );

  return (
    <>
      {type === 'top' ? (
        <div
          className={`${styles.sideNav} ${styles.top}`}
          data-testid="sideNavigationTop"
        >
          {navItems}
          {photoLink}
        </div>
      ) : (
        <div className="stickySidebarWrap">
          <div
            className={`${styles.sideNav} ${styles.bottom} ${
              visible ? '' : styles.invisible
            }`}
            data-testid="sideNavigation"
          >
            {navItems}
            {photoLink}
            <InvestButton
              className={styles.investButton}
              mustVerifyToInvest={mustVerifyToInvest}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SideNavigation;
