import Collapse from '@mui/material/Collapse';
import { ChartDataPoint } from 'containers/Portfolio/types';
import React, { useState } from 'react';

import utils from '../../../../utilities/utils';
import * as styles from './LegendDataPoint.module.scss';

interface Props {
  dataPoint: ChartDataPoint;
  showNullState?: boolean;
}

const LegendDataPoint = ({ dataPoint, showNullState }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.legendDataPoint}>
      <div className={styles.dataPoint} key={dataPoint.name}>
        <div className={styles.description}>
          <div
            className={styles.swatch}
            style={{ background: dataPoint.fill }}
          />
          <div className={styles.name}>{dataPoint.name}</div>
        </div>
        <div className={styles.value}>
          <span className={styles.percentage}>{dataPoint.percentage}%</span>
        </div>
        {!showNullState && (
          <button
            aria-label="expand row"
            className={styles.toggleRowButton}
            onClick={() => setOpen(!open)}
            title="expand or collapse details"
            type="button"
          >
            <div className={styles.iconCircle}>
              <i className={`fa fa-chevron-right ${open ? styles.down : ''}`} />
            </div>
          </button>
        )}
      </div>
      {!showNullState && (
        <Collapse className={styles.collapse} in={open} unmountOnExit>
          <div className={styles.collapseItem}>
            <span className={styles.collapseName}>Amount:</span>
            <span className={styles.value}>
              {utils.formatCurrency(dataPoint.value, 0)}
            </span>
          </div>
          <div className={styles.collapseItem}>
            <span className={styles.collapseName}>Investments:</span>
            <span className={styles.value}>{dataPoint.count}</span>
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default LegendDataPoint;
