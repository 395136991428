import { Button, RadioButton, Select } from '@equitymultiple/react-eui';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit
} from 'react-hook-form';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import { dateRangeOptions } from '../../constants';
import { FormFields } from '../PaymentsTable/types';
import * as styles from './PaymentsTableFilters.module.scss';

const getDateTypeLabel = dateType =>
  dateType === 'date_posted' ? 'Transfer Date' : 'Effective Date';

const getFilterLabels = (activeFilters, investmentOptions) => {
  const { dateRange, dateType, investmentId } = activeFilters;

  const filterLabels = [];

  if (activeFilters) {
    if (investmentOptions && investmentId !== 'all') {
      const selectedInvestment = investmentOptions.find(
        option => option.value === investmentId
      );
      if (selectedInvestment) filterLabels.push(selectedInvestment.label);
    }

    if (dateRange && dateRange !== 'all') {
      filterLabels.push(getDateTypeLabel(dateType));
      const selectedDateOption = dateRangeOptions.find(
        option => option.value === dateRange
      );
      if (selectedDateOption) filterLabels.push(selectedDateOption.label);
    }
  }

  return filterLabels.length > 0 ? filterLabels : null;
};

interface Props {
  activeFilters: {
    dateRange: string;
    investmentId: number;
  };
  clearFilter: () => void;
  control: Control<FormFields>;
  errors: FieldErrors<FormFields>;
  filterFieldsOpen: boolean;
  formValues: FormFields;
  handleSubmit: UseFormHandleSubmit<object, unknown>;
  investmentOptions: {
    label: string;
    value: string;
  }[];
  onSubmit: () => void;
  toggleFilterFields: () => void;
}

const PaymentsTableFilters = ({
  activeFilters,
  clearFilter,
  control,
  errors,
  filterFieldsOpen,
  formValues,
  handleSubmit,
  investmentOptions,
  onSubmit,
  toggleFilterFields
}: Props) => {
  const filterLabels = getFilterLabels(activeFilters, investmentOptions);

  return (
    <>
      <Collapse in={filterFieldsOpen} timeout="auto" unmountOnExit>
        <p>Filter by payment details:</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {investmentOptions && (
            <>
              <Controller
                control={control}
                name="investmentId"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    className="input-fixed-width"
                    label="Investment"
                    options={investmentOptions}
                  />
                )}
              />

              <p>and/or:</p>
            </>
          )}

          <Controller
            control={control}
            name="dateType"
            render={({ field }) => (
              <>
                <div className="margin-x">
                  <RadioButton
                    {...setRadioFieldProps(field, errors, 'date_posted')}
                    hideError
                    label={
                      <>
                        Transfer Date
                        <div className="subtext">
                          Filter transactions by the date they were transferred.
                          Recommended for seeing activity on a cash basis.
                        </div>
                      </>
                    }
                  />
                </div>
                <div className="margin-xx">
                  <RadioButton
                    {...setRadioFieldProps(field, errors, 'effective_date')}
                    label={
                      <>
                        Effective Date
                        <div className="subtext">
                          Filter transactions by the date they are effective.
                          Recommended for performance calculations.
                        </div>
                      </>
                    }
                  />
                </div>
              </>
            )}
          />

          <Controller
            control={control}
            name="dateRange"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                className="input-fixed-width"
                label={getDateTypeLabel(formValues.dateType)}
                options={dateRangeOptions}
              />
            )}
          />

          <div className="button-wrap margin-top-xx margin-xx">
            <Button
              className={styles.filterButton}
              onClick={toggleFilterFields}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              className={styles.filterButton}
              title="Filter Payment Activity"
              type="submit"
              variant="outlined"
            >
              Filter
            </Button>
          </div>
        </form>
      </Collapse>
      {filterLabels && (
        <div className={styles.filterActive}>
          <div>
            <div className={styles.filteredBy}>Filtered by:</div>
            {filterLabels.map(label => (
              <div className={styles.filterLabel} key={label}>
                {label}
              </div>
            ))}
          </div>
          <div className={styles.actions}>
            <button
              className="text-link underline"
              onClick={toggleFilterFields}
              type="button"
            >
              Edit
            </button>
            <div className={styles.separator}>|</div>
            <button
              className="text-link underline"
              onClick={clearFilter}
              type="button"
            >
              Clear Filter
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentsTableFilters;
