import React from 'react';
import Skeleton from 'react-loading-skeleton';

import ClosingStageLabel from '../../../../components/ClosingStageLabel/ClosingStageLabel';
import { ClosingStage } from '../../../../types/api/closing';
import * as styles from './InvestmentTitle.module.scss';

interface Props {
  loading?: boolean;
  stage?: ClosingStage;
  title: string;
}

const InvestmentTitle = ({ loading, stage, title }: Props) => (
  <div className={styles.investmentTitle} data-testid="investmentTitle">
    <h2 className={`margin-top-0 margin-x ${loading ? styles.loading : ''}`}>
      {loading ? <Skeleton width="70%" /> : title}
    </h2>
    {!loading && stage && <ClosingStageLabel stage={stage} />}
  </div>
);

export default InvestmentTitle;
