import { Card, EMLoadingIcon, ErrorLabel } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  removeBeneficialOwner
} from 'redux/actions/account';
import {
  AnyInvestmentAccountType,
  EntityInvestmentAccount,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import humane from 'utilities/humane';

import { accountFlag, accountStatus } from '../../contents';
import {
  cannotLinkNewBankAccountReason,
  formatDate,
  isComplete,
  sortBeneficialOwners,
  supportsBeneficialOwners
} from '../../helpers';
import PlaidLink from '../PlaidLink/PlaidLink';
import * as styles from './InvestmentAccount.module.scss';

type EntityAccountType = LoadInvestmentAccountAccount<EntityInvestmentAccount>;

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<AnyInvestmentAccountType>;
  loading: boolean;
}

const InvestmentAccount = ({ dispatch, investmentAccount, loading }: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'My Accounts | Edit Account';

    dispatch(loadInvestmentAccount(params.reference_id))
      .then(account => {
        const investmentAccountRes = account.investment_account;
        if (
          investmentAccountRes.type === 'ira' &&
          investmentAccountRes.status !== accountStatus.created &&
          investmentAccountRes.entity_name !== 'Other'
        )
          navigate(`accounts/wizard/ira/${investmentAccountRes.reference_id}`);
      })
      .catch(err => handleErrorResponse(navigate, err));
  }, [dispatch, params, navigate]);

  const removeBeneficiary = id => {
    dispatch(removeBeneficialOwner(id))
      .then(res => {
        humane.notice(res.message);
        dispatch(loadInvestmentAccount(params.reference_id));
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const dispatchLoadInvestmentAccount = async () => {
    dispatch(loadInvestmentAccount(params.reference_id));
  };

  const handlePlaidLinkSuccess = () => {
    dispatchLoadInvestmentAccount();
  };

  const cannotLinkBankAccountReason =
    cannotLinkNewBankAccountReason(investmentAccount);

  const PlaidLinkLaunchButton = (
    <button
      className={`text-link ${styles.newBank}`}
      onClick={() => setError(null)}
      type="button"
    >
      Link New Bank Account
    </button>
  );

  const linkNewAccountButton = cannotLinkBankAccountReason ? (
    <button
      className={`text-link ${styles.newBank}`}
      onClick={() => humane.error(cannotLinkBankAccountReason)}
      type="button"
    >
      Link New Bank Account
    </button>
  ) : (
    <PlaidLink
      LaunchButton={PlaidLinkLaunchButton}
      onError={err => setError(err)}
      onSuccess={handlePlaidLinkSuccess}
    />
  );

  const newBeneficialAllowed =
    !(investmentAccount as EntityAccountType)?.beneficial_owners ||
    (investmentAccount as EntityAccountType).beneficial_owners.length < 4;
  const sortedBeneficialOwners = sortBeneficialOwners(
    (investmentAccount as EntityAccountType)?.beneficial_owners
  );

  return loading || !investmentAccount ? (
    <EMLoadingIcon />
  ) : (
    <Container className={`container-narrow ${styles.investmentAccount}`}>
      <h2 className="margin-top-0">{investmentAccount.title}</h2>
      <Card>
        {investmentAccount.needs_update && (
          <Row>
            <Col md={12}>
              {investmentAccount.type === 'joint account' &&
                isComplete(investmentAccount) && (
                  <ErrorLabel
                    className="margin-xx"
                    message="The Power of Attorney letter has not been counter-signed. To be able to invest with this account,
                      please ensure that this letter is signed by all parties."
                  />
                )}
              {investmentAccount.type === 'entity' &&
                isComplete(investmentAccount) && (
                  <ErrorLabel
                    className="margin-xx"
                    message={accountFlag('entity')}
                  />
                )}
            </Col>
          </Row>
        )}
        {isComplete(investmentAccount) && (
          <Row>
            <Col md={12}>
              {investmentAccount.dwolla_customer_status === 'document' && (
                <ErrorLabel
                  className="margin-xx"
                  message="We're currently reviewing documents that you have submitted."
                />
              )}
              {investmentAccount.dwolla_customer_status === 'suspended' && (
                <ErrorLabel
                  className="margin-xx"
                  message="There is an issue with your account. Please contact our support team at your earliest convenience."
                />
              )}
            </Col>
          </Row>
        )}
        {(investmentAccount.type !== 'ira' ||
          (investmentAccount.type === 'ira' &&
            investmentAccount.status === accountStatus.created)) && (
          <div>
            <h4>Information</h4>
            <Row>
              <Col md={4}>
                <div className="text-label">Entity Name</div>
                <p>{investmentAccount.entity_name}</p>
              </Col>
              <Col md={4}>
                <div className="text-label">Account Status</div>
                <p
                  className={
                    investmentAccount.status === accountStatus.created
                      ? 'text-green'
                      : 'text-yellow'
                  }
                >
                  {investmentAccount.status}
                </p>
              </Col>
              <Col md={4}>
                <div className="text-label">Account Type</div>
                <p>
                  {investmentAccount.type === 'ira'
                    ? 'IRA'
                    : investmentAccount.type.charAt(0).toUpperCase() +
                      investmentAccount.type.slice(1)}
                </p>
              </Col>
            </Row>
            {investmentAccount.type === 'ira' && (
              <Row>
                <Col md={4}>
                  <div className="text-label">IRA Type</div>
                  <p>{investmentAccount.business_type}</p>
                </Col>
                <Col md={4}>
                  <div className="text-label">Account Number</div>
                  <p>{investmentAccount.ira_account_number}</p>
                </Col>
              </Row>
            )}
          </div>
        )}

        {investmentAccount.type !== 'ira' && (
          <div className={styles.editLinks}>
            <p>
              We are required to maintain up-to-date and accurate records of all
              individuals and entities investing through our platform. To update
              account details please use link(s) below.
            </p>
            <Row>
              <Col lg={4}>
                <Link className="text-link" to="/settings/profile">
                  Update Profile
                </Link>
              </Col>
              {investmentAccount.type === 'entity' && (
                <Col lg={4}>
                  <Link
                    className="text-link"
                    to={`/accounts/update/entity/information/${investmentAccount.reference_id}`}
                  >
                    Update Entity Information
                  </Link>
                </Col>
              )}
              {investmentAccount.type === 'joint account' && (
                <Col lg={4}>
                  <Link
                    className="text-link"
                    to={`/accounts/wizard/joint/secondary_account/${investmentAccount.reference_id}`}
                  >
                    Update Joint Account Information
                  </Link>
                </Col>
              )}
              <Col lg={4}>
                <Link className="text-link" to="/settings/accreditation">
                  View Accreditation
                </Link>
              </Col>
            </Row>
            <hr />
          </div>
        )}
        {investmentAccount.type === 'entity' &&
          supportsBeneficialOwners(investmentAccount) && (
            <div>
              <h4>Beneficial Owners</h4>
              {sortBeneficialOwners.length ? (
                <div>
                  {sortedBeneficialOwners.map((representative, index) => (
                    <div key={representative.id}>
                      <Row>
                        <Col md={4}>
                          <div className="text-label">Name</div>
                          <p>
                            {representative.first_name +
                              ' ' +
                              representative.last_name}
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-label">Email</div>
                          <p>{representative.email}</p>
                        </Col>
                        <Col md={4}>
                          <div className="text-label">Phone Number</div>
                          <p>{representative.phone}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div className="text-label">SSN</div>
                          <p>{representative.masked_ssn}</p>
                        </Col>
                        <Col md={4}>
                          <div className="text-label">Date of Birth</div>
                          <p>{formatDate(representative.dob)}</p>
                        </Col>
                        <Col md={4}>
                          <Link
                            className={`text-link ${styles.beneficialOwnerEdit}`}
                            to={`/accounts/update/${investmentAccount.account_type}/rep/${representative.id}/${investmentAccount.reference_id}`}
                          >
                            Edit
                          </Link>
                          {index !== 0 && (
                            <button
                              className={`text-link danger ${styles.beneficialOwnerDelete}`}
                              onClick={() =>
                                removeBeneficiary(representative.id)
                              }
                              type="button"
                            >
                              Remove
                            </button>
                          )}
                        </Col>
                      </Row>
                      {index !==
                        (investmentAccount as EntityAccountType)
                          .beneficial_owners.length -
                          1 && <hr />}
                    </div>
                  ))}
                </div>
              ) : (
                <p>
                  In order to comply with Know Your Customer (KYC) and
                  Anti-Money Laundering (AML) rules and policies, we are
                  required to collect additional information on every beneficial
                  owner who owns 25% or more of your entity.
                </p>
              )}
              {newBeneficialAllowed && (
                <div>
                  <Link
                    className="text-link"
                    to={`/accounts/update/${investmentAccount.account_type}/rep/new/${investmentAccount.reference_id}`}
                  >
                    Add New Owner
                  </Link>
                </div>
              )}
              <hr />
            </div>
          )}
        {investmentAccount.type !== 'ira' && (
          <div>
            <h4>Bank Accounts</h4>
            {error && <ErrorLabel className="margin-xx" message={error} />}
            {investmentAccount.bank_accounts?.length &&
            investmentAccount.bank_accounts.filter(
              bankAccount => bankAccount.primary
            ).length ? (
              <div className={styles.bankAccount}>
                {investmentAccount.bank_accounts
                  .filter(bankAccount => bankAccount.primary)
                  .map(bankAccount => (
                    <div key={bankAccount.id}>
                      <Row>
                        <Col md={4}>
                          <div className="text-label">Name</div>
                          <p>{bankAccount.name}</p>
                        </Col>
                        <Col md={4}>
                          <div className="text-label">Status</div>
                          <p>
                            <span
                              className={
                                bankAccount.status === 'verified'
                                  ? 'text-green'
                                  : 'text-yellow'
                              }
                            >
                              {bankAccount.status}
                            </span>
                            <span>{bankAccount.primary && ' (Primary)'}</span>
                          </p>
                        </Col>
                        <Col md={4}>
                          <div className="text-label">Account Type</div>
                          <p>{bankAccount.type}</p>
                        </Col>
                      </Row>
                      {bankAccount.iav ? (
                        <Row>
                          <Col md={4}>
                            <div className="text-label">Bank Name</div>
                            <p>{bankAccount.bank_name}</p>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md={4}>
                            <div className="text-label">Account Number</div>
                            <p>{bankAccount.account_number}</p>
                          </Col>
                          <Col md={4}>
                            <div className="text-label">Routing Number</div>
                            <p>{bankAccount.routing_number}</p>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}
              </div>
            ) : (
              <p>No verified bank account added yet.</p>
            )}

            <div className="clearfix-after">
              {investmentAccount.bank_accounts?.length > 0 && (
                <Link
                  className="text-link"
                  to={`/accounts/update/${investmentAccount.account_type}/linkedbanks/${investmentAccount.reference_id}`}
                >
                  View All Bank Accounts
                </Link>
              )}
              {linkNewAccountButton}
            </div>
            <hr />
            <h4>Documents</h4>
            <div>
              <Link
                className="text-link"
                to={`/accounts/update/${investmentAccount.account_type}/documents/${investmentAccount.reference_id}`}
              >
                View All Documents
              </Link>
            </div>
          </div>
        )}
      </Card>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(InvestmentAccount);
