import { formatDate } from 'containers/Accounts/helpers';
import utils from 'utilities/utils';

export const getUserAttributes = user => {
  return {
    ...(({
      address,
      country_code,
      email,
      first_name,
      id_document,
      investor_profile,
      last_name,
      middle_name,
      phone,
      preferred_name
    }) => ({
      address_attributes: {
        address: address?.address,
        address2: address?.address2,
        city: address?.city,
        postal_code: address?.postal_code,
        state: address?.state
      },
      country_code,
      email,
      first_name,
      id_document: {
        attachment: id_document?.name && {
          name: id_document?.name,
          url: id_document?.url
        },
        expiry: formatDate(id_document?.expiry),
        passport: investor_profile?.passport,
        type: id_document?.type
      },
      investor_profile_attributes: {
        accreditation: investor_profile?.accreditation,
        country_of_citizenship: investor_profile?.country_of_citizenship,
        dob: formatDate(investor_profile?.dob),
        investment_experience: investor_profile?.investment_experience,
        investment_objectives: investor_profile?.investment_objectives,
        investment_reason: investor_profile?.investment_reason,
        occupation: investor_profile?.occupation,
        passport: investor_profile?.passport,
        residence_status: investor_profile?.residence_status,
        risk_tolerance: investor_profile?.risk_tolerance,
        ssn: investor_profile?.ssn
      },
      last_name,
      middle_name,
      phone,
      preferred_name
    }))(user)
  };
};

export const profileFormValues = (obj: object, keys: string[]) => {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (keys.includes(key)) {
          return [key, value];
        } else if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        ) {
          const nestedSelected = profileFormValues(
            value,
            utils.getNestedKeys(key, keys)
          );
          if (Object.keys(nestedSelected).length > 0) {
            return [key, nestedSelected];
          }
        }
        return undefined;
      })
      .filter(Boolean)
  );
};
