import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import tinycolor from 'tinycolor2';

import {
  baseColors,
  offeringTypePlaceholderDataPoints,
  placeholderDonutChartData,
  propertyTypePlaceholderDataPoints
} from '../../constants';
import ChartLegend from '../ChartLegend/ChartLegend';
import DonutChart from '../DonutChart/DonutChart';

interface Props {
  dataPointCounts: Record<
    string,
    {
      balance: number;
      count: number;
    }
  >;
  heading: string;
  showNullState: boolean;
  totalBalance: number;
}

const DiversificationChart = ({
  dataPointCounts,
  heading,
  showNullState,
  totalBalance
}: Props) => {
  const [dataPoints, setDataPoints] = useState(null);

  const setPropertyChartData = () => {
    const propertyTypeCounts = dataPointCounts;
    let newDataPoints = null;

    const propertyCountLength = Object.keys(propertyTypeCounts).length;

    const propertyCountsArray = Object.keys(propertyTypeCounts).map(
      propertyType => ({
        count: propertyTypeCounts[propertyType].count,
        name: propertyType,
        percentage: (
          (propertyTypeCounts[propertyType].balance / totalBalance) *
          100
        ).toFixed(2),
        value: propertyTypeCounts[propertyType].balance
      })
    );
    propertyCountsArray.sort((a, b) => b.value - a.value);

    if (propertyCountLength < 5) {
      // We have enough base colors for the number of property types
      newDataPoints = propertyCountsArray.map((propertyType, index) => ({
        ...propertyType,
        fill: baseColors[index]
      }));
    } else {
      // We don't have enough base colors and need to create shades from the base colors
      let dataPointCount = propertyCountLength;
      let baseColorCount = 4;
      const shadesCounts = [];

      while (dataPointCount > 0 && baseColorCount > 0) {
        const shadeCount = Math.ceil(dataPointCount / baseColorCount);
        dataPointCount -= shadeCount;
        baseColorCount--;
        shadesCounts.push(shadeCount);
      }

      const colors = [];

      // Create the new shades
      shadesCounts.forEach((shadeCount, index) => {
        const baseColor = baseColors[index];
        colors.push(baseColor);
        const newShades = shadeCount;
        for (let i = 1; i < newShades; i++) {
          let shadeOpacityDecrease = 15;
          if (propertyCountLength > 10) shadeOpacityDecrease = 7;
          else if (propertyCountLength > 5) shadeOpacityDecrease = 10;

          const lightenPercentage = i * shadeOpacityDecrease;

          const lighterShade = tinycolor(baseColor)
            .lighten(lightenPercentage)
            .toString();

          colors.push(lighterShade);
        }
      });

      // Assign a shade to each data point
      newDataPoints = propertyCountsArray.map((propertyType, index) => ({
        ...propertyType,
        fill: colors[index]
      }));
    }

    setDataPoints(newDataPoints);
  };

  const setOfferingChartData = () => {
    const offeringTypeCounts = dataPointCounts;

    const offeringTypeCountsArray = Object.keys(offeringTypeCounts).map(
      offeringType => ({
        count: offeringTypeCounts[offeringType].count,
        fill: null,
        name: offeringType,
        percentage: (
          (offeringTypeCounts[offeringType].balance / totalBalance) *
          100
        ).toFixed(2),
        value: offeringTypeCounts[offeringType].balance
      })
    );
    offeringTypeCountsArray.sort((a, b) => b.value - a.value);

    offeringTypeCountsArray.forEach((offeringType, index) => {
      offeringType.fill = baseColors[index];
    });

    setDataPoints(offeringTypeCountsArray);
  };

  const setChartData = () => {
    if (showNullState) {
      const newDataPoints =
        heading === 'Property Types'
          ? propertyTypePlaceholderDataPoints
          : offeringTypePlaceholderDataPoints;
      setDataPoints(newDataPoints);
    } else if (heading === 'Property Types') {
      setPropertyChartData();
    } else {
      setOfferingChartData();
    }
  };

  useEffect(() => {
    if (dataPointCounts || showNullState) setChartData();
  }, [dataPointCounts, showNullState]); // eslint-disable-line react-hooks/exhaustive-deps

  const donutChartDataPoints = showNullState
    ? placeholderDonutChartData
    : dataPoints;

  return (
    <Row>
      <Col md={6}>
        <ChartLegend
          dataPoints={dataPoints}
          heading={heading}
          showNullState={showNullState}
        />
      </Col>
      <Col md={6}>
        <DonutChart dataPoints={donutChartDataPoints} />
      </Col>
    </Row>
  );
};

export default DiversificationChart;
