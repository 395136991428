import { Button, Checkbox } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import PhoneFields from 'components/forms/PhoneFields/PhoneFields';
import { PhoneFormValues } from 'containers/SignUp/types';
import Back from 'images/icons/arrow-back.svg';
import InfoIcon from 'images/icons/info.svg';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import {
  requestPhoneVerificationCode,
  updateUserProfile
} from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import { setCheckboxFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { phoneSchema } from '../../../validation';
import * as styles from './PhoneForm.module.scss';

export interface Props {
  dispatch: Dispatch;
  onSubmitSuccess: (values: PhoneFormValues) => void;
  user: User;
}

const PhoneForm = ({ dispatch, onSubmitSuccess, user }: Props) => {
  const navigate = useNavigate();
  let defaultValues = {};

  if (user) {
    defaultValues = {
      country_code: user.country_code || '1',
      enable_two_factor: user.otp_required_for_login,
      opted_to_text: user.investor_profile?.opted_to_text,
      phone: user.phone
    };
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<PhoneFormValues>({
    resolver: yupResolver(phoneSchema),
    values: defaultValues as PhoneFormValues
  });

  const onSubmit: SubmitHandler<PhoneFormValues> = async values => {
    if (values.opted_to_text || values.enable_two_factor) {
      const token = await getRecaptchaToken('requestSmsCode');

      const submitValues = {
        captcha_response: token,
        user: {
          country_code: values.country_code,
          phone: values.phone
        }
      };

      return dispatch(requestPhoneVerificationCode(submitValues))
        .then(() => {
          onSubmitSuccess(values);
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    } else {
      return dispatch(
        updateUserProfile({
          country_code: values.country_code,
          investor_profile_attributes: {
            opted_to_text: values.opted_to_text
          },
          phone: values.phone
        })
      )
        .then(async () => {
          await dispatch(loadAuth());

          navigate('/users/signup/discover');
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="margin-x">Provide your phone number</h3>
      <p className="margin-xx">
        Don't miss any updates with instant notifications that fit your
        schedule.
      </p>
      <PhoneFields<PhoneFormValues> control={control} errors={errors} />

      <div className={`${styles.disclaimer} input-fixed-width-wide`}>
        <InfoIcon />
        <p className="margin-xx size-12">
          By checking these boxes, you agree to receive text messages and/or
          phone calls sent by our offer marketing team. Agreeing to these terms
          is not a condition of purchase. EquityMultiple or its providers may
          send a max of 10 messages per month. Messages and data rates may
          apply. You may text STOP at any time to cancel. Text HELP for help.
          Read the full{' '}
          <a
            className="underline"
            href="https://www.equitymultiple.com/terms"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            className="underline"
            href="https://www.equitymultiple.com/privacy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>

      <Controller
        control={control}
        name="opted_to_text"
        render={({ field }) => (
          <Checkbox
            {...setCheckboxFieldProps(field, errors)}
            className="margin-x"
            label="I am open to receiving SMS texts regarding offerings or my portfolio"
          />
        )}
      />

      <Controller
        control={control}
        name="enable_two_factor"
        render={({ field }) => (
          <Checkbox
            {...setCheckboxFieldProps(field, errors)}
            className="margin-x"
            label="I want to improve the security of my account by turning on 2-factor authentication"
          />
        )}
      />

      <FormError errors={errors} />

      <div className="forwardBackButtonWrapCompact">
        <Button
          className="button-fixed-width margin-top-xx"
          loading={isSubmitting}
          type="submit"
          variant="orange"
        >
          Continue
        </Button>
        <Button
          className="button-fixed-width margin-top-xx arrowBackButton"
          disabled={isSubmitting}
          variant="outlined"
          wrapper={<Link to="/users/signup/continue" />}
        >
          <Back />
        </Button>
      </div>
    </form>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(PhoneForm);
