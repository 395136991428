import he from 'he';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import EmAnalytics from '../../utilities/em_analytics';
import * as styles from './ArticleCard.module.scss';

const handleArticleCardClick = (title: string, url: string) => {
  EmAnalytics.track('Clicked Article Card', 'Investing', {
    articleTitle: title,
    page: window.location.href,
    url
  });
};

interface Props {
  className?: string;
  link: string;
  loading?: boolean;
  title: string;
}

const ArticleCard = ({
  className = '',
  link,
  loading = false,
  title
}: Props) => {
  const decodedTitle = title && he.decode(title);

  return (
    <a
      className={`${styles.articleCard} ${
        loading ? styles.loading : ''
      } ${className}`}
      href={link}
      onClick={() => handleArticleCardClick(decodedTitle, link)}
      rel="noopener noreferrer"
      target="_blank"
      title={decodedTitle}
    >
      <div className={styles.tag}>Resources</div>
      <h3 className={styles.cardHeading}>
        {loading ? <Skeleton /> : decodedTitle}
      </h3>
      <div className={styles.arrow}>-&gt;</div>
    </a>
  );
};

export default ArticleCard;
