import { AnyAction } from 'redux';

import * as actions from '../actions/payments';
const initialState = {
  loading: false,
  transferCount: 0,
  transfers: null
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.GET_PAYMENTS:
      return {
        ...state,
        loading: true
      };

    case actions.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        transferCount: action.result.count,
        transfers: action.result.data
      };

    case actions.GET_PAYMENTS_FAIL:
      return {
        ...state,
        error: action.result,
        loading: false
      };

    case actions.RESET_PAYMENTS:
      return initialState;

    default:
      return state;
  }
};
