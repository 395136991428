import { Button, Card, Table } from '@equitymultiple/react-eui';
import { DashboardAccount } from 'containers/Portfolio/types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { UserStage } from 'types/actions/auth';
import utils from 'utilities/utils';

import { accountStatus } from '../../../Accounts/contents';
import { getAccountDescription } from '../../helpers';
import * as styles from './Accounts.module.scss';

interface Props {
  accounts: DashboardAccount[];
  completedAccountsCount: number;
  loading: boolean;
  userStage: UserStage;
}

const Accounts = ({
  accounts,
  completedAccountsCount,
  loading,
  userStage
}: Props) => {
  const getColumnHeaders = () => {
    return [
      'Investment Entity',
      userStage === 'invest' ? '' : 'Capital Balance',
      ''
    ];
  };

  const getRows = () => {
    if (accounts && completedAccountsCount > 0) {
      if (loading) {
        const rows = [];
        for (let i = 0; i < completedAccountsCount; i++) {
          rows.push({
            cells: [<Skeleton key="name" />, <Skeleton key="balance" />, '']
          });
        }
        return rows;
      }
      const completeAccounts = accounts.filter(
        account => account.status === accountStatus.created
      );

      return completeAccounts.map(account => {
        const hasInvestments =
          account.balance !== 0 || account.investment_count > 0;
        const secondCell = hasInvestments ? (
          utils.formatCurrency(account.balance)
        ) : (
          <Button
            className="float-right"
            style={{ width: 160 }}
            variant="orange"
            wrapper={<Link to="/" />}
          >
            Invest
          </Button>
        );
        const lastCell = hasInvestments ? (
          <i className="fa fa-chevron-right" key="icon" />
        ) : (
          ''
        );

        return {
          cells: [
            <>
              <span className={styles.accountName}>{account.entity_name}</span>
              <div className={styles.accountDetails}>
                {getAccountDescription(account)}
              </div>
            </>,
            secondCell,
            lastCell
          ],
          className: hasInvestments ? styles.rowLink : '',
          link: hasInvestments ? `/portfolio/account/${account.id}` : null
        };
      });
    }
    return [];
  };

  return (
    <Card className={styles.accountsCard}>
      <div className={styles.top}>
        <h3>My Accounts</h3>
        <Link title="My Accounts" to="/accounts">
          <i className="fa fa-gear" />
        </Link>
      </div>
      <Table
        className={styles.accountsTable}
        columnHeaders={getColumnHeaders()}
        rows={getRows()}
      />
      <div className={styles.bottom}>
        <Link
          className={`${styles.newAccount} text-link underline`}
          to="/accounts/new"
        >
          <i className="fa fa-plus-circle" />
          Open a new account
        </Link>
      </div>
    </Card>
  );
};

export default Accounts;
