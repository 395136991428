import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from '../NotFound/NotFound';
import {
  AccountDocuments,
  Accounts,
  AccountStatus,
  BankAccounts,
  BeneficialEdit,
  BeneficialNew,
  BeneficialOwners,
  EntityAccountUpdate,
  InvestmentAccount,
  JointSecondaryHolderIdentification,
  JointSecondaryHolderUpdate,
  NewAccount,
  NewEntityAccount,
  NewIraAccount,
  NewJointSecondaryHolder,
  OrganizationalDocument,
  PlaidLinkOAuth,
  PoaDocumentUpdate,
  TaxDocument,
  W9DocumentUpdate
} from '.';

const accountRoutes = (
  <Routes>
    <Route element={<NotFound />} path="*" />
    <Route element={<Accounts />} path="" />
    <Route element={<NewAccount />} path="/new" />
    <Route element={<NewAccount />} path="/:type/affordances" />
    {/* Routes for creating new Investment Account */}
    <Route element={<NewIraAccount />} path="/wizard/ira" />
    <Route element={<NewAccount />} path="/wizard/:account_type/new" />
    <Route
      element={<InvestmentAccount />}
      path="/wizard/:account_type/:reference_id"
    />
    <Route
      element={<NewEntityAccount />}
      path="/wizard/entity/information/new"
    />
    <Route
      element={<EntityAccountUpdate />}
      path="/wizard/entity/information/:reference_id"
    />
    <Route
      element={<NewJointSecondaryHolder />}
      path="/wizard/joint/secondary_account/new"
    />
    <Route
      element={<JointSecondaryHolderUpdate />}
      path="/wizard/joint/secondary_account/:reference_id"
    />
    <Route
      element={<JointSecondaryHolderIdentification />}
      path="/update/joint/secondary_account/identification_document/:reference_id"
    />
    <Route
      element={<JointSecondaryHolderIdentification />}
      path="/wizard/joint/secondary_account/identification_document/:reference_id"
    />
    <Route
      element={<OrganizationalDocument />}
      path="/wizard/:account_type/organizational_document/:reference_id"
    />
    <Route
      element={<TaxDocument />}
      path="/wizard/:account_type/tax_document/:reference_id"
    />
    <Route
      element={<W9DocumentUpdate />}
      path="/wizard/:account_type/w9/:reference_id"
    />
    <Route
      element={<PoaDocumentUpdate />}
      path="/wizard/:account_type/poa/:reference_id"
    />
    <Route
      element={<AccountStatus />}
      path="/wizard/:account_type/thanks/:reference_id"
    />
    <Route
      element={<BankAccounts />}
      path="/wizard/:account_type/link_bank/:reference_id"
    />
    <Route
      element={<BeneficialOwners />}
      path="/wizard/:account_type/beneficial_owners/:reference_id"
    />
    <Route
      element={<BeneficialNew />}
      path="/wizard/:account_type/beneficial_owners/new/:reference_id"
    />
    <Route
      element={<BeneficialEdit />}
      path="/wizard/:account_type/beneficial_owners/:id/:reference_id"
    />
    <Route
      element={<InvestmentAccount />}
      path="/update/:account_type/:reference_id"
    />
    <Route
      element={<EntityAccountUpdate />}
      path="/update/entity/information/:reference_id"
    />
    <Route
      element={<BeneficialNew />}
      path="/update/entity/rep/new/:reference_id"
    />
    <Route
      element={<BeneficialEdit />}
      path="/update/entity/rep/:id/:reference_id"
    />
    <Route
      element={<BankAccounts />}
      path="/update/:account_type/linkedbanks/:reference_id"
    />
    <Route
      element={<AccountDocuments />}
      path="/update/:account_type/documents/:reference_id"
    />
    <Route
      element={<OrganizationalDocument />}
      path="/update/:account_type/documents/organizational_document/:reference_id"
    />
    <Route
      element={<TaxDocument />}
      path="/update/:account_type/documents/tax_document/:reference_id"
    />
    <Route
      element={<PoaDocumentUpdate />}
      path="/update/:account_type/documents/poa/:reference_id"
    />
    <Route
      element={<PoaDocumentUpdate />}
      path="/update/:account_type/documents/poa/update/:reference_id"
    />
    <Route
      element={<W9DocumentUpdate />}
      path="/update/:account_type/documents/w9/:reference_id"
    />
    <Route
      element={<W9DocumentUpdate />}
      path="/update/:account_type/documents/w9/update/:reference_id"
    />
    <Route element={<PlaidLinkOAuth />} path="/bank-linking/oauth" />
  </Routes>
);

export default accountRoutes;
