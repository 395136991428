import { Button, Table } from '@equitymultiple/react-eui';
import { Transfer } from 'containers/Portfolio/types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Dispatch } from 'types/redux';

import { getPayments, resetPayments } from '../../../../redux/actions/payments';
import EmAnalytics from '../../../../utilities/em_analytics';
import utils from '../../../../utilities/utils';
import PaymentsTableFilters from '../PaymentsTableFilters/PaymentsTableFilters';
import * as sharedPaymentStyles from '../PaymentsTableShared.module.scss';
import TransferDetails from '../TransferDetails/TransferDetails';
import * as styles from './PaymentsTable.module.scss';
import { FormFields } from './types';

interface Props {
  accountIds?: number[];
  dispatch: Dispatch;
  investmentId?: number | string;
  investmentOptions?: {
    label: string;
    value: string;
  }[];
  loading: boolean;
  transferCount: number;
  transfers: Transfer[];
}

interface GetPaymentsParams {
  account_ids?: number[];
  date_type?: string;
  end_date?: string;
  investment_ids: string[];
  page: number;
  page_size: number;
  start_date?: string;
}

const activeFiltersInitialState = {
  dateRange: null,
  investmentId: null
};

const PaymentsTable = ({
  accountIds,
  dispatch,
  investmentId,
  investmentOptions,
  loading,
  transferCount,
  transfers
}: Props) => {
  const [activeFilters, setActiveFilters] = useState(activeFiltersInitialState);
  const [filterFieldsOpen, setFilterFieldsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const defaultValues = {
    dateRange: 'all',
    dateType: 'date_posted' as FormFields['dateType'],
    investmentId: 'all'
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormFields>({
    values: defaultValues
  });

  const formValues = watch();

  const handleGetPayments = async (options = { getAllForExport: false }) => {
    const selectedInvestmentId =
      !formValues ||
      !formValues.investmentId ||
      formValues.investmentId === 'all'
        ? null
        : formValues.investmentId;
    let pageSize = rowsPerPage;
    if (options.getAllForExport || rowsPerPage === -1) pageSize = transferCount;

    const params: GetPaymentsParams = {
      investment_ids: [],
      page: options.getAllForExport ? 1 : page + 1,
      page_size: pageSize
    };
    if (selectedInvestmentId)
      params.investment_ids = [selectedInvestmentId.toString()];
    if (investmentId) params.investment_ids = [investmentId.toString()];
    if (accountIds) params.account_ids = accountIds;
    if (formValues.dateType) params.date_type = formValues.dateType;
    if (formValues.dateRange && formValues.dateRange !== 'all') {
      const dateRange = JSON.parse(formValues.dateRange);
      params.start_date = dateRange.startDate;
      params.end_date = dateRange.endDate;
    }

    return dispatch(getPayments(params));
  };

  useEffect(() => {
    handleGetPayments();

    return () => {
      dispatch(resetPayments());
    };
  }, [activeFilters, page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormattedDateRange = dateRange => {
    const allTime = !dateRange || dateRange === 'all';
    let startDate;
    let endDate;

    if (allTime) {
      const firstTransferDate = transfers[transfers.length - 1].date_posted;
      startDate = moment(firstTransferDate, 'M/D/YYYY').format('MM-DD-YYYY');
      endDate = moment().format('MM-DD-YYYY');
    } else {
      startDate = moment(dateRange.startDate).format('MM-DD-YYYY');
      endDate = moment(dateRange.endDate).format('MM-DD-YYYY');
    }

    return `${startDate}-to-${endDate}`;
  };

  const getPaymentClassName = amount => {
    return amount > 0 ? 'positiveCashflow' : 'negativeCashflow';
  };

  const buildRows = () => {
    return transfers.length
      ? transfers.map(transfer => ({
          cells: [
            transfer.date_posted || '-',
            transfer.offering?.title || '-',
            utils.capitalize(transfer.status),
            transfer.event_type,
            <div
              className={
                sharedPaymentStyles[getPaymentClassName(transfer.amount)]
              }
              key="amount"
            >
              {utils.formatCurrency(transfer.amount, 2, true)}
            </div>
          ],
          collapsibleCell: <TransferDetails transfer={transfer} />
        }))
      : [];
  };

  const buildCsvRows = () => {
    const transactions = [];

    transfers?.forEach(transfer => {
      transfer.transactions.forEach(transaction =>
        transactions.push([
          transfer.effective_date || '-',
          transfer.date_posted || '-',
          transfer.offering?.title || '-',
          transaction.type,
          transfer.payment_method,
          utils.capitalize(transfer.status),
          utils.formatCurrency(transaction.amount)
        ])
      );
    });

    return transactions;
  };

  const getCsvData = async () => {
    // If there are more transfers than the table is displaying then fetch them first
    if (rowsPerPage !== -1 && transferCount > rowsPerPage)
      return handleGetPayments({ getAllForExport: true }).then(() =>
        buildCsvRows()
      );
    return buildCsvRows();
  };

  const exportRows = () => {
    let dateRange;
    try {
      dateRange = JSON.parse(formValues.dateRange);
    } catch {
      dateRange = formValues.dateRange;
    }

    const singleInvestment = formValues.investmentId !== 'all' || investmentId;

    EmAnalytics.track('Export Payment Activity', {
      offering: singleInvestment ? transfers[0].offering?.title : 'All',
      timePeriod: dateRange
    });

    getCsvData().then(csvData => {
      const columnHeadings =
        'Effective Date,Transfer Date,Investment,Transaction Type,Payment Method,Status,Amount';
      let fileName = 'payment-activity';

      if (singleInvestment)
        fileName = `${utils.kebabCase(
          transfers[0].offering?.title
        )}-${fileName}`;
      fileName = `${fileName}-(${getFormattedDateRange(dateRange)})`;

      utils.generateAndDownloadCsv(csvData, columnHeadings, fileName);

      handleGetPayments();
    });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value);
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleFilterSubmit = () => {
    const {
      dateRange,
      dateType,
      investmentId: currentInvestmentId
    } = formValues;
    const newActiveFilters = {
      dateRange: null,
      dateType: null,
      investmentId: null
    };
    if (currentInvestmentId !== 'all')
      newActiveFilters.investmentId = currentInvestmentId;
    if (dateRange !== 'all') {
      newActiveFilters.dateRange = dateRange;
      newActiveFilters.dateType = dateType;
    }

    setActiveFilters(newActiveFilters);
    setFilterFieldsOpen(false);
    setPage(0);
  };

  const getPaginationOptions = () => {
    return {
      count: transferCount,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
      page,
      rowsPerPage,
      rowsPerPageOptions: [10, 25, 100, { label: 'All', value: -1 }]
    };
  };

  const toggleFilterFields = () => {
    setFilterFieldsOpen(!filterFieldsOpen);
  };

  const clearFilter = () => {
    setValue('dateRange', 'all');
    setValue('dateType', 'date_posted');
    setValue('investmentId', 'all');

    setActiveFilters(activeFiltersInitialState);
    setFilterFieldsOpen(false);
  };

  let rows = [];

  if (transfers) rows = buildRows();

  const colNames = [
    'Transfer Date',
    'Investment',
    'Status',
    'Activity',
    'Amount'
  ];
  const paginationOptions = getPaginationOptions();
  const loadingRowsLength = rowsPerPage > 100 ? 100 : rowsPerPage;

  return (
    <>
      <div className={styles.topWrap}>
        <div className={styles.top}>
          <div>
            <h3>Payment Activity</h3>
            <div className={styles.transferCount}>
              Showing {transferCount} transfers
            </div>
            <button
              className={`text-link underline ${styles.textLink} ${
                filterFieldsOpen ? styles.open : ''
              }`}
              onClick={toggleFilterFields}
              type="button"
            >
              Filter
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          <Button
            className={styles.exportButton}
            disabled={loading || transfers?.length === 0}
            onClick={exportRows}
            variant="outlined"
          >
            <>
              <i className="fa fa-download" /> Export to CSV
            </>
          </Button>
        </div>
        <PaymentsTableFilters
          activeFilters={activeFilters}
          clearFilter={clearFilter}
          control={control}
          errors={errors}
          filterFieldsOpen={filterFieldsOpen}
          formValues={formValues}
          handleSubmit={handleSubmit}
          investmentOptions={investmentOptions}
          onSubmit={handleFilterSubmit}
          toggleFilterFields={toggleFilterFields}
        />
      </div>

      {!loading && !(rows.length > 0) ? (
        <div className={styles.topWrap}>
          <h4>No payment activity found.</h4>
        </div>
      ) : (
        <Table
          className={styles.table}
          columnHeaders={colNames}
          loading={loading}
          loadingRows={loadingRowsLength}
          pagination={paginationOptions}
          rows={rows}
        />
      )}
    </>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.payments.loading,
    transferCount: store.payments.transferCount,
    transfers: store.payments.transfers
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(PaymentsTable);
