import { LegacyRef } from 'react';
import { Field as FieldType, FieldError, FieldErrors } from 'react-hook-form';

import utils from './utils';

type Field = FieldType['_f'] & {
  ref: LegacyRef<HTMLInputElement>;
};

// Helper for unpacking nested error messages with nested fields. Recursively
// looks up the error message based on field name and returns it, if found.
const getNestedErrors = (fieldName: string, errors: FieldErrors): string => {
  const nestedKeys = fieldName.split('.');
  let current: FieldErrors | FieldError = errors;
  let hasError = false;
  // The name of the key in the error object that we are actually looking for
  const rootKey = nestedKeys[nestedKeys.length - 1];

  for (const keyName of nestedKeys) {
    const isRoot = keyName === rootKey;

    if (current === undefined) {
      break;
    }

    if (Object.prototype.hasOwnProperty.call(current, keyName)) {
      current = current[keyName];
    } else {
      break;
    }

    if (isRoot && Object.prototype.hasOwnProperty.call(current, 'message')) {
      hasError = true;
      break;
    }
  }

  if (hasError && typeof current.message === 'string') {
    return current.message;
  }

  return '';
};

// When passed a field object from the Controller component and formState.errors,
// sets commonly reused props on REUI field components
export const setFieldProps = (field: Field, errors: FieldErrors) => {
  return {
    ...field,
    errorMessage: getNestedErrors(field.name, errors),
    id: field.name,
    onChange: e => field.onChange(e),
    value: field.value || ''
  };
};

// Sets checkbox props
export const setCheckboxFieldProps = (field: Field, errors: FieldErrors) => {
  return {
    ...field,
    checked: field.value || false,
    errorMessage: getNestedErrors(field.name, errors),
    id: field.name
  };
};

// Sets RadioButton props
export const setRadioFieldProps = (
  field: Field,
  errors: FieldErrors,
  optionValue: string | boolean
) => {
  return {
    ...field,
    checked: utils.normalizeBoolean(field.value) === optionValue,
    errorMessage: getNestedErrors(field.name, errors),
    id: `${field.name}_${optionValue}`,
    onChange: () => {
      field.onChange(utils.normalizeBoolean(optionValue));
    }
  };
};
