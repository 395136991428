import { FileUploader, Select } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue
} from 'react-hook-form';
import { maxUploadSize } from 'utilities/constants';
import { setFieldProps } from 'utilities/formHelpers';

import { idTypes } from '../contents';
import { BeneficialOwnerFields } from '../types';

interface Props {
  control: Control<BeneficialOwnerFields>;
  errors: FieldErrors<BeneficialOwnerFields>;
  setValue: UseFormSetValue<BeneficialOwnerFields>;
  uploading: boolean;
}

const BeneficialOwnerDocumentFields = ({
  control,
  errors,
  setValue,
  uploading
}: Props) => {
  return (
    <Row className="margin-row">
      <Col md={6}>
        <Controller
          control={control}
          name="beneficial_owner.document"
          render={({ field }) => (
            <FileUploader
              {...setFieldProps(field, errors)}
              acceptedFileTypes={['JPG', 'PNG']}
              maxSize={maxUploadSize}
              onRemove={() => {}}
              showImage
              upload={(file: File) =>
                setValue('beneficial_owner.document', file)
              }
              uploading={uploading}
            />
          )}
        />
      </Col>
      <Col className="overflow-visible" md={6}>
        <Controller
          control={control}
          name="beneficial_owner.document_type"
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label="Document Type"
              options={idTypes.map(key => ({
                label: key.label,
                value: key.value
              }))}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default BeneficialOwnerDocumentFields;
