import { yupResolver } from '@hookform/resolvers/yup';
import { IraFields } from 'containers/Accounts/types';
import { accountInformationIraSchema } from 'containers/Accounts/validation';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createInvestmentAccount } from 'redux/actions/account';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import AccountWrap from '../AccountWrap/AccountWrap';
import IraAccountFields from './IraAccountFields';

interface Props {
  dispatch: Dispatch;
  user: User;
}

const NewIraAccount = ({ dispatch, user }: Props) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    watch
  } = useForm<IraFields>({
    resolver: yupResolver(accountInformationIraSchema)
  });

  const businessType = watch('investment_account.business_type');
  const entityName = watch('investment_account.entity_name');

  const onSubmit = values => {
    if (values.investment_account) {
      values.investment_account.type = 'ira';
      return dispatch(createInvestmentAccount(values))
        .then(res => {
          EmAnalytics.track('Submits IRA Information', 'Onboarding', {
            account_type: 'IRA'
          });

          const referenceId = res.investment_account.reference_id;
          navigate(`/accounts/wizard/ira/thanks/${referenceId}`);
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    }
    return null;
  };

  const cardHeader = (
    <h2 className="margin-top-0">Individual Retirement Account</h2>
  );

  return (
    <AccountWrap cardHeader={cardHeader}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IraAccountFields
          businessType={businessType}
          control={control}
          entityName={entityName}
          errors={errors}
          irRep={user.investor_profile?.representative_meeting_link}
          submitting={isSubmitting}
        />
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(NewIraAccount);
