import { Button } from '@equitymultiple/react-eui';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import RecoveryCodes from 'components/RecoveryCodes/RecoveryCodes';
import SmsConfirmationForm from 'components/SmsConfirmationForm/SmsConfirmationForm';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { Dispatch } from 'types/redux';

import RequireLoginWrap from '../components/RequireLoginWrap';
import { PhoneFormValues } from '../types';
import PhoneForm from './components/PhoneForm/PhoneForm';

interface Props {
  dispatch: Dispatch;
  recoveryCodes: string[];
}

const Phone = ({ dispatch, recoveryCodes }: Props) => {
  const navigate = useNavigate();
  const [showSmsConfirmationForm, setShowSmsConfirmationForm] = useState(false);
  const [phoneFormValues, setPhoneFormValues] = useState<PhoneFormValues>();

  useRecaptcha();

  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';
  }, []);

  const getSmsConfirmationFormHeading = () => {
    let heading = 'Complete opt-in to receive SMS messages on your phone';
    if (phoneFormValues?.enable_two_factor && phoneFormValues?.opted_to_text) {
      heading =
        'Complete setting up your phone for security and messages about offering or your portfolio';
    } else if (phoneFormValues.enable_two_factor) {
      heading = 'Complete securing your account with two factor authentication';
    }
    return heading;
  };

  const handlePhoneFormSubmitSuccess = values => {
    setPhoneFormValues(values);
    setShowSmsConfirmationForm(true);
  };

  const handleSmsSubmitSuccess = async res => {
    await dispatch(loadAuth());

    // If a user chose to enable 2FA then they'll receive backup_codes, if they just opt into SMS then they won't
    if (res.backup_codes) {
      setShowSmsConfirmationForm(false);
    } else {
      navigate('/users/signup/discover');
    }
  };

  return (
    <RequireLoginWrap>
      <AccountProgressContainer showSkipLink signupStage="sign up">
        {recoveryCodes ? (
          <>
            <RecoveryCodes recoveryCodes={recoveryCodes} showHeading />
            <Button
              className="button-fixed-width margin-top-xx"
              variant="orange"
              wrapper={<Link to="/users/signup/discover" />}
            >
              Continue
            </Button>
          </>
        ) : showSmsConfirmationForm ? (
          <>
            <h3 className="margin-x">{getSmsConfirmationFormHeading()}</h3>
            <SmsConfirmationForm
              changePhone={() => setShowSmsConfirmationForm(false)}
              onSubmitSuccess={handleSmsSubmitSuccess}
              phoneFormValues={phoneFormValues}
            />
          </>
        ) : (
          <PhoneForm onSubmitSuccess={handlePhoneFormSubmitSuccess} />
        )}
      </AccountProgressContainer>
    </RequireLoginWrap>
  );
};

function mapStateToProps(state) {
  return {
    recoveryCodes: state.userSettings.twoFactorRecoveryCodes
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Phone);
