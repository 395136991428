import { ClosingInvestment, ClosingStage } from 'types/api/closing';

export const getOfferingCardValue = (
  closingStage: ClosingStage,
  investment: ClosingInvestment
) => {
  if (investment) {
    const {
      is_funded: isFunded,
      is_reinvestment: isReinvestment,
      status,
      transaction_status: transactionStatus
    } = investment;

    if (status === 'interest') {
      return 'Complete Your Investment';
    }

    if (status === 'waitlist') {
      if (closingStage === 'interest') {
        return 'View Pledge';
      }
      return 'You Are On The Waitlist';
    }

    if (status === 'signed') {
      if (transactionStatus === 'failed') {
        return 'Update Payment';
      }
      if (transactionStatus === 'pending' && (isReinvestment || isFunded)) {
        return 'Review Your Investment';
      }
      return 'Complete Your Investment';
    }

    if (status === 'funded') {
      if (transactionStatus === 'pending') {
        return 'Investment Pending';
      }
      if (transactionStatus === 'posted' || isFunded) {
        return 'Review Your Investment';
      }
      return 'Complete Your Investment';
    }
  }
  return 'View Offering';
};

export const getInvestmentWizardRoute = (
  closingId: number | string,
  investment: ClosingInvestment
) => {
  const {
    documentation_status: documentationStatus,
    is_funded: isFunded,
    is_reinvestment: isReinvestment,
    status,
    transaction_status: transactionStatus
  } = investment;

  const route = `/invest/${closingId}/investment/${investment.id}/`;
  let subRoute = '';

  if (status === 'interest') {
    subRoute = 'interest';

    if (documentationStatus === 'To Do') {
      subRoute = 'sign';
    }
  }

  if (status === 'waitlist') {
    subRoute = 'interest';
  }

  if (status === 'signed') {
    subRoute = 'fund';

    if (transactionStatus === 'pending' && (isReinvestment || isFunded)) {
      subRoute = 'confirm';
    }
  }

  if (status === 'funded') {
    subRoute = 'confirm';
  }

  return route + subRoute;
};
