import { Modal } from '@equitymultiple/react-eui';
import React from 'react';

type Props = {
  hideLearnMoreModal: () => void;
  showModal: boolean;
};

const LearnMoreModal = ({ hideLearnMoreModal, showModal }: Props) => {
  return (
    <Modal
      onClose={hideLearnMoreModal}
      open={showModal}
      title={<h6>Invite Friends, Earn Even Better Returns</h6>}
    >
      <ul className="margin-xxx margin-top-xxx">
        <li>
          <strong>Invite in Multiple Ways:</strong> Invite as many friends as
          you like through email, social share buttons, or with your customized
          invite link.
        </li>
        <li>
          <strong>Earn Unlimited Credits:</strong> For each friend that signs up
          and invests on EQUITYMULTIPLE, you'll earn one Multiplier credit -
          don't worry, we're keeping track!
        </li>
        <li>
          <strong>Your Friend Gets the Same Benefit:</strong> Each friend you
          invite will have one Multiplier credit waiting for them in their
          account.
        </li>
        <li>
          <strong>Credits Improve Your Returns:</strong> The next time you
          invest you'll automatically use a Multiplier credit to increase your
          potential earnings by up to 1% annually (i.e., from 8-9%) more.
        </li>
      </ul>
      <p>
        Questions? Please reach out to us at{' '}
        <a href="mailto:help@equitymultiple.com">help@equitymultiple.com</a>
      </p>
    </Modal>
  );
};

export default LearnMoreModal;
