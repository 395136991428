import {
  EmailInvitiationPayload,
  LoadInvitationsResponse
} from 'types/actions/invite';
import { AsyncActionCreator } from 'types/redux';

export const LOAD = 'invite/LOAD';
export const LOAD_SUCCESS = 'invite/LOAD_SUCCESS';
export const LOAD_FAIL = 'invite/LOAD_FAIL';

export const RESEND_INVITE = 'invite/RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'invite/RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_FAIL = 'invite/RESEND_INVITE_FAIL';

export const INVITATION_SEND = 'invite/INVITATION_SEND';
export const INVITATION_SEND_SUCCESS = 'invite/INVITATION_SEND_SUCCESS';
export const INVITATION_SEND_FAIL = 'invite/INVITATION_SEND_FAIL';

export function loadInvitations(): AsyncActionCreator<LoadInvitationsResponse> {
  return {
    promise: client => client.get<LoadInvitationsResponse>('/mkt/invitations'),
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL]
  };
}

export function resendInviteAction(id) {
  return {
    promise: client => client.get(`/mkt/invitations/${id}/resend_invite`),
    types: [RESEND_INVITE, RESEND_INVITE_SUCCESS, RESEND_INVITE_FAIL]
  };
}

export function emailInvitation(params: EmailInvitiationPayload) {
  return {
    promise: client => client.post('/mkt/users/invitation', { data: params }),
    types: [INVITATION_SEND, INVITATION_SEND_SUCCESS, INVITATION_SEND_FAIL]
  };
}
