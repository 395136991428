import { Button, Card } from '@equitymultiple/react-eui';
import React from 'react';
import { Container } from 'react-grid-system';

const Confirmation = () => {
  return (
    <Card>
      <h4 className="margin-xx">You&apos;ve completed your account review</h4>
      <div className="margin-xx size-16">
        Thank you for verifying your information - this will help us prepare
        your tax materials and stay current with regulatory requirements.
      </div>
      <Container className="text-center">
        <Button href="/" style={{ width: 200 }}>
          View Offerings
        </Button>
      </Container>
    </Card>
  );
};

export default Confirmation;
