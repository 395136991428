import { ErrorLabel, RadioButton, Select } from '@equitymultiple/react-eui';
import { FundFormFields } from 'containers/Investment/types';
import AchIcon from 'images/icons/ach-payment.svg';
import CheckIcon from 'images/icons/check-payment.svg';
import WireIcon from 'images/icons/wire-payment.svg';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { CapitalCall } from 'types/actions/capital-call';
import {
  FundingOptions,
  LoadInvestmentResponse,
  PaymentMethod
} from 'types/actions/investments';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';

import * as styles from './PaymentForm.module.scss';

interface Props {
  capitalCallTransaction?: CapitalCall;
  control: Control<FundFormFields>;
  errors: FieldErrors<FundFormFields>;
  fundingOptions: FundingOptions;
  investment: LoadInvestmentResponse;
  selectedBalance: number;
  selectedPaymentMethod: PaymentMethod;
  setValue: (field, value, options) => void;
}

const PaymentForm = ({
  capitalCallTransaction,
  control,
  errors,
  fundingOptions,
  investment,
  selectedBalance,
  selectedPaymentMethod,
  setValue
}: Props) => {
  const bankAccountOptions = () => {
    return fundingOptions?.funding_sources?.length > 0
      ? fundingOptions.funding_sources.map(fundingSource => ({
          label: fundingSource.bank_name
            ? `${fundingSource.bank_name} - ${fundingSource.type} (${fundingSource.account_number})`
            : `${fundingSource.name} (${fundingSource.account_number})`,
          value: fundingSource.id
        }))
      : [];
  };

  const remainingAmount = capitalCallTransaction
    ? parseFloat(capitalCallTransaction.amount)
    : parseFloat(investment.unfunded_amount) > 0
      ? parseFloat(investment.unfunded_amount) - selectedBalance
      : parseFloat(investment.pending_amount || '0');

  const errorMessage = errors.payment_method?.message;

  const wrapperClass = errorMessage
    ? `${styles.wrapper} ${styles.invalid}`
    : styles.wrapper;

  const updatePaymentMethod = value => {
    setValue('payment_method', value, { shouldValidate: true });
  };

  return (
    <div>
      <p className="weight-md-heavy">
        {parseInt(investment.unfunded_amount) === 0
          ? 'Amount'
          : 'Remaining amount'}{' '}
        to fund: {utils.formatCurrency(remainingAmount)}
      </p>
      {errorMessage && <ErrorLabel message={errorMessage} />}
      <Controller
        control={control}
        name="payment_method"
        render={({ field }) => (
          <>
            {fundingOptions?.funding_sources?.length > 0 && (
              <div
                className={`${wrapperClass} ${selectedPaymentMethod === 'ACH' && styles.selected}`}
              >
                <button
                  className={styles.rowContainer}
                  data-testid="achPaymentMethod"
                  onClick={() => updatePaymentMethod('ACH')}
                  type="button"
                >
                  <div className={styles.iconWrapper}>
                    <AchIcon />
                  </div>
                  <div className={styles.fullWidth}>
                    ACH
                    <div className={styles.secondaryText}>
                      1 - 2 business days
                    </div>
                  </div>
                  <RadioButton
                    {...setRadioFieldProps(field, errors, 'ACH')}
                    hideError
                    label=""
                  />
                </button>
                {selectedPaymentMethod === 'ACH' && (
                  <div className={styles.bankAccountSelectWrapper}>
                    <hr />
                    <Controller
                      control={control}
                      name="source"
                      render={({ field: selectField }) => (
                        <Select
                          {...setFieldProps(selectField, errors)}
                          className={`input-fixed-width ${styles.bankAccountSelect}`}
                          label="Bank Account"
                          options={bankAccountOptions()}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            )}
            <div
              className={`${wrapperClass} ${selectedPaymentMethod === 'WIRE' && styles.selected}`}
            >
              <button
                className={styles.rowContainer}
                data-testid="wirePaymentMethod"
                onClick={() => updatePaymentMethod('WIRE')}
                type="button"
              >
                <div className={styles.iconWrapper}>
                  <WireIcon />
                </div>
                <div className={styles.fullWidth}>
                  WIRE
                  <div className={styles.secondaryText}>
                    1 - 2 business days (fees may apply)
                  </div>
                </div>

                <RadioButton
                  {...setRadioFieldProps(field, errors, 'WIRE')}
                  hideError
                  label=""
                />
              </button>
            </div>
            <div
              className={`${wrapperClass} ${selectedPaymentMethod === 'CHECK' && styles.selected}`}
            >
              <button
                className={styles.rowContainer}
                data-testid="checkPaymentMethod"
                onClick={() => updatePaymentMethod('CHECK')}
                type="button"
              >
                <div className={styles.iconWrapper}>
                  <CheckIcon />
                </div>
                <div className={styles.fullWidth}>
                  CHECK
                  <div className={styles.secondaryText}>
                    1 - 10 business days
                  </div>
                </div>
                <RadioButton
                  {...setRadioFieldProps(field, errors, 'CHECK')}
                  hideError
                  label=""
                />
              </button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default PaymentForm;
