import React, {
  forwardRef,
  LegacyRef,
  useEffect,
  useRef,
  useState
} from 'react';
import Reaptcha from 'reaptcha';

export interface Props {
  errorMessage: string;
  onChange(...args: unknown[]): unknown;
  value: string;
  valueReset: boolean;
}

const Captcha = forwardRef(
  (
    { errorMessage = '', onChange, value, valueReset = false }: Props,
    forwardedRef
  ) => {
    const [expired, setExpired] = useState(false);
    const [verified, setVerified] = useState(false);
    const captcha = useRef<Reaptcha>(null);
    const shouldReset = valueReset && !expired;

    const size = window.innerWidth > 400 ? 'normal' : 'compact';

    useEffect(() => {
      if (verified && value == '') setVerified(false);

      if (!verified && value) {
        setVerified(true);
        setExpired(false);
      }
    }, [value, verified]);

    useEffect(() => {
      if (captcha?.current?.state.rendered && shouldReset) {
        captcha.current.reset();
      }
    }, [shouldReset]);

    const handleExpire = () => {
      setExpired(true);
      onChange('');
    };

    return (
      <div
        data-testid="Captcha"
        ref={forwardedRef as LegacyRef<HTMLDivElement>}
      >
        <Reaptcha
          onExpire={handleExpire}
          onVerify={onChange}
          ref={captcha}
          sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
          size={size}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    );
  }
);

Captcha.displayName = 'Captcha';

export default Captcha;
