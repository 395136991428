import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import React from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  AnyInvestmentAccountType,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { User } from 'types/actions/auth';

import * as styles from './AccountWrap.module.scss';

interface Props {
  account?: LoadInvestmentAccountAccount<AnyInvestmentAccountType>;
  cardHeader?: React.ReactNode;
  children: React.ReactNode;
  hideHeader?: boolean;
  loading?: boolean;
  onCompletionPage?: boolean;
  user: User;
}

const AccountWrap = ({
  account,
  cardHeader,
  children,
  hideHeader,
  loading,
  onCompletionPage,
  user
}: Props) => {
  const showAccountProgress =
    user?.investor_profile.stage === 'activate' ||
    (onCompletionPage && user?.created_investment_accounts_count === 1);

  return loading ? (
    <EMLoadingIcon />
  ) : (
    <>
      {!hideHeader && (
        <Container
          className={
            showAccountProgress ? 'container-wide' : 'container-narrow'
          }
        >
          <div className={styles.cardHeader}>
            {cardHeader || <h2 className="margin-top-0">{account?.title}</h2>}
          </div>
        </Container>
      )}

      {showAccountProgress ? (
        <AccountProgressContainer
          account={account}
          isComplete={onCompletionPage}
          showSkipLink
          signupStage="investment account"
        >
          {children}
        </AccountProgressContainer>
      ) : (
        <Container className="container-narrow">
          <Card data-testid="card">{children}</Card>
        </Container>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(AccountWrap);
