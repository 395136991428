import {
  RequestPhoneVerificationCodePayload,
  UpdatePasswordPayload,
  UpdatePersonalIdentificationPayload,
  UpdateUsernamePayload,
  UpdateUserProfilePayload,
  VerifyPhoneVerificationCodePayload
} from 'types/actions/user-settings';

export const RENEW_API_KEY = 'user-settings/RENEW_API_KEY';
export const RENEW_API_KEY_SUCCESS = 'user-settings/RENEW_API_KEY_SUCCESS';
export const RENEW_API_KEY_FAIL = 'user-settings/RENEW_API_KEY_FAIL';

export const UPDATE_USERNAME = 'user-settings/UPDATE_USERNAME';
export const UPDATE_USERNAME_SUCCESS = 'user-settings/UPDATE_USERNAME_SUCCESS';
export const UPDATE_USERNAME_FAIL = 'user-settings/UPDATE_USERNAME_FAIL';

export const UPDATE_PASSWORD = 'user-settings/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'user-settings/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'user-settings/UPDATE_PASSWORD_FAIL';

export const UPDATE_USER_PROFILE = 'user-settings/UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS =
  'user-settings/UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL =
  'user-settings/UPDATE_USER_PROFILE_FAIL';

export const UPDATE_PERSONAL_IDENTIFICATION =
  'user-settings/UPDATE_PERSONAL_IDENTIFICATION';
export const UPDATE_PERSONAL_IDENTIFICATION_SUCCESS =
  'user-settings/UPDATE_PERSONAL_IDENTIFICATION_SUCCESS';
export const UPDATE_PERSONAL_IDENTIFICATION_FAIL =
  'user-settings/UPDATE_PERSONAL_IDENTIFICATION_FAIL';

export const UPDATE_ID_DOCUMENT = 'user-settings/UPDATE_ID_DOCUMENT';
export const UPDATE_ID_DOCUMENT_SUCCESS =
  'user-settings/UPDATE_ID_DOCUMENT_SUCCESS';
export const UPDATE_ID_DOCUMENT_FAIL = 'user-settings/UPDATE_ID_DOCUMENT_FAIL';

export const DISCONNECT_SOCIAL_ACCOUNT =
  'user-settings/DISCONNECT_SOCIAL_ACCOUNT';
export const DISCONNECT_SOCIAL_ACCOUNT_SUCCESS =
  'user-settings/DISCONNECT_SOCIAL_ACCOUNT_SUCCESS';
export const DISCONNECT_SOCIAL_ACCOUNT_FAIL =
  'user-settings/DISCONNECT_SOCIAL_ACCOUNT_FAIL';

export const REGENERATE_RECOVERY_CODES =
  'user-settings/REGENERATE_RECOVERY_CODES';
export const REGENERATE_RECOVERY_CODES_SUCCESS =
  'user-settings/REGENERATE_RECOVERY_CODES_SUCCESS';
export const REGENERATE_RECOVERY_CODES_FAIL =
  'user-settings/REGENERATE_RECOVERY_CODES_FAIL';
export const CLEAR_RECOVERY_CODES = 'user-settings/CLEAR_RECOVERY_CODES';

export const REQUEST_PHONE_VERIFICATION_CODE =
  'user-settings/REQUEST_PHONE_VERIFICATION_CODE';
export const REQUEST_PHONE_VERIFICATION_CODE_SUCCESS =
  'user-settings/REQUEST_PHONE_VERIFICATION_CODE_SUCCESS';
export const REQUEST_PHONE_VERIFICATION_CODE_FAIL =
  'user-settings/REQUEST_PHONE_VERIFICATION_CODE_FAIL';

export const VERIFY_PHONE_VERIFICATION_CODE =
  'user-settings/VERIFY_PHONE_VERIFICATION_CODE';
export const VERIFY_PHONE_VERIFICATION_CODE_SUCCESS =
  'user-settings/VERIFY_PHONE_VERIFICATION_CODE_SUCCESS';
export const VERIFY_PHONE_VERIFICATION_CODE_FAIL =
  'user-settings/VERIFY_PHONE_VERIFICATION_CODE_FAIL';

export function updatePersonalIdentification(
  formData: UpdatePersonalIdentificationPayload
) {
  return {
    promise: client =>
      formData.user.id_document
        ? client.put('/mkt/users/id_document', {
            attach: { id_document: formData.user.id_document },
            field: formData.user
          })
        : client.put('/mkt/users/id_document', {
            data: formData.user
          }),
    types: [
      UPDATE_PERSONAL_IDENTIFICATION,
      UPDATE_PERSONAL_IDENTIFICATION_SUCCESS,
      UPDATE_PERSONAL_IDENTIFICATION_FAIL
    ]
  };
}

export function updateUserProfile(formData: UpdateUserProfilePayload) {
  if (formData.id_document) delete formData.id_document;

  return {
    promise: client =>
      client.put('/mkt/users/profile', { data: { user: formData } }),
    types: [
      UPDATE_USER_PROFILE,
      UPDATE_USER_PROFILE_SUCCESS,
      UPDATE_USER_PROFILE_FAIL
    ]
  };
}

export function updateUsername(formData: UpdateUsernamePayload) {
  return {
    promise: client =>
      client.put('/mkt/users/update_username', { data: formData }),
    types: [UPDATE_USERNAME, UPDATE_USERNAME_SUCCESS, UPDATE_USERNAME_FAIL]
  };
}

export function updatePassword(formData: UpdatePasswordPayload) {
  return {
    promise: client =>
      client.put('/mkt/users/update_password', { data: formData }),
    types: [UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL]
  };
}

export function disconnectSocialAccount(params) {
  return {
    promise: client =>
      client.del('/mkt/users/disconnect_social_account', { data: params }),
    types: [
      DISCONNECT_SOCIAL_ACCOUNT,
      DISCONNECT_SOCIAL_ACCOUNT_SUCCESS,
      DISCONNECT_SOCIAL_ACCOUNT_FAIL
    ]
  };
}

export function regenerateRecoveryCodes() {
  return {
    promise: client => client.post('/mkt/users/reset_backup_codes'),
    types: [
      REGENERATE_RECOVERY_CODES,
      REGENERATE_RECOVERY_CODES_SUCCESS,
      REGENERATE_RECOVERY_CODES_FAIL
    ]
  };
}

export function clearRecoveryCodes() {
  return {
    type: CLEAR_RECOVERY_CODES
  };
}

export function requestPhoneVerificationCode(
  data: RequestPhoneVerificationCodePayload
) {
  return {
    promise: client =>
      client.post('/mkt/users/phone_verification/request', { data }),
    types: [
      REQUEST_PHONE_VERIFICATION_CODE,
      REQUEST_PHONE_VERIFICATION_CODE_SUCCESS,
      REQUEST_PHONE_VERIFICATION_CODE_FAIL
    ]
  };
}

export function verifyPhoneVerificationCode(
  data: VerifyPhoneVerificationCodePayload
) {
  return {
    promise: client =>
      client.post('/mkt/users/phone_verification/verify', { data }),
    types: [
      VERIFY_PHONE_VERIFICATION_CODE,
      VERIFY_PHONE_VERIFICATION_CODE_SUCCESS,
      VERIFY_PHONE_VERIFICATION_CODE_FAIL
    ]
  };
}
