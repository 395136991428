import logo from 'images/logos/full-logo-white.svg?url';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

import * as styles from './Footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer} data-testid="Footer">
      <Container className={styles.container}>
        <img alt="EquityMultiple" className={styles.footerLogo} src={logo} />
        <Row>
          <Col lg={4}>
            <div className={styles.contact}>
              <a href="tel:+16468449918">+1 (646) 844-9918</a>
              <div>9am - 6pm EST</div>
              <div>
                General:{' '}
                <a href="mailto:contact@equitymultiple.com">
                  contact@equitymultiple.com
                </a>
              </div>
              <div>
                Support:{' '}
                <a href="mailto:help@equitymultiple.com">
                  help@equitymultiple.com
                </a>
              </div>
            </div>
          </Col>
          <Col className={styles.menuColumns} lg={8}>
            <div className={styles.menuColumnsRow}>
              <div className={styles.menuColumn}>
                <ul>
                  <li>
                    <a href="https://www.equitymultiple.com/about-us/">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/security/">
                      Trust &amp; Security
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/business-continuity/">
                      Business Continuity
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/raise-capital">
                      Raise Capital
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.menuColumn}>
                <ul>
                  <li>
                    <a href="https://www.youtube.com/@EQUITYMULTIPLE">
                      YouTube
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/equity-multiple">
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/equitymultiple_com/">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contact@equitymultiple.com">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className={styles.menuColumn}>
                <ul>
                  <li>
                    <a href="https://www.equitymultiple.com/blog/">Insights</a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/glossary">
                      Glossary
                    </a>
                  </li>
                  <li>
                    <a href="https://jobs.lever.co/equitymultiple">Careers</a>
                  </li>
                </ul>
              </div>
              <div className={styles.menuColumn}>
                <ul>
                  <li>
                    <a href="https://www.equitymultiple.com/terms/">
                      Terms Of Use
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/privacy/">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/copyright-dispute-policy">
                      Copyright Dispute Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.equitymultiple.com/disclaimer/">
                      Disclaimer
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.footerBorder} />

        <Row>
          <Col className={styles.footerLeft} md={6}>
            <p>
              &#169; {currentYear} Equity Multiple, Inc. All Rights Reserved.
            </p>
          </Col>
          <Col className={styles.footerRight} md={6}>
            <a
              className={styles.footerSocial}
              href="https://www.facebook.com/equitymultiple"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-facebook-square" />
            </a>
            <a
              className={styles.footerSocial}
              href="https://www.youtube.com/@EQUITYMULTIPLE"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-youtube-play" />
            </a>
            <a
              className={styles.footerSocial}
              href="https://www.linkedin.com/company/equity-multiple"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-linkedin-square" />
            </a>
          </Col>
        </Row>

        <div className={styles.disclaimer}>
          <p>
            No communication by Equity Multiple, Inc. or any of its affiliates
            (collectively, “EquityMultiple”), through this website or any other
            medium, should be construed or is intended to be a recommendation to
            purchase, sell or hold any security or otherwise to be investment,
            tax, financial, accounting, legal, regulatory or compliance advice.
            Nothing on this website is intended as an offer to extend credit, an
            offer to purchase or sell securities or a solicitation of any
            securities transaction.
          </p>

          <p>
            Any financial targets or returns shown on the website are estimated
            predictions of performance only, are hypothetical, are not based on
            actual investment results and are not guarantees of future results.
            Estimated targets do not represent or guarantee the actual results
            of any transaction, and no representation is made that any
            transaction will, or is likely to, achieve results or profits
            similar to those shown. In addition, other financial metrics and
            calculations shown on the website (including amounts of principal
            and interest repaid) have not been independently verified or audited
            and may differ from the actual financial metrics and calculations
            for any investment, which are contained in the investors’
            portfolios. Any investment information contained herein has been
            secured from sources that EquityMultiple believes are reliable, but
            we make no representations or warranties as to the accuracy or
            completeness of such information and accept no liability therefor.
          </p>

          <p>
            Private placement investments are NOT bank deposits (and thus NOT
            insured by the FDIC or by any other federal governmental agency),
            are NOT guaranteed by EquityMultiple or any other party, and MAY
            lose value. Neither the Securities and Exchange Commission nor any
            federal or state securities commission or regulatory authority has
            recommended or approved any investment or the accuracy or
            completeness of any of the information or materials provided by or
            through the website. Investors must be able to afford the loss of
            their entire investment.
          </p>

          <p>
            Investments in private placements are speculative and involve a high
            degree of risk and those investors who cannot afford to lose their
            entire investment should not invest. Additionally, investors may
            receive illiquid and/or restricted securities that may be subject to
            holding period requirements and/or liquidity concerns. Investments
            in private placements are highly illiquid and those investors who
            cannot hold an investment for the long term (at least 5-7 years)
            should not invest. Real estate and other alternative investments
            should only be part of your overall investment portfolio.
          </p>

          <p>
            Articles or information from third-party media outside of this
            domain may discuss EquityMultiple or relate to information contained
            herein, but EquityMultiple does not approve and is not responsible
            for such content. Hyperlinks to third-party sites, or reproduction
            of third-party articles, do not constitute an approval or
            endorsement by EquityMultiple of the linked or reproduced content.
          </p>

          <p>
            Investing in securities or real property investments (the
            ""Investments"") listed on EquityMultiple pose risks, including but
            not limited to market risk, credit risk, interest rate risk, and the
            risk of losing some or all of the money you invest. Before investing
            you should: (1) conduct your own investigation and analysis; (2)
            carefully consider the investment and all related charges, expenses,
            uncertainties and risks, including all uncertainties and risks
            described in offering materials; and (3) consult with your own
            investment, tax, financial and legal advisors. Such Investments are
            only suitable for accredited investors who understand and willing
            and able to accept the high risks associated with private
            investments.
          </p>

          <p>
            Investing in private placements requires long-term commitments, the
            ability to afford to lose the entire investment, and low liquidity
            needs. This website provides preliminary and general information
            about the Investments and is intended for initial reference purposes
            only. It does not summarize or compile all the applicable
            information. This website does not constitute an offer to sell or
            buy any securities or other investments. No offer or sale of any
            Investments will occur without the delivery of confidential offering
            materials and related documents. This information contained herein
            is qualified by and subject to more detailed information in the
            applicable offering materials. EquityMultiple is not registered as a
            broker-dealer. EquityMultiple does not make any representation or
            warranty to any prospective investor regarding the legality of an
            investment in any EquityMultiple Investments.
          </p>

          <p>
            Banking services are provided by Blue Ridge Bank, Member FDIC.
            Payment processing is provided by Dwolla, Inc.
          </p>

          <p>
            Investment advisory services are provided by EM Advisor, LLC, an
            investment advisor registered with the Securities and Exchange
            Commission.
          </p>

          <p>
            Our site uses a third party service to match browser cookies to your
            mailing address. We then use another company to send special offers
            through the mail on our behalf. Our company never receives or stores
            any of this information and our third parties do not provide or sell
            this information to any other company or service.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
