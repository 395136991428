export const INVITE_SHOW_LEARN_MORE_MODAL =
  'modal/INVITE_SHOW_LEARN_MORE_MODAL';
export const SHOW_EMAIL_INVITE_MODAL = 'modal/SHOW_EMAIL_INVITE_MODAL';
export const POST_EMAIL_NOTIFY_MODAL = 'modal/POST_EMAIL_NOTIFY_MODAL';
export const SET_AD_BLOCKER_MODAL_CONTENT =
  'modal/SET_AD_BLOCKER_MODAL_CONTENT';

export function setAdBlockerModalContent(content) {
  return {
    content,
    type: SET_AD_BLOCKER_MODAL_CONTENT
  };
}
