import { Modal } from '@equitymultiple/react-eui';
import { Image, NavComponent } from 'containers/Offerings/types';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Offering } from 'types/api/offering';
import utils from 'utilities/utils';

import ImageGallery from '../ImageGallery/ImageGallery';
import SideNavigation from '../SideNavigation/SideNavigation';
import * as styles from './Header.module.scss';

interface Props {
  hero: {
    data: {
      omcms: {
        images: Image[];
        left_gradient?: boolean;
        subimage?: Image;
        subtext_body?: string;
        subtext_title?: string;
      };
    };
  };
  images: Image[];
  mustVerifyToInvest: boolean;
  navComponents: NavComponent[];
  offering: Offering;
  showGallery: boolean;
  toggleGallery: () => void;
  trackSideNavigationLinkClick: (linkText: string) => void;
}

const Header = ({
  hero,
  images,
  mustVerifyToInvest,
  navComponents,
  offering,
  showGallery,
  toggleGallery,
  trackSideNavigationLinkClick
}: Props) => {
  useEffect(() => {
    document.title = `${offering.title} | EquityMultiple`;
  }, [offering]);

  const showProgress = offering.show_progress;
  let heroStyle;
  let heroImage;

  const omcmsData = hero?.data?.omcms;

  if (omcmsData?.images?.length > 0) {
    heroImage = hero.data.omcms.images[0].versions.xlarge;
    if (window.innerWidth < 1201)
      heroImage = hero.data.omcms.images[0].versions.large;
    heroStyle = {
      backgroundImage: 'url(' + heroImage + ')'
    };
  }

  return (
    <section
      className={`${styles.header} ${
        omcmsData?.left_gradient ? styles.leftGradient : ''
      }`}
      data-testid="header"
      style={heroStyle}
    >
      <Modal
        className={styles.galleryModal}
        darkMode
        onClose={toggleGallery}
        open={showGallery}
        title={<h6>Photos</h6>}
      >
        <ImageGallery images={images} />
      </Modal>
      <div className={styles.intro}>
        <Container>
          <Row style={{ alignItems: 'flex-end' }}>
            <Col xl={10}>
              <span>
                {omcmsData?.subtext_title && (
                  <h2 className={styles.subHeading} data-testid="subHeading">
                    {omcmsData.subtext_title}
                  </h2>
                )}
                {omcmsData?.subtext_body && (
                  <p className={styles.subCopy} data-testid="subCopy">
                    {omcmsData.subtext_body}
                  </p>
                )}

                {omcmsData?.subimage && (
                  <img
                    alt=""
                    className={styles.subImage}
                    data-testid="subImage"
                    src={omcmsData.subimage.versions.thumb}
                  />
                )}

                <h1 className={styles.title}>{offering.title}</h1>
                {showProgress && (
                  <p className={styles.progressLabel}>
                    {utils.formatCurrency(offering.total_amount_raised, 0) +
                      '  of  ' +
                      utils.formatCurrency(offering.total_target_amount, 0)}
                  </p>
                )}
              </span>
              {showProgress && (
                <div className={styles.progress}>
                  <div
                    className={styles.progressBar}
                    style={{
                      width: `${Number(offering.total_percent_raised)}%`
                    }}
                  />
                </div>
              )}
            </Col>
            <Col xl={2}>
              <SideNavigation
                mustVerifyToInvest={mustVerifyToInvest}
                navComponents={navComponents}
                toggleGallery={toggleGallery}
                trackLinkClick={trackSideNavigationLinkClick}
                type="top"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Header;
