import { Button, Card, FileUploader, Tooltip } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import {
  loadInvestmentAccount,
  loadInvestmentAccounts,
  loadPendingInvestments,
  uploadProfessionalLetter
} from '../../redux/actions/account';
import {
  LoadInvestmentAccountsAccount,
  LoadInvestmentAccountsResponse,
  LoadPendingInvestmentsResponse
} from '../../types/actions/account';
import { User } from '../../types/actions/auth';
import { Dispatch } from '../../types/redux';
import { maxUploadSize } from '../../utilities/constants';
import humane from '../../utilities/humane';
import utils from '../../utilities/utils';
import { getSingleOwnerAccountLabels } from './helpers';
import { verifyAccreditationSchema } from './validation';
import * as styles from './VerifyAccreditation.module.scss';

interface InvestmentsInfoProps {
  investments: LoadPendingInvestmentsResponse;
}

const InvestmentsInfo = ({ investments }: InvestmentsInfoProps) => {
  return (
    <div className={styles.investmentsInfo}>
      <div className={styles.textContent}>
        <p>
          Please verify your accreditation to comply with federal regulations
          and retain your position in the following investment(s).
          EquityMultiple retains the right to drop your investment if you do not
          verify your accreditation status.
        </p>
        {investments.map(investment => (
          <div className={styles.investment} key={investment.id}>
            <div className="text-label">Investment</div>
            <div className="value">{investment?.offering?.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

type Params = {
  reference_id: string;
};

type Props = {
  dispatch: Dispatch;
  investmentAccounts: LoadInvestmentAccountsResponse;
  loading: boolean;
  pendingInvestments: LoadPendingInvestmentsResponse;
  uploading: boolean;
  user: User;
};

interface FormFields {
  professionalLetter: File;
}

const VerifyAccreditation = ({
  dispatch,
  investmentAccounts,
  loading,
  pendingInvestments,
  uploading,
  user
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [account, setAccount] = useState<LoadInvestmentAccountsAccount | null>(
    null
  );
  const accountReferenceId = Number(params.reference_id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue
  } = useForm<FormFields>({
    resolver: yupResolver(verifyAccreditationSchema)
  });

  const successRedirect = () => {
    navigate(
      `/settings/accreditation?accreditation_success=${account.entity_name}`
    );
  };

  const handleCloseModal = () => {
    if (!loading) {
      dispatch(loadInvestmentAccount(accountReferenceId)).then(res => {
        if (res.investment_account.accreditation_status === 'pending') {
          successRedirect();
        }
      });
    }
  };

  useEffect(() => {
    document.title = 'Verify Your Accreditation | EquityMultiple';
  }, []);

  useEffect(() => {
    const embedScript = document.createElement('script');
    embedScript.src = process.env.REACT_APP_VERIFY_INVESTOR_EMBEDDED_API_URL;
    embedScript.async = true;
    document.body.appendChild(embedScript);
  }, []);

  useEffect(() => {
    dispatch(loadInvestmentAccounts())
      .then(accounts => {
        const selectedAccount = accounts?.find(
          investmentAccount =>
            investmentAccount.reference_id === accountReferenceId
        );

        const accountIsVerified =
          ['verified', 'pending'].includes(
            selectedAccount.accreditation_status
          ) && !selectedAccount.accreditation_is_expiring;

        if (!selectedAccount || accountIsVerified) {
          humane.notice(
            "You've already completed verification for this account"
          );
          navigate('/');
        } else setAccount(selectedAccount);
      })
      .catch(() => {
        navigate('/');
      });
  }, [dispatch, accountReferenceId, navigate]);

  useEffect(() => {
    if (user.investor_profile.stage === 'multiply') {
      dispatch(loadPendingInvestments(accountReferenceId));
    }
  }, [accountReferenceId, dispatch, user]);

  const onSubmit = values => {
    return dispatch(
      uploadProfessionalLetter(account.id, values.professionalLetter)
    )
      .then(() => {
        successRedirect();
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  const onUpload = file => {
    setValue('professionalLetter', file);
  };

  const onRemove = () => {
    setValue('professionalLetter', null);
  };

  const handleVerifyInvestorClick = () => {
    const apiKey = process.env.REACT_APP_VERIFY_INVESTOR_EMBEDDED_API_KEY;
    window.verifyInvestor(apiKey, account.id);

    window.document.addEventListener('modalClosed', handleCloseModal, false);
  };

  let accountDescription = '';
  if (account) {
    if (account.type === 'individual')
      accountDescription = getSingleOwnerAccountLabels(investmentAccounts);
    else accountDescription = utils.titalize(account.type);
  }

  return (
    <Container className="container-narrow">
      <h2 className="margin-top-0 margin-x">
        {loading ? <Skeleton /> : account?.entity_name}
      </h2>
      <div className="section-subheading">
        {loading ? <Skeleton /> : accountDescription}
      </div>
      <Card className="margin-top-x">
        <h3 className={`margin-top-0 ${styles.heading}`}>
          Verify Your Accreditation To Invest
        </h3>
        <p>
          The SEC requires that all investors are verified as accredited, before
          making an EquityMultiple investment.
          <Tooltip
            className="info-icon-margin-left"
            data-testid="accreditationInfo"
            infoIcon
            tooltipContent={
              <>
                <strong>Why do we verify accreditation?</strong>
                <div>
                  Recent United States federal laws require us to take
                  "reasonable steps" to verify that the investors in these
                  offerings are all "accredited investors." Failure to properly
                  take these steps could harm the Company as well as its
                  investors. For more information about "accredited investors",
                  please visit:
                  <div>
                    <a
                      href="http://www.sec.gov/answers/accred.html"
                      rel="noreferrer"
                      target="_blank"
                    >
                      http://www.sec.gov/answers/accred.html
                    </a>
                  </div>
                </div>
              </>
            }
          />
        </p>
        {pendingInvestments.length > 0 && (
          <InvestmentsInfo investments={pendingInvestments} />
        )}

        <h4 className="margin-top-xxx">Upload a Professional Letter</h4>
        <p>
          Provide a third-party professional letter whereby either a licensed
          attorney, a CPA, an SEC-registered investment adviser, or a registered
          broker-dealer certifies that you are accredited. The letter must be
          dated within the last 90 days.
        </p>
        <p>
          You can download a professional letter template to fill out below.
        </p>
        <p className="margin-xx">
          <a
            className={styles.downloadLink}
            download="Professional Letter Template"
            href="https://equitymultiple.com/wp-content/uploads/2023/07/accreditation_verification_letter.pdf"
          >
            <i className={`fa fa-download ${styles.downloadIcon}`} />
            Download Professional Letter Template
          </a>
        </p>
        <form
          data-testid="verifyAccreditationForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="professionalLetter"
            render={({ field }) => (
              <FileUploader
                {...setFieldProps(field, errors)}
                acceptedFileTypes={['PDF', 'JPG', 'PNG']}
                data-testid="professionalLetterField"
                maxSize={maxUploadSize}
                onRemove={onRemove}
                showImage
                upload={onUpload}
                uploading={uploading}
              />
            )}
          />

          <FormError errors={errors} />

          <div className="forwardBackButtonWrap">
            <Button loading={uploading} type="submit">
              Upload Letter
            </Button>
            <Link data-testid="backLink" to="/settings/accreditation">
              Back
            </Link>
          </div>
        </form>
      </Card>
      <Card>
        <h6>Don't have a professional letter?</h6>
        <p>
          You can verify your accreditation through various other methods.{' '}
          <button
            className="text-link underline"
            disabled={!account}
            onClick={handleVerifyInvestorClick}
            type="button"
          >
            Click here
          </button>{' '}
          to start a secure process of verifying your accreditation.
        </p>
      </Card>
    </Container>
  );
};

function mapStateToProps(store) {
  return {
    investmentAccounts: store.account.investmentAccounts,
    loading: store.account.loading,
    pendingInvestments: store.account.pendingInvestments,
    uploading: store.account.uploading,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(VerifyAccreditation);
