import { Table, Tooltip } from '@equitymultiple/react-eui';
import { Investment } from 'containers/Portfolio/types';
import moment from 'moment';
import React from 'react';

import utils from '../../../../utilities/utils';
import { tableHeaders, tooltips } from '../../constants';
import * as styles from '../../containers/AccountShared.module.scss';
import { formatExitedPerformanceStatus } from '../../helpers';

interface Props {
  investments: Investment[];
  loading: boolean;
  timePeriod: 'Total' | 'YTD';
}

const getColumnHeaders = () => [
  tableHeaders.investment,
  tableHeaders.performanceStatus,
  tableHeaders.investmentAmount,
  tableHeaders.dateInvested,
  <span className={styles.infoIconWrap} key="exit-date">
    <span className={styles.th}>Exit Date</span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      placement="top"
      tooltipContent={tooltips.exitDate}
    />
  </span>,
  <span className={styles.infoIconWrap} key="return">
    <span className={styles.th}>Return</span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      placement="top"
      tooltipContent={tooltips.return}
    />
  </span>,
  tableHeaders.annualizedReturn,
  ''
];

const getRows = (investments, timePeriod) => {
  if (investments) {
    let totalAmount = 0;
    let totalEarningsPercentPreDivide = 0;
    let totalReturnAnnualizedPreDivide = 0;

    const rows = investments.map(investment => {
      const amount = parseFloat(investment.amount);
      let earningsPercent = parseFloat(investment.earnings_percent);
      const returnAnnualized = parseFloat(investment.xirr);

      if (timePeriod === 'YTD') {
        earningsPercent = parseFloat(investment.ytd_earnings_percent);
      }

      totalAmount += amount;

      totalEarningsPercentPreDivide += amount * earningsPercent;
      totalReturnAnnualizedPreDivide += amount * returnAnnualized;

      return {
        cells: [
          investment.offering.title,
          <span className={styles.infoIconWrap} key="performance-status">
            {formatExitedPerformanceStatus(
              investment.offering.performance_status
            )}
            <Tooltip
              className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
              infoIcon
              placement="top"
              tooltipContent={
                tooltips.exitedPerformanceStatuses[
                  investment.offering.performance_status
                ]
              }
            />
          </span>,
          utils.formatCurrency(amount, 0),
          utils.dateFormat(investment.date_invested),
          {
            sortableValue: moment(investment.offering.matures_on).unix(),
            value: utils.dateFormat(investment.offering.matures_on)
          },
          `${earningsPercent}%`,
          `${investment.xirr}%`,
          <i className="fa fa-chevron-right" key="icon" />
        ],
        className: styles.rowLink,
        link: `/portfolio/account/${investment.investment_account_id}/investment/${investment.id}`
      };
    });

    const totalEarningsPercent = utils.round(
      totalEarningsPercentPreDivide / totalAmount,
      2
    );
    const totalReturnAnnualized = utils.round(
      totalReturnAnnualizedPreDivide / totalAmount,
      2
    );

    rows.push({
      cells: [
        'Total',
        '',
        utils.formatCurrency(totalAmount, 0),
        '',
        '',
        `${totalEarningsPercent}%`,
        `${totalReturnAnnualized}%`,
        ''
      ]
    });

    return rows;
  }

  return [];
};

const ExitedInvestments = ({ investments, loading, timePeriod }: Props) => {
  return (
    <Table
      allowSorting={[0, 4]}
      className={styles.table}
      columnHeaders={getColumnHeaders()}
      loading={loading}
      rows={getRows(investments, timePeriod)}
      totalRow
    />
  );
};

export default ExitedInvestments;
