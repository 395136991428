import { Button, Password } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import SetPasswordFields from 'components/forms/SetPasswordFields/SetPasswordFields';
import { ChangePasswordFormFields } from 'containers/Setting/types';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { updatePassword } from 'redux/actions/user-settings';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { updatePasswordSchema } from '../validation';

interface Props {
  dispatch: Dispatch;
}

const SettingUpdatePasswordForm = ({ dispatch }: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<ChangePasswordFormFields>({
    resolver: yupResolver(updatePasswordSchema)
  });

  const onSubmit: SubmitHandler<ChangePasswordFormFields> = values => {
    return dispatch(updatePassword({ user: values }))
      .then(res => {
        humane.notice(res.message);
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="current_password"
        render={({ field }) => (
          <Password
            {...setFieldProps(field, errors)}
            className="input-fixed-width-wide"
            label="Current Password"
          />
        )}
      />
      <SetPasswordFields<ChangePasswordFormFields>
        changePassword
        control={control}
        errors={errors}
      />
      <FormError errors={errors} />
      <Button
        className="float-right"
        loading={isSubmitting}
        style={{ width: '140px' }}
        type="submit"
      >
        Update
      </Button>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(SettingUpdatePasswordForm);
