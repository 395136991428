import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { User } from 'types/actions/auth';
import { twoFaSetupRequired } from 'utilities/user';

import * as sharedStyles from '../../SettingsShared.module.scss';
import SettingUpdateEmailForm from '../forms/SettingUpdateEmailForm';
import SettingUpdatePasswordForm from '../forms/SettingUpdatePasswordForm';
import TwoFactorAuthentication from '../forms/TwoFactorAuthentication';
import UserDevice from '../forms/UserDevice/UserDevice';

interface Props {
  user: User;
}

const Security = ({ user }: Props) => {
  const twoFaSetupIsRequired = twoFaSetupRequired(user);

  return (
    <div className="margin-top-xxx" data-testid="securityTab">
      <div className="margin-xxx">
        <Row className="margin-xxxx">
          <Col lg={3}>
            <h3 className={sharedStyles.h3}>Two-factor Authentication</h3>
          </Col>
          <Col lg={9}>
            <TwoFactorAuthentication />
          </Col>
        </Row>
      </div>

      {!twoFaSetupIsRequired && (
        <>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Change Your Password</h3>
            </Col>
            <Col lg={9}>
              <SettingUpdatePasswordForm />
            </Col>
          </Row>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Update Email</h3>
            </Col>
            <Col lg={9}>
              <SettingUpdateEmailForm user={user} />
            </Col>
          </Row>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Your Devices</h3>
            </Col>
            <Col lg={9}>
              <UserDevice />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Security);
