import { ErrorLabel } from '@equitymultiple/react-eui';
import React from 'react';
import { FieldErrors } from 'react-hook-form';

interface Props {
  errors: FieldErrors;
}

const FormError = ({ errors }: Props) => {
  let errorMessage = '';

  if (errors?.root?.serverError) {
    errorMessage = errors.root.serverError.message;
  }

  return errorMessage ? (
    <ErrorLabel
      className="margin-xx"
      data-testid="formError"
      message={errorMessage}
    />
  ) : null;
};

export default FormError;
