import warningIcon from 'images/icons/warning.svg?url';
import React from 'react';
import { Link } from 'react-router-dom';
import type { AccreditationAlertData } from 'types/actions/auth';
import utils from 'utilities/utils';

import * as styles from './AccreditationBanner.module.scss';

type Props = {
  alert: AccreditationAlertData;
};

const AccreditationBanner = ({ alert }: Props) => {
  let content = <></>;
  const verifyLink = `/verify_accreditation/${alert.investment_account_reference_id}`;
  const accountName = alert.investment_account_name;

  switch (alert.accreditation_status) {
    case 'verified':
      content = (
        <>
          Your accreditation expires{' '}
          {utils.dateFormat(alert.accreditation_expires_at)}.{' '}
          <Link className="text-link-light underline" to={verifyLink}>
            Verify your accreditation
          </Link>{' '}
          to continue investing with your {accountName} Investment Account.
        </>
      );
      break;
    case 'not_started':
    case 'expired':
    case 'incomplete':
      content = (
        <>
          <Link className="text-link-light underline" to={verifyLink}>
            Verify your accreditation
          </Link>{' '}
          to retain investments made with your {accountName} Investment Account.
        </>
      );
      break;
  }

  return (
    <div data-testid="bannerContent">
      <img
        alt="Accreditation Alert"
        className={styles.warning}
        src={warningIcon}
      />
      {content}
    </div>
  );
};

export default AccreditationBanner;
