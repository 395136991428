import { LoadDocumentsResponse } from 'types/actions/documents';
import { AsyncActionCreator } from 'types/redux';

export const LOAD = 'documents/LOAD';
export const LOAD_SUCCESS = 'documents/LOAD_SUCCESS';
export const LOAD_FAIL = 'documents/LOAD_FAIL';

export function loadDocuments(): AsyncActionCreator<LoadDocumentsResponse> {
  return {
    promise: client => client.get<LoadDocumentsResponse>('/mkt/documents'),
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL]
  };
}
