export const phoneMask = '(999) 999-9999 [ext 999999]';
export const percentageMask = '9[9][.9[9]]%';

const maskedChar = 'X|9';
export const ssn = `${maskedChar}${maskedChar}${maskedChar}-${maskedChar}${maskedChar}-9999`;

// inputMaskOptions required for SSN fields
export const ssnMaskOptions = {
  definitions: {
    X: {
      validator: '[X]'
    }
  }
};

// inputMaskOptions for numbers
export const numberMaskOptions = {
  autoUnmask: true
};

export const percentageMaskNumberOnUnMask = (maskedValue, _, options) => {
  let returnValue = maskedValue;
  returnValue = returnValue.replace('_', '');
  returnValue = returnValue.replace(',', '');
  if (options.prefix) returnValue = returnValue.replace(options.prefix, '');
  if (options.suffix) returnValue = returnValue.replace(options.suffix, '');
  if (returnValue.charAt(returnValue.length - 1) === '.')
    return returnValue || null;
  else return returnValue ? Number(returnValue) : null;
};

export const percentageMaskOptions = {
  autoUnmask: true,
  onUnMask: percentageMaskNumberOnUnMask,
  suffix: '%'
};
