import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Image } from 'containers/Offerings/types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { getImageUrl } from '../../helpers';
import * as styles from './ImageCarousel.module.scss';

interface Props {
  images: Image[];
}

const ImageCarousel = ({ images }: Props) => {
  return (
    <Carousel className={styles.carousel} emulateTouch showThumbs={false}>
      {images.map(image => {
        const imageUrl = getImageUrl(image);
        return (
          <div className={styles.slideInner} key={image.id}>
            <img
              alt={image.description}
              src={imageUrl}
              width={image.custom_width}
            />
            {!image.description_hidden && (
              <div className={styles.caption}>{image.description}</div>
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default ImageCarousel;
