import React from 'react';

import RichText from '../../../../components/RichText';
import * as styles from './Footnotes.module.scss';

interface Props {
  footnotes: string;
}

const Footnotes = ({ footnotes }: Props) => {
  return (
    <div className={styles.footnotes}>
      <RichText sanitize text={footnotes} />
    </div>
  );
};

export default Footnotes;
