import { Button, Input, Select } from '@equitymultiple/react-eui';
import { stateOptions } from 'containers/Accounts/contents';
import { AddressFormFields } from 'containers/UserUpdateAlerts/types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors, Path } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';

interface Props {
  control: Control<AddressFormFields>;
  errors: FieldErrors<AddressFormFields>;
  formName: 'investment_account' | 'user_address_verification.address';
  previousWizard: () => void;
}

const AddressForm = ({ control, errors, formName, previousWizard }: Props) => {
  return (
    <div>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name={(formName + '.address') as Path<AddressFormFields>}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name={(formName + '.address2') as Path<AddressFormFields>}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2"
                placeholder="Apartment number, etc."
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={4}>
          <Controller
            control={control}
            name={(formName + '.city') as Path<AddressFormFields>}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col className="overflow-visible" md={4}>
          <Controller
            control={control}
            name={
              (formName +
                (formName === 'investment_account'
                  ? '.region'
                  : '.state')) as Path<AddressFormFields>
            }
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name={(formName + '.postal_code') as Path<AddressFormFields>}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="99999"
              />
            )}
          />
        </Col>
      </Row>
      <div className="forwardBackButtonWrap">
        <Button style={{ width: 240 }} type="submit">
          Save Changes
        </Button>
        <button className="text-link" onClick={previousWizard} type="button">
          Back
        </button>
      </div>
    </div>
  );
};

export default AddressForm;
