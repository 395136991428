import React, { useEffect, useState } from 'react';

import * as styles from './AmountSelect.module.scss';

interface Props {
  amountChange: (value: number) => void;
  defaultValue: string;
  minAmount: number;
  name: string;
}

const AmountSelect = ({
  amountChange,
  defaultValue,
  minAmount,
  name
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const minimumValue = Math.floor(minAmount);
    let selectValues = [
      minimumValue,
      5000,
      10000,
      20000,
      50000,
      100000,
      250000
    ].filter(val => val >= minimumValue);
    selectValues = [...new Set(selectValues)];
    const initVal = defaultValue || selectValues[0];

    setSelectedValue(initVal);
    setValues(selectValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    const val = e.target.value;
    amountChange(val);
    setSelectedValue(val);
  };

  return (
    <div className={styles.cashflowSelect}>
      <select
        className={styles.cashflowAmount}
        name={name}
        onChange={handleChange}
        value={selectedValue || ''}
      >
        {values.map(val => {
          const valFormatted = val > 999 ? (val / 1000).toFixed() + 'k' : val;
          return (
            <option key={val} value={val}>
              {valFormatted}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default AmountSelect;
