import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { setAdBlockerModalContent } from '../../../redux/actions/modals';
import { Dispatch } from '../../../types/redux';
import * as styles from './AdBlockerModal.module.scss';

interface Props {
  content: string;
  dispatch: Dispatch;
}

const AdBlockerModal = ({ content, dispatch }: Props) => {
  const closeModal = () => dispatch(setAdBlockerModalContent(''));

  return (
    <Modal
      data-testid="adBlockerModal"
      onClose={closeModal}
      open
      submitButton={
        <Button className={styles.button} onClick={closeModal} type="button">
          Okay
        </Button>
      }
      title={<h6>Ad blocking plug-in detected</h6>}
    >
      {content}
    </Modal>
  );
};

export default AdBlockerModal;
