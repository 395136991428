import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

type Params = {
  investment_id: string;
};

const PaymentActivityRedirect = () => {
  const { investment_id: investmentId } = useParams<Params>();
  return (
    <Navigate replace to={`/investments/payment_activity/${investmentId}`} />
  );
};

export default PaymentActivityRedirect;
