import React from 'react';
import { NavLink } from 'react-router-dom';

import * as styles from './TabularMenu.module.scss';

interface Props {
  tabs: string[];
  twoFaSetupRequired: boolean;
}

const TabularMenu = ({ tabs, twoFaSetupRequired }: Props) => {
  return (
    <div className={styles.menuContainer}>
      <ul className={styles.tabsMenu}>
        {tabs.map(tab => {
          const tabFormatted = tab.toLowerCase().replace(' ', '_');
          return (
            <li key={tabFormatted}>
              <NavLink
                className={
                  twoFaSetupRequired && tab !== 'Security'
                    ? styles.disabled
                    : ''
                }
                to={`/settings/${tabFormatted}`}
              >
                {tab}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TabularMenu;
