import { Image } from 'containers/Offerings/types';
import React, { useState } from 'react';
import { Container } from 'react-grid-system';

import * as styles from './ImageGallery.module.scss';

interface Props {
  images: Image[];
}

const ImageGallery = ({ images }: Props) => {
  const [activeImage, setActiveImage] = useState(null);

  const viewFullImage = index => {
    setActiveImage(index);
  };

  const handleBackClick = () => {
    setActiveImage(null);
  };

  return (
    <div>
      <Container>
        <div className={styles.galleryContent}>
          <button
            className={`${styles.back} ${
              activeImage !== null ? '' : styles.invisible
            }`}
            onClick={handleBackClick}
            type="button"
          >
            <i className="fa fa-arrow-left" />
            back to gallery
          </button>
          {images?.map((image, index) => {
            if (activeImage === null || activeImage === index) {
              // Hero component images don't have thumb size or an alt tag, hence the checks
              const imageLarge = image.versions.large;
              const imageMedium = image.versions.medium;
              const imageSmall = image.versions.thumb || imageMedium;
              const imageFull =
                window.innerWidth > 600 ? imageLarge : imageMedium;

              return (
                <button
                  className={`${styles.image} ${
                    activeImage === index ? styles.fullSize : ''
                  }`}
                  key={image.id}
                  onClick={() => viewFullImage(index)}
                  type="button"
                >
                  <img
                    alt={image.description || ''}
                    src={activeImage === index ? imageFull : imageSmall}
                  />
                </button>
              );
            }
            return null;
          })}
        </div>
      </Container>
    </div>
  );
};

export default ImageGallery;
