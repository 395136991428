import { Card } from '@equitymultiple/react-eui';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import utils from '../../../../utilities/utils';
import * as styles from './EarningsTable.module.scss';

interface Props {
  earnings: {
    current_year: number;
    total: number;
  };
  loading: boolean;
}

const getPriorYearsValue = earnings =>
  utils.formatCurrency(
    earnings?.total ? earnings.total - earnings.current_year : 0
  );

const EarningsTable = ({ earnings, loading }: Props) => {
  return (
    <Card className={styles.earningsTable}>
      <div className={styles.flex}>
        <div className={styles.flexTop}>
          <h3 className={styles.cardHeading}>Earnings</h3>
          <div className={styles.earningsRow}>
            <div>Current Year</div>
            {loading ? (
              <Skeleton />
            ) : (
              <div>{utils.formatCurrency(earnings?.current_year || 0)}</div>
            )}
          </div>
          <div className={styles.earningsRow}>
            <div>Prior Years</div>
            {loading ? <Skeleton /> : <div>{getPriorYearsValue(earnings)}</div>}
          </div>
        </div>
        <div className={`${styles.earningsRow} ${styles.totalRow}`}>
          <div>Total</div>
          {loading ? (
            <Skeleton />
          ) : (
            <div>{utils.formatCurrency(earnings?.total || 0)}</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default EarningsTable;
