import { Button, Input, Modal } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { emailInvitation } from 'redux/actions/invite';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import emailInviteModalSchema from './validation';

type FormFields = {
  email: string;
};

interface Props {
  dispatch: Dispatch;
  hideEmailInviteModal: () => void;
  showEmailModal: boolean;
}

const EmailInviteModal = ({
  dispatch,
  hideEmailInviteModal,
  showEmailModal
}: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(emailInviteModalSchema)
  });

  const onSubmit = values => {
    const formData = {
      user: {
        email: values.email
      }
    };

    return dispatch(emailInvitation(formData))
      .then(res => {
        humane.notice('Successfully delivered invitation mail to ' + res.email);
        hideEmailInviteModal();
      })
      .catch(err => throwReactHookFormSubmissionErrors(err, setError));
  };

  return (
    <Modal
      onClose={hideEmailInviteModal}
      open={showEmailModal}
      title={<h6>Invite via Email</h6>}
    >
      <form
        className="margin-xxx margin-top-xxx"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              {...setFieldProps(field, errors)}
              label="Friend's Email Address"
              placeholder="email@example.com"
            />
          )}
        />

        <FormError errors={errors} />

        <Button
          className="pull-left"
          loading={isSubmitting}
          style={{ width: '140px' }}
          type="submit"
        >
          Invite
        </Button>
      </form>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect()(EmailInviteModal);
