import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { updateUsername } from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { updateEmailSchema } from '../validation';

interface FormFields {
  unconfirmed_email: string;
}

interface Props {
  dispatch: Dispatch;
  user: User;
}

const SettingUpdateEmailForm = ({ dispatch, user }: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(updateEmailSchema)
  });

  const onSubmit: SubmitHandler<FormFields> = values => {
    return dispatch(updateUsername({ user: values }))
      .then(res => {
        humane.notice(res.message);
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const unconfirmedEmail = user?.unconfirmed_email;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {unconfirmedEmail ? (
        <div>
          <p>
            Your request to update your email is registered. Confirmation link
            has been sent to your new email {unconfirmedEmail}. Kindly confirm
            your new email to update.
          </p>
          <p>
            Press resend button to send the confirmation instructions again.
          </p>
        </div>
      ) : (
        <p>
          <strong className="text-blue">Note: </strong> You have to confirm your
          new email.
        </p>
      )}
      <Controller
        control={control}
        name="unconfirmed_email"
        render={({ field }) => (
          <Input
            {...setFieldProps(field, errors)}
            className="input-fixed-width-wide"
            label="Email"
            placeholder="Enter Your New Email"
          />
        )}
      />
      <FormError errors={errors} />
      <Button
        className="float-right"
        loading={isSubmitting}
        name="submit"
        style={{ width: '140px' }}
        type="submit"
      >
        {unconfirmedEmail ? 'Resend' : 'Update'}
      </Button>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(SettingUpdateEmailForm);
