export const LOAD_ALL = 'offering/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'offering/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'offering/LOAD_ALL_FAIL';

export const FETCH_OFFERING = 'offering/FETCH_OFFERING';
export const FETCH_OFFERING_SUCCESS = 'offering/FETCH_OFFERING_SUCCESS';
export const FETCH_OFFERING_FAIL = 'offering/FETCH_OFFERING_FAIL';

export const LOAD_OFFERING = 'offerings/LOAD_OFFERING';
export const LOAD_OFFERING_SUCCESS = 'offerings/LOAD_OFFERING_SUCCESS';
export const LOAD_OFFERING_FAIL = 'offerings/LOAD_OFFERING_FAIL';

export const LOAD_CLOSING = 'closings/LOAD_CLOSING';
export const LOAD_CLOSING_SUCCESS = 'closings/LOAD_CLOSING_SUCCESS';
export const LOAD_CLOSING_FAIL = 'closings/LOAD_CLOSING_FAIL';

export const FETCH_AGREEMENT = 'offering/FETCH_AGREEMENT';
export const FETCH_AGREEMENT_SUCCESS = 'offering/FETCH_AGREEMENT_SUCCESS';
export const FETCH_AGREEMENT_FAIL = 'offering/FETCH_AGREEMENT_FAIL';

export const SUBMIT_AGREEMENT = 'offering/SUBMIT_AGREEMENT';
export const SUBMIT_AGREEMENT_SUCCESS = 'offering/SUBMIT_AGREEMENT_SUCCESS';
export const SUBMIT_AGREEMENT_FAIL = 'offering/SUBMIT_AGREEMENT_FAIL';

export const LOAD_OFFERING_INVESTMENTS = 'offerings/LOAD_OFFERING_INVESTMENTS';
export const LOAD_OFFERING_INVESTMENTS_SUCCESS =
  'offerings/LOAD_OFFERING_INVESTMENTS_SUCCESS';
export const LOAD_OFFERING_INVESTMENTS_FAIL =
  'offerings/LOAD_OFFERING_INVESTMENTS_FAIL';

export const LOAD_DISTRIBUTION_REINVESTMENT =
  'offerings/LOAD_DISTRIBUTION_REINVESTMENT';
export const LOAD_DISTRIBUTION_REINVESTMENT_SUCCESS =
  'offerings/LOAD_DISTRIBUTION_REINVESTMENT_SUCCESS';
export const LOAD_DISTRIBUTION_REINVESTMENT_FAIL =
  'offerings/LOAD_DISTRIBUTION_REINVESTMENT_FAIL';

export const UPDATE_DISTRIBUTION_REINVESTMENT =
  'offerings/UPDATE_DISTRIBUTION_REINVESTMENT';
export const UPDATE_DISTRIBUTION_REINVESTMENT_SUCCESS =
  'offerings/UPDATE_DISTRIBUTION_REINVESTMENT_SUCCESS';
export const UPDATE_DISTRIBUTION_REINVESTMENT_FAIL =
  'offerings/UPDATE_DISTRIBUTION_REINVESTMENT_FAIL';

export function loadOffering(offeringId) {
  return {
    promise: client => client.get(`/mkt/offerings/${offeringId}`),
    types: [LOAD_OFFERING, LOAD_OFFERING_SUCCESS, LOAD_OFFERING_FAIL]
  };
}

export function loadClosing(closingId) {
  return {
    promise: client => client.get(`/mkt/closings/${closingId}`),
    types: [LOAD_CLOSING, LOAD_CLOSING_SUCCESS, LOAD_CLOSING_FAIL]
  };
}

export function loadPostOfferings(searchString) {
  return {
    promise: client => client.get(`/mkt/posts/offerings?${searchString}`),
    types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL]
  };
}

export function loadOfferingWithComponents(offeringId, closingId) {
  return {
    promise: client =>
      client.get(
        `/mkt/offerings/${offeringId}/components?closing_id=${closingId}`
      ),
    types: [FETCH_OFFERING, FETCH_OFFERING_SUCCESS, FETCH_OFFERING_FAIL]
  };
}

export function confidentialityAgreementAccepted(offeringId: string) {
  return {
    promise: client =>
      client.get(`/mkt/offerings/${offeringId}/confidentiality_agreement`),
    types: [FETCH_AGREEMENT, FETCH_AGREEMENT_SUCCESS, FETCH_AGREEMENT_FAIL]
  };
}

export function agreeConfidentialityAgreement(offeringId, data) {
  return {
    promise: client =>
      client.post(`/mkt/offerings/${offeringId}/accept_confidentiality`, {
        data
      }),
    types: [SUBMIT_AGREEMENT, SUBMIT_AGREEMENT_SUCCESS, SUBMIT_AGREEMENT_FAIL]
  };
}

export function loadInvestmentsInOffering(offeringId) {
  return {
    promise: client => client.get(`/mkt/offerings/${offeringId}/investments`),
    types: [
      LOAD_OFFERING_INVESTMENTS,
      LOAD_OFFERING_INVESTMENTS_SUCCESS,
      LOAD_OFFERING_INVESTMENTS_FAIL
    ]
  };
}

export function loadDistributionReinvestmentInOffering(offeringId) {
  return {
    promise: client =>
      client.get(`/mkt/offerings/${offeringId}/distribution_reinvestment`),
    types: [
      LOAD_DISTRIBUTION_REINVESTMENT,
      LOAD_DISTRIBUTION_REINVESTMENT_SUCCESS,
      LOAD_DISTRIBUTION_REINVESTMENT_FAIL
    ]
  };
}

export function updateDistributionReinvestmentInOffering(offeringId, data) {
  return {
    promise: client =>
      client.put(
        `/mkt/offerings/${offeringId}/update_distribution_reinvestment`,
        {
          data
        }
      ),
    types: [
      UPDATE_DISTRIBUTION_REINVESTMENT,
      UPDATE_DISTRIBUTION_REINVESTMENT_SUCCESS,
      UPDATE_DISTRIBUTION_REINVESTMENT_FAIL
    ]
  };
}
