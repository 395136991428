import { ToggleableTooltip } from '@equitymultiple/react-eui';
import { NavComponent } from 'containers/Offerings/types';
import React, { useState } from 'react';

import { getComponentId } from '../../helpers';
import * as styles from './MobileSectionNav.module.scss';

interface Props {
  navComponents: NavComponent[];
}

const MobileSectionNav = ({ navComponents }: Props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleLinkClick = (id, index) => {
    setActiveIndex(index);
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        behavior: 'smooth',
        top: sectionTop - 30
      });
    }
  };

  return (
    <ToggleableTooltip
      className={styles.tooltipWrap}
      placement="top"
      tooltipBoxClassName={styles.tooltip}
      triggerText={
        <div className={styles.navToggle}>
          <div className={styles.iconBar}>
            <span />
            <span />
            <span />
          </div>
          <span className={styles.toggleText}>Sections</span>
        </div>
      }
    >
      {navComponents?.map((navItem, index) => (
        <div className={styles.navItem} key={navItem.id}>
          <button
            className={activeIndex === index ? 'active' : ''}
            key={navItem.id}
            onClick={() => {
              handleLinkClick(getComponentId(navItem), index);
            }}
            type="button"
          >
            {navItem.title}
          </button>
        </div>
      ))}
    </ToggleableTooltip>
  );
};

export default MobileSectionNav;
