import { Checkbox } from '@equitymultiple/react-eui';
import iconInfo from 'images/icons/info.svg?url';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { OfferingDocument } from 'types/api/offering';
import { setCheckboxFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';

import { SignFormValues } from '../../types';
import DocumentDownload from '../DocumentDownload/DocumentDownload';
import Signature from '../Signature/Signature';
import * as styles from './AcknowledgeAndSign.module.scss';

interface Props<T extends FieldValues> {
  control: Control<T>;
  documentsNotDownloaded: boolean;
  errors: FieldErrors<T>;
  formValues: SignFormValues;
  isSubmitted: boolean;
  offeringId: number;
  setDownloadedField: (documentIndex: number) => void;
  signRequiredDocuments: OfferingDocument[];
}

export const AcknowledgeAndSign = <T extends FieldValues>({
  control,
  documentsNotDownloaded,
  errors,
  formValues,
  isSubmitted,
  offeringId,
  setDownloadedField,
  signRequiredDocuments
}: Props<T>) => {
  let documentNamesString = '';
  if (signRequiredDocuments) {
    const documentNames = signRequiredDocuments.map(document => document.name);
    documentNamesString = utils.arrayToCommaSeparatedString(documentNames);
  }

  const showDocumentError = isSubmitted && documentsNotDownloaded;

  return (
    <>
      <h4>Acknowledge & Sign</h4>
      <div className="margin-x">
        <Controller
          control={control}
          name={'pledge1' as Path<T>}
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              className="margin-xxx"
              label={
                <>
                  I am an accredited investor according to{' '}
                  <a
                    className="underline"
                    href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Rule 501
                  </a>
                  , and hereby confirm that all representations previously made
                  during my account registration process with respect to my
                  Accredited Investor status remain true, accurate and correct.
                </>
              }
            />
          )}
        />
      </div>
      <p className={styles.documentInstructions}>
        <img
          alt=""
          className={showDocumentError ? styles.documentError : ''}
          src={iconInfo}
        />
        Attached documents must be downloaded
      </p>
      <div className="margin-xx">
        {signRequiredDocuments?.map((document, index) => (
          <DocumentDownload
            document={document}
            documentIndex={index}
            downloaded={formValues?.documents?.[`doc${index}`]}
            key={document.name}
            offeringId={offeringId}
            setDownloadedField={setDownloadedField}
            showErrors={isSubmitted}
          />
        ))}
      </div>
      <div className="margin-x">
        <Controller
          control={control}
          name={'pledge2' as Path<T>}
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              className="margin-xxx"
              disabled={documentsNotDownloaded}
              label={`I have read and agree to the terms of the ${documentNamesString} attached thereto.`}
            />
          )}
        />
      </div>
      <div className="margin-x">
        <Controller
          control={control}
          name={'pledge3' as Path<T>}
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              className="margin-xxx"
              disabled={!formValues?.pledge2}
              label="I have read and understood the provided documents in detail, including without limitation, all risk factors and conflicts of interest set forth therein."
            />
          )}
        />
      </div>
      <p className="margin-xxx">
        By my signature below, I hereby execute the Subscription Agreement,
        certify that I have read and agree to the{' '}
        <a
          className="underline"
          href="https://equitymultiple.com/terms"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Service
        </a>
        .
      </p>

      <h4>Sign and choose the style</h4>
      <Signature control={control} errors={errors} formValues={formValues} />
    </>
  );
};
