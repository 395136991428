import React from 'react';
import { Col, Row } from 'react-grid-system';
import { CreatedInvitation } from 'types/actions/invite';

import * as styles from './CreatedInvites.module.scss';

type Props = {
  created: CreatedInvitation[];
};

const CreatedInvites = ({ created }: Props) => {
  const createdInvitation = created.map(invitationDetails => (
    <Row key={invitationDetails.id}>
      <Col md={1} sm={2}>
        <div className={`${styles.createdInviteImg} text-center`}>
          <span>
            {invitationDetails.first_name
              ? invitationDetails.first_name[0] + invitationDetails.last_name[0]
              : invitationDetails.email[0]}
          </span>
        </div>
      </Col>

      <Col sm={6}>
        <p>
          {invitationDetails.first_name
            ? `${invitationDetails.first_name} ${invitationDetails.last_name}`
            : invitationDetails.email}
        </p>
        {invitationDetails.is_investing ? (
          <p className={styles.smallText}>
            {' '}
            Invested on {invitationDetails.invested_on}{' '}
          </p>
        ) : (
          <p className={styles.smallText}> Not yet invested. </p>
        )}
      </Col>

      {invitationDetails.is_investing && (
        <Col md={5} sm={4}>
          <p className={`${styles.smallText} ${styles.investing}`}>
            <div className={styles.labelGreen}> INVESTING </div>
          </p>
        </Col>
      )}
    </Row>
  ));

  return (
    <section className={styles.inviteMetaDetails}>
      <Row>
        <Col xs={12}>
          <div className={styles.metaBlock}>
            <p className={styles.metaTitle}>
              {' '}
              Created - {created.length} of my invites
            </p>
          </div>
        </Col>
      </Row>

      {created.length > 0 ? <div>{createdInvitation}</div> : 'No Created User'}
    </section>
  );
};

export default CreatedInvites;
