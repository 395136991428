import { Button } from '@equitymultiple/react-eui';
import phoneHex from 'images/signup-phone-hex.png';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EmAnalytics from 'utilities/em_analytics';

import RequireLoginWrap from '../components/RequireLoginWrap';
import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import * as styles from './Continue.module.scss';

interface Props {
  firstName: string;
}

const Continue = ({ firstName }: Props) => {
  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';
  }, []);

  return (
    <RequireLoginWrap>
      {firstName && (
        <SignUpWrap
          introBackgroundColor="gray"
          introContent={
            <>
              <img alt="EquityMultiple" className={styles.hex} src={phoneHex} />
              <h1>
                You're in
                <br /> Good Company.
              </h1>
              <p>
                55,000 likeminded investors and counting.
                <br /> Onward and upward.
              </p>
            </>
          }
          step={5}
        >
          <h3 className="margin-xx">Thanks for signing up, {firstName}!</h3>
          <p className="margin-xx">
            Ready to choose your first investment? Start now by tailoring your
            investment profile, this typically only takes about 10 minutes.
          </p>

          <Button
            className={`fitContent ${styles.button} ${styles.completeButton}`}
            onClick={() => EmAnalytics.track('Continue Signup', 'Onboarding')}
            variant="orange"
            wrapper={<Link to="/users/signup/phone" />}
          >
            Complete Your Account
          </Button>

          <p className="margin-xx">Start exploring our platform:</p>

          <Button
            className={`fitContent ${styles.button}`}
            variant="outlined"
            wrapper={<Link to="/" />}
          >
            View Investments
          </Button>
        </SignUpWrap>
      )}
    </RequireLoginWrap>
  );
};

function mapStateToProps(store) {
  return {
    firstName: store.auth.user?.first_name
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Continue);
