import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getAllUserCapitalCalls } from 'redux/actions/capital-call';
import type { User } from 'types/actions/auth';
import { GetAllUserCapitalCallsResponse } from 'types/actions/capital-call';
import type { Dispatch } from 'types/redux';

import * as styles from './Banner.module.scss';
import {
  AccreditationBanner,
  CapitalCallBanner,
  TaxTrackerBanner
} from './Banners';

type Props = {
  capitalCalls: GetAllUserCapitalCallsResponse;
  dispatch: Dispatch;
  loading: boolean;
  location: string;
  user: User;
};
const Banner = ({ capitalCalls, dispatch, loading, location, user }: Props) => {
  const prevLocationRef = useRef(location);

  const alerts = user.alerts;

  // Check for active banner in order of priority
  const getActiveBanner = () => {
    // Accreditation
    if (alerts?.some(alert => alert.type === 'accreditation_verification'))
      return 'accreditation';

    // Capital Call
    const capitalCallActive = capitalCalls?.some(capitalCall => {
      const optIn = capitalCall.investment?.capital_call_opt_in;
      // Show if:
      // User has not opted in our out of the capital call
      // User has opted in but not completed funding
      return optIn === null || (optIn && capitalCall.status !== 'cancelled');
    });
    if (capitalCallActive) return 'capitalCall';

    // Tax Tracker
    const taxTrackerActive = user?.tax_tracker_enabled;
    if (taxTrackerActive) return 'taxTracker';

    // No active banner
    return null;
  };

  const shouldBannerBeShownOnCurrentRoute = banner => {
    switch (banner) {
      case 'accreditation':
        // Shown if not on the settings or manual accreditation pages
        return (
          location.indexOf('/settings') === -1 &&
          location.indexOf('/verify_accreditation') === -1
        );

      case 'capitalCall':
        // Shown if not on a capital call route
        return (
          location.indexOf('/capital_call') === -1 &&
          location.indexOf('/fund') === -1 &&
          location.indexOf('/confirm') === -1
        );

      case 'taxTracker':
        // Shown if not on the tax tracker page
        return location.indexOf('/tax_tracker') === -1;
    }

    return false;
  };

  const getUserCapitalCalls = () => {
    dispatch(getAllUserCapitalCalls());
  };

  useEffect(() => {
    if (user.investor_profile.stage === 'multiply') getUserCapitalCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prevLocation = prevLocationRef.current;

    if (
      prevLocation !== location &&
      user.investor_profile.stage === 'multiply'
    ) {
      // When the page changes and the previous page was a capital call or fund route,
      // get capital call data again in case something has changed which would affect the display of the banner
      // e.g. user funding or opting out
      const prevRouteWasCapitalCall =
        prevLocation.indexOf('/capital_call') > -1 ||
        prevLocation.indexOf('/fund') > -1;
      if (prevRouteWasCapitalCall) getUserCapitalCalls();
    }

    prevLocationRef.current = location;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getBanner = () => {
    if (loading) return null;

    // Determine what banner, if any, the user should be seeing
    const activeBanner = getActiveBanner();

    if (activeBanner) {
      // Determine whether the user should see the active banner on their current route
      const showBanner = shouldBannerBeShownOnCurrentRoute(activeBanner);
      let accreditationAlert;

      if (showBanner) {
        switch (activeBanner) {
          case 'accreditation':
            accreditationAlert = alerts.find(
              alert => alert.type === 'accreditation_verification'
            );
            return <AccreditationBanner alert={accreditationAlert.data} />;

          case 'capitalCall':
            return <CapitalCallBanner capitalCalls={capitalCalls} />;
          case 'taxTracker':
            return <TaxTrackerBanner />;
        }
      }

      return null;
    }

    return null;
  };

  const banner = getBanner();

  return (
    banner && (
      <div className={styles.headerBanner}>
        <div className={styles.bannerText}>{banner}</div>
      </div>
    )
  );
};

function mapStateToProps(store) {
  return {
    capitalCalls: store.capitalCall.capitalCalls,
    loading: store.capitalCall.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Banner);
