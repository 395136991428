import done from 'images/done.svg?url';
import moment from 'moment-timezone';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelInvestment, loadInvestment } from 'redux/actions/investments';
import { CapitalCall } from 'types/actions/capital-call';
import { LoadInvestmentResponse } from 'types/actions/investments';
import { LoadClosingResponse } from 'types/actions/offerings';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import humane from 'utilities/humane';
import utils from 'utilities/utils';

import CapitalCallTimer from '../../containers/CapitalCall/CapitalCallTimer/CapitalCallTimer';
import * as styles from './InvestmentStatus.module.scss';

const LoadingSkeleton = () => (
  <>
    <Skeleton style={{ marginBottom: 15 }} width={130} />
    <Skeleton style={{ marginBottom: 15 }} width={80} />
    <Skeleton style={{ marginBottom: 15 }} width={85} />
  </>
);

interface Props {
  cancelPage?: boolean;
  capitalCallTransaction?: CapitalCall;
  closing: LoadClosingResponse['closing'];
  dateInvested?: string;
  dispatch: Dispatch;
  editingInterestPage?: boolean;
  hideCancelLink?: boolean;
  investment: LoadInvestmentResponse;
  loading?: boolean;
  noTimer?: boolean;
  step: number;
}

const InvestmentStatus = ({
  cancelPage,
  capitalCallTransaction,
  closing,
  dateInvested,
  dispatch,
  editingInterestPage,
  hideCancelLink,
  investment,
  loading,
  noTimer,
  step
}: Props) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(`/invest/${closing.id}/investment/${investment.id}/cancel`);
  };

  const returnToReviewStep = () => {
    humane.notice('You have successfully cancelled your investment increase');
    navigate(`/invest/${closing.id}/investment/${investment.id}/confirm`);
  };

  const handleInvestmentIncreaseCancel = () => {
    dispatch(cancelInvestment(investment.id))
      .then(() => {
        dispatch(loadInvestment(investment.id))
          .then(() => returnToReviewStep())
          .catch(error => handleErrorResponse(navigate, error));
      })
      .catch(error => handleErrorResponse(navigate, error));
  };

  const capitalCall = !!capitalCallTransaction;
  let investmentAmount = null;
  if (capitalCall && capitalCallTransaction.amount)
    investmentAmount = parseFloat(capitalCallTransaction.amount);
  else if (investment?.amount) investmentAmount = investment.amount;

  let maintainAmount = investmentAmount;

  if (step === 3 && investment?.transaction_status === 'failed') {
    return (
      <div className={styles.statusNav}>
        <div className={styles.navTitle}>
          {capitalCall ? 'Capital Call Status' : 'Investment Status'}
        </div>
        <div className={`${styles.step} ${styles.active}`}>Update Payment</div>
        <div className={styles.stepDescription}>
          Complete funding to finalize your investment. Funds must be received
          and cleared no later than
          <br />
          {capitalCall
            ? utils.dateFormat(capitalCallTransaction.effective_date)
            : utils.dateFormat(closing?.funding_end_date)}
          .
        </div>
      </div>
    );
  }

  // We must not cancel an investment with an indication of additional interest (ie - investment_increase is true)
  // If a user's investment status is 'signed', or they just signed the agreement, don't allow cancelling
  const canCancel =
    !editingInterestPage &&
    !hideCancelLink &&
    !cancelPage &&
    investment?.can_cancel &&
    !investment.investment_increase &&
    !capitalCall;

  // We should reset to the prior investment state
  const hasIncreasedInvestment =
    !cancelPage && investment?.investment_increase && !capitalCall;

  let investmentIncreaseAmount;
  if (hasIncreasedInvestment && investment.transactions) {
    const fundedAmount = investment.transactions.contributions.reduce(
      (total, transaction) => total + parseInt(transaction.amount),
      0
    );
    const refundedAmount =
      investment.transactions?.refunds?.reduce(
        (total, transaction) => total + parseInt(transaction.amount),
        0
      ) || 0;
    investmentIncreaseAmount =
      parseInt(investment.amount) - (fundedAmount - refundedAmount);

    if (investmentIncreaseAmount === 0) {
      const pendingTransactions = investment.transactions.contributions.filter(
        (transaction, index) =>
          index !== 0 &&
          transaction.status !== 'posted' &&
          transaction.type !== 'Origination Fee'
      );
      investmentIncreaseAmount = parseInt(
        pendingTransactions.at(-1)?.amount || '0'
      );
    }
    maintainAmount = investmentAmount - investmentIncreaseAmount;
  }

  const step2Complete = capitalCall
    ? step > 2
    : ['Complete', 'In Progress'].includes(investment?.documentation_status) &&
      !editingInterestPage &&
      !cancelPage;

  // Deadline is 10 days before the transaction's effective_date
  const capitalCallFundingDeadline = capitalCall
    ? moment(capitalCallTransaction.effective_date).subtract(10, 'days')
    : null;

  return (
    <div className={styles.statusNav}>
      <div className={styles.navTitle}>
        {capitalCall ? 'Capital Call Status' : 'Investment Status'}
      </div>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div className={`${styles.step} ${step === 1 ? styles.active : ''}`}>
            <span>
              1.{' '}
              {capitalCall ? (
                <>
                  Capital Call
                  <div className="size-12">
                    (Original Investment on {utils.dateFormat(dateInvested)})
                  </div>
                </>
              ) : (
                'Indicate Interest'
              )}
              {step > 1 && investment && investmentAmount && (
                <span> - {utils.formatCurrency(investmentAmount)}</span>
              )}
            </span>
            {step > 1 && <img alt="Completed Step" src={done} />}
          </div>
          {step === 1 && !capitalCall && (
            <div className={styles.stepDescription}>
              Indicate interest to get started. This does not reserve your
              position in the offering.
            </div>
          )}
          <div className={`${styles.step} ${step === 2 ? styles.active : ''}`}>
            <span>
              2. {capitalCall ? 'Additional Allocation' : 'Sign'}
              {step > 2 && !capitalCall && <span> - Doc signed</span>}
            </span>
            {step2Complete && <img alt="Completed Step" src={done} />}
          </div>
          {step === 2 && !step2Complete && !capitalCall && (
            <div className={styles.stepDescription}>
              Completing these steps will secure your place in the investment.
            </div>
          )}
          <div className={`${styles.step} ${step === 3 ? styles.active : ''}`}>
            <span>
              3. Fund
              {step > 3 && (
                <span> - {utils.titalize(investment.transaction_status)}</span>
              )}
            </span>
            {step > 3 && <img alt="Completed Step" src={done} />}
          </div>
          {step === 3 && (
            <div className={styles.stepDescription}>
              Complete funding to finalize your investment. Funds must be
              received and cleared no later than <br />
              {capitalCall
                ? utils.dateFormat(capitalCallFundingDeadline)
                : utils.dateFormat(closing?.funding_end_date)}
              .
            </div>
          )}
          {canCancel && (
            <button
              className={`text-link ${styles.cancelInvestment}`}
              onClick={handleCancel}
              type="button"
            >
              Cancel Investment
            </button>
          )}
          {(hasIncreasedInvestment || editingInterestPage) && (
            <>
              <button
                className={`text-link ${styles.cancelInvestment}`}
                onClick={
                  hasIncreasedInvestment
                    ? handleInvestmentIncreaseCancel
                    : returnToReviewStep
                }
                type="button"
              >
                {hasIncreasedInvestment
                  ? investmentIncreaseAmount >= 0
                    ? `Cancel investment increase of ${utils.formatCurrency(
                        investmentIncreaseAmount
                      )}`
                    : `Cancel investment decrease of ${utils.formatCurrency(
                        investmentIncreaseAmount * -1
                      )}`
                  : 'Cancel Investment Increase'}
              </button>
              {investment && (
                <span>
                  (Maintain your {utils.formatCurrency(maintainAmount)}{' '}
                  investment)
                </span>
              )}
            </>
          )}
          {capitalCall && step === 1 && !noTimer && (
            <CapitalCallTimer deadline={capitalCallFundingDeadline} />
          )}
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sending: state.investments.sending
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(InvestmentStatus);
