import {
  Alert,
  Button,
  Checkbox,
  Input,
  Password
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Captcha } from 'components/formControls';
import FormError from 'components/FormError/FormError';
import GoogleButton from 'components/GoogleButton/GoogleButton';
import OfferingSignUp from 'components/OfferingSignUp/OfferingSignUp';
import logo from 'images/logos/full-logo-blue.svg?url';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createSignin, fetchSignin } from 'redux/actions/auth';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { setCheckboxFieldProps, setFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as styles from './SignIn.module.scss';
import signInSchema from './validation';

interface FormFields {
  captcha_required?: boolean;
  captcha_response?: string;
  email?: string;
  otp_attempt?: string;
  otp_required?: boolean;
  password?: string;
  remember_device?: boolean;
  remember_email?: boolean;
}

interface Props {
  dispatch: Dispatch;
  googleLoginUrl: string;
}

const SignIn = ({ dispatch, googleLoginUrl }: Props) => {
  const navigate = useNavigate();
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [otpResent, setOtpResent] = useState(false);
  const [resendAttempt, setResendAttempt] = useState(false);
  const [resendVerificationTimer, setResendVerificationTimer] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [initialSubmit, setInitialSubmit] = useState(true);
  const [captchaRequired, setCaptchaRequied] = useState(false);
  const [captchaSubmitted, setCaptchaSubmitted] = useState(false);
  const [initialCaptchaRequired, setInitialCaptchaRequired] = useState(false);
  const [sendCodeAfterCaptcha, setSendCodeAfterCaptcha] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const prevSendCodeAfterCaptcha = useRef(false);

  const defaultValues = {
    email: utils.getLocalStorage('em_user_email'),
    otp_attempt: '',
    remember_email: Boolean(utils.getLocalStorage('em_user_email'))
  };

  const {
    clearErrors,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(signInSchema),
    values: defaultValues
  });

  const phoneLastFour = phoneNumber
    ? phoneNumber.replace(/\D/g, '').slice(-4)
    : '';

  useEffect(() => {
    dispatch(fetchSignin());
    document.title = 'Login | EquityMultiple';
    document.body.classList.add('login-page');
    document.getElementById('email').focus();
    reset();

    return () => {
      document.body.classList.remove('login-page');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resendVerificationTimer > 0) {
      setTimeout(() => {
        if (resendVerificationTimer > 0)
          setResendVerificationTimer(resendVerificationTimer - 1);
      }, 1000);
    }
  }, [resendVerificationTimer]);

  const handleCaptchaRequired = useCallback(
    result => {
      if (result.captcha_required) {
        // If the OTP was successfully sent, we should not show or require a captcha for OTP submission
        setShowCaptcha(!result.otp_sent);
        setValue('captcha_required', !result.otp_sent);
        setValue('captcha_response', '');
        setCaptchaRequied(true);
        setInitialCaptchaRequired(initialSubmit);
      }
    },
    [initialSubmit, setValue]
  );

  const handleResendSuccess = () => {
    setOtpResent(true);
    setResendVerificationTimer(30);
    setResendAttempt(false);
  };

  const handleSubmitError = useCallback(
    (err, data) => {
      interface ErrorFormat {
        password?: string;
        root?: {
          serverError?: string;
        };
      }

      let newErrors: ErrorFormat = {
        password: undefined,
        root: undefined
      };

      if (err && [400, 401, 403, 429].includes(err.status_code)) {
        if (err.body.message === 'Your account is locked.') {
          navigate('/users/locked', {
            state: { email: data.email }
          });
        } else if (err.body.errors) {
          newErrors = err.body.errors;
        } else if (err.status_code === 401) {
          newErrors.password = err.body.message;
        } else {
          newErrors = {
            root: {
              serverError:
                err.body?.message || 'Something went wrong. Please try again!'
            }
          };
        }
      } else {
        newErrors = {
          root: {
            serverError:
              err.body?.message || 'Something went wrong. Please try again!'
          }
        };
      }

      throwReactHookFormSubmissionErrors(
        { body: { errors: newErrors } },
        setError
      );
    },
    [navigate, setError]
  );

  const signIn = useCallback(
    (originalData, sendVerificationCode?: boolean) => {
      const data = {
        ...originalData
      };
      if (!data.otp_attempt) delete data.otp_attempt;
      if (!data.captcha_response) delete data.captcha_response;

      return dispatch(createSignin({ user: data }))
        .then(result => {
          if (data.remember_email)
            utils.setLocalStorage('em_user_email', data.email);
          else utils.removeLocalStorage('em_user_email');

          if (result.otp_attempt) {
            setValue('otp_required', true);
            setOtpEnabled(true);
            setPhoneNumber(result.phone);
          } else {
            EmAnalytics.identify(result.id, { email: result.email });
            let afterLoginPath = utils.getAfterLoginPath(
              result.after_login_path
            );

            // Only users with incomplete signups will have the "step" property present
            if (result.step) {
              let signupRoute = 'name';
              if (result.step === 4) signupRoute = 'accreditation';

              afterLoginPath = `/users/signup/${signupRoute}`;
            }

            window.location.replace(afterLoginPath);
          }

          handleCaptchaRequired(result);

          if (resendAttempt && result.otp_sent) handleResendSuccess();

          if (initialSubmit) setInitialSubmit(false);

          if (sendVerificationCode && initialCaptchaRequired) {
            setInitialCaptchaRequired(false);
          }
        })
        .catch(err => {
          handleSubmitError(err, data);

          if (sendVerificationCode) {
            if (!err.body?.errors?.captcha_response) {
              if (initialCaptchaRequired) {
                setShowCaptcha(false);
                setInitialCaptchaRequired(false);
                setOtpResent(false);
                setOtpEnabled(false);
                setCaptchaRequied(false);
                setInitialSubmit(true);
                setResendAttempt(false);
                reset(undefined, { keepErrors: true });
              } else {
                setShowCaptcha(false);
              }
            } else {
              setValue('captcha_response', '');
            }
          }
        })
        .finally(() => {
          if (sendVerificationCode) {
            setCaptchaSubmitted(false);
          }
        });
    },
    [
      dispatch,
      handleCaptchaRequired,
      resendAttempt,
      initialSubmit,
      handleSubmitError,
      setValue,
      reset,
      initialCaptchaRequired
    ]
  );

  const onSubmit = data => {
    data.time_zone = -new Date().getTimezoneOffset();

    if (resendAttempt) setResendAttempt(false);

    if (data.otp_attempt) data.otp_attempt = data.otp_attempt.trim();

    return signIn(data);
  };

  const resendVerificationCode = () => {
    clearErrors();
    setValue('otp_attempt', '');

    setOtpResent(false);
    setResendAttempt(true);

    if (captchaRequired && !captchaSubmitted) {
      /**
       * Once `captchaRequired` is set to true, it will never be set to false.
       * This allows us to continue to enforce the captcha on resend attempts.
       */
      setValue('captcha_required', true);
      setShowCaptcha(true);
    } else {
      setSendCodeAfterCaptcha(true);
    }
  };

  useEffect(() => {
    if (sendCodeAfterCaptcha && !prevSendCodeAfterCaptcha.current) {
      setSendCodeAfterCaptcha(false);
      signIn(watch(), true);
    }

    prevSendCodeAfterCaptcha.current = sendCodeAfterCaptcha;
  }, [sendCodeAfterCaptcha, watch, signIn]);

  useEffect(() => {
    if (otpEnabled && !showCaptcha) {
      document.getElementById('otp_attempt').focus();
    }
  });

  const captchaVerified = value => {
    setValue('captcha_response', value);
    setCaptchaSubmitted(true);
    setSendCodeAfterCaptcha(true);
  };

  return (
    <div className={styles.signinContainer}>
      <div className={styles.signinPageWrapper}>
        <div className={styles.signinSection}>
          <div className={`${styles.eqmLogo} text-center`}>
            <a href="https://www.equitymultiple.com">
              <img alt="EquityMultiple" src={logo} />
            </a>
          </div>

          <Container>
            <OfferingSignUp />
          </Container>

          <div className={styles.loginForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {otpEnabled ? (
                <React.Fragment key="otp">
                  <p className="text-center">
                    {phoneLastFour && showCaptcha
                      ? 'Please submit the captcha below in order to receive a temporary code to your device.'
                      : 'We sent you a temporary code to your phone number ending in ' +
                        phoneLastFour +
                        '. Code expires in 5 minutes.'}
                  </p>
                  {!showCaptcha && (
                    <Controller
                      control={control}
                      name="otp_attempt"
                      render={({ field }) => (
                        <Input
                          {...setFieldProps(field, errors)}
                          label="Enter Code"
                        />
                      )}
                    />
                  )}
                  <div
                    className={`input-fixed-width ${showCaptcha ? '' : styles.captchaHidden}`}
                    data-testid="captchaWrapper"
                  >
                    <Controller
                      control={control}
                      name="captcha_response"
                      render={({ field, fieldState }) => (
                        <Captcha
                          {...setFieldProps(field, errors)}
                          onChange={captchaVerified}
                          valueReset={
                            fieldState.isTouched && field.value === ''
                          }
                        />
                      )}
                    />
                  </div>

                  {otpResent && (
                    <Alert>
                      A one time password has been resent to your mobile phone.
                      Please use a backup code to login if you do not have
                      access to your device.
                    </Alert>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment key="emailPassword">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        label="Email Address"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => (
                      <Password
                        {...setFieldProps(field, errors)}
                        label="Password"
                      />
                    )}
                  />
                </React.Fragment>
              )}

              <FormError errors={errors} />

              {!showCaptcha && (
                <>
                  <Button
                    className={styles.signinButton}
                    disabled={showCaptcha}
                    loading={isSubmitting}
                    type="submit"
                  >
                    {otpEnabled ? 'Submit' : 'Sign In'}
                  </Button>

                  <div className={`${styles.optSignin} margin-top-x`}>
                    <span className={styles.cbRememberMe}>
                      {otpEnabled ? (
                        !initialCaptchaRequired && (
                          <Controller
                            control={control}
                            name="remember_device"
                            render={({ field }) => (
                              <Checkbox
                                {...setCheckboxFieldProps(field, errors)}
                                label="Remember this device for 14 days"
                              />
                            )}
                          />
                        )
                      ) : (
                        <Controller
                          control={control}
                          name="remember_email"
                          render={({ field }) => (
                            <Checkbox
                              {...setCheckboxFieldProps(field, errors)}
                              label="Remember my email address"
                            />
                          )}
                        />
                      )}
                    </span>

                    <span className={styles.forgot}>
                      {otpEnabled &&
                        (resendVerificationTimer > 0 ? (
                          <div className={styles.resendTimer}>
                            <span className="text-green">
                              Verification code resent.
                            </span>
                            <br />
                            You may resend again in {
                              resendVerificationTimer
                            }{' '}
                            second
                            {resendVerificationTimer > 1 && 's'}.
                          </div>
                        ) : (
                          !initialCaptchaRequired && (
                            <>
                              <button
                                className="text-link margin-top-x"
                                disabled={resendVerificationTimer > 0}
                                onClick={resendVerificationCode}
                                type="button"
                              >
                                Resend verification code
                              </button>
                            </>
                          )
                        ))}
                      {!otpEnabled && (
                        <Link to="/users/password/new">Forgot password?</Link>
                      )}
                    </span>
                  </div>
                </>
              )}

              <div className={styles.cardFooter}>
                {otpEnabled ? (
                  !initialCaptchaRequired && (
                    <>
                      <p>
                        If you are not receiving or having trouble logging in
                        with your temporary code, you may enter a backup code
                        which was provided when two-factor authentication was
                        enabled.
                      </p>
                      <p data-testid="phoneHelpParagraph">
                        If you do not have access to your backup codes or are
                        still unable to log in, please give us a call at{' '}
                        <a href="tel:16468449918">(646) 844-9918</a>.
                      </p>
                    </>
                  )
                ) : (
                  <Link className={styles.signUpLink} to="/users/signup/start">
                    <span className={styles.account}>
                      Don&#39;t have an account?
                    </span>
                    <i className={`fa fa-arrow-right ${styles.icon}`} />
                  </Link>
                )}
              </div>
            </form>
          </div>

          {!otpEnabled && (
            <div className="text-center margin-xxx">
              <div className={styles.loginButtons}>Or login with Google</div>
              <GoogleButton oauthUrl={googleLoginUrl} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    googleLoginUrl: state.auth.googleLoginUrl
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(SignIn);
