import logo from 'images/logos/full-logo-blue.svg?url';
import logoMark from 'images/logos/logomark-blue.svg?url';
import React from 'react';
import { Link } from 'react-router-dom';

import * as styles from './HeaderSimple.module.scss';

interface Props {
  className?: string;
  firstPage?: boolean;
}

const HeaderSimple = ({ className, firstPage }: Props) => (
  <div
    className={`${styles.headerNav} ${className || ''}`}
    data-testid="HeaderSimple"
  >
    <a href="https://www.equitymultiple.com">
      <img alt="EquityMultiple" className={styles.defaultLogo} src={logo} />
      <img alt="EquityMultiple" className={styles.smallLogo} src={logoMark} />
    </a>
    {firstPage && (
      <Link className={`text-link ${styles.signinLink}`} to="/users/sign_in">
        Already Signed Up? Click here to login
      </Link>
    )}
  </div>
);

export default HeaderSimple;
